export default {
  "ApiIntegration": {
    "automatedReportingContent": "Keep your KPIs and reports updated, accelerating your Supply Chain performances analysis and optimization.",
    "automatedReportingTitle": "Automated reporting",
    "availableIntegrationsComponentContent": "All ERP and TMS offering API or EDI integrations are supported, including:",
    "availableIntegrationsComponentHeader": "Available integrations",
    "buttonLabel": "Integrations",
    "connectComponentHeader": "Connect OVRSEA to your own tools by API or EDI",
    "contactOvrseaButton": "Contact OVRSEA",
    "contactOvrseaHeader": "Thank you for your interest!",
    "contactOvrseaTextFooter": "To get started, you can now share the documentation to your IT team.",
    "contactOvrseaTextHeader": "Your Sales Manager has been notified and will get back to you shortly to discuss your project.",
    "integrationsOfferingCopyLinkButtonLabel": "Copy link",
    "integrationsOfferingCopyLinkText": "Copy and share the link to the documentation",
    "integrationsOfferingCopyLinkTitle": "Copy the link",
    "integrationsOfferingHeader": "Share OVRSEA integrations offering",
    "integrationsOfferingInvitationButtonLabel": "Add a user",
    "integrationsOfferingInvitationText": "Send an invitation to a colleague to join OVRSEA",
    "integrationsOfferingInvitationTitle": "Add a user",
    "pageTitle": "Integrations",
    "readDocButton": "Read documentation",
    "realTimeUpdatesContent": "Automatically synchronize OVRSEA data in your ERP or TMS, eliminating manual data entry and ensuring up-to-date transportation information.",
    "realTimeUpdatesTitle": "Real-time updates",
    "seamlessCollaborationContent": "Provide up-to-date transportation data to your Purchasing, Sales, Accounting and Finance departments, directly in your collaboration tools.",
    "seamlessCollaborationTitle": "Seamless collaboration",
    "shareDocButton": "Share API documentation",
    "shareInternally": "Share to your IT team"
  },
  "BookingMessageField": {
    "header": "Should you have any question, you can write us here and our team will gladly answer them without delay."
  },
  "BookingRequest": {
    "declineComment": "Comment",
    "declineCommentPlaceholder": "Reason",
    "readyDate": "Ready date",
    "schedulesSelection": "Choose a vessel",
    "selectedSchedule": "Proposed vessel",
    "selectedSchedulePlural": "Proposed vessels",
    "selectedScheduleSingular": "Proposed vessel",
    "supplier": "Supplier"
  },
  "Calendar": {
    "actions": {
      "cancel": "Cancel appointment",
      "close": "Close",
      "confirm": "Accept the appointment",
      "confirmCancel": "Are you sure you want to cancel this appointment ?",
      "goToShipment": "Go to shipment",
      "replanify": "Reschedule",
      "request": "Send Request",
      "suggest": "Suggest another slot"
    },
    "appointments": {
      "awaitingAcceptance": "Awaiting acceptance",
      "awaitingAcceptanceDescription": "Appointment requests awaiting an answer from partners",
      "complex": "Complex",
      "done": "{numberOfAppointments, plural, one{Operation} other{Operations}} done",
      "noAppointment": "No appointment for now",
      "planned": "Planned",
      "priority": "Priority",
      "rescheduleDragAndDrop": "Request new appointment slots by dragging appointments onto the calendar",
      "scheduleDragAndDrop": "Request an appointment slot by dragging appointments onto the calendar",
      "toConfirm": "To confirm",
      "toPlan": "To plan",
      "toRequest": "To request",
      "toReschedule": "To reschedule",
      "toReview": "To review",
      "toReviewDescription": "Click appointments to review details and answer them"
    },
    "header": {
      "currentMonth": "This month",
      "currentWeek": "This week",
      "delivery": "Deliveries",
      "month": "Month",
      "pickup": "Pickups",
      "planning": "Planning",
      "planningOf": "Planning of",
      "sort": "Sort",
      "week": "Week"
    },
    "modal": {
      "details": {
        "cargo": "Cargo",
        "container": "Container Type",
        "orderPreparationRequest": "Order Preparation Request",
        "packages": "# of packages",
        "palets": "# of pallets",
        "products": "# of products",
        "purchaseOrder": "# of POs",
        "shipment": "Shipment",
        "shipowner": "Shipowner",
        "volume": "Total Volume",
        "weight": "Total Weight"
      },
      "kind": {
        "delivery": "Delivery",
        "pickup": "Pickup"
      },
      "pill": {
        "actual": "Done",
        "awaitingConfirmation": "Awaiting confirmation",
        "cancelled": "Cancelled",
        "planned": "Planned",
        "slotToConfirm": "To be confirmed",
        "toPlan": "To plan",
    },
      "priority": "Priority"
    },
    "sendRequest": {
      "body": "The appointment will be confirmed once the other party has accepted your request.",
      "title": "Would you like to request the following appointment ?"
    },
    "shareModal": {
      "body": "Add this calendar to your own tools through this link:",
      "copyLink": "Copy link",
      "share": "Share",
      "title": "Share calendar"
    },
    "trackingActions": {
      "reschedule": {
        "delivery": "Reschedule delivery",
        "pickup": "Reschedule pickup"
      },
      "review": "Review appointment",
      "schedule": {
        "delivery": "Plan delivery",
        "pickup": "Plan pickup"
      }
    }
  },
  "CargaisonDetails": {
    "Actions": {
      "addContainers": "Add containers",
      "cancel": "Cancel",
      "validate": "Validate"
    },
    "CargaisonContainerCard": {
      "headers": {
        "acceptedSeparators": "Accepted separators: spaces, commas and /.",
        "containerNumber": "Container N°",
        "containerType": "Type",
        "grossWeight": "Gross weight (kg)",
        "hazardousDetails": "Hazardous",
        "leadNumber": "Seal number",
        "loadingReference": "Loading reference",
        "numberOfPackages": "Number of packages",
        "numberOfPallets": "Total pallets",
        "positDate": "Posit date",
        "tareWeight": "Tare weight (kg)",
        "unloadingReferences": "Unloading references",
        "unloadingReferencesTip": "Copy and paste unloading references (INBSHIP, WMS References, etc.).",
        "volume": "Volume (cbm)"
      },
      "loadFactor": {
        "description": "The <i>Load factor</i> of a shipping container is an indicator of how efficiently the space within the container is utilized.",
        "descriptionBis": "It is expressed by these two formulas:",
        "loadFactor": "Load Factor",
        "missing": "Missing Data"
      },
      "reservation": {
        "badge": "Products reservation"
      }
    },
    "CargaisonContainerCards": {
      "noData": "No containers for now."
    },
    "CargaisonLoadCards": {
      "noData": "No cargo for now."
    },
    "ContainerActionCell": {
      "addDocument": "Attach a document",
      "deleteContainer": "Delete"
    },
    "ContainerCard": {
      "container": "Container"
    },
    "ContainerDocument": {},
    "Error": {
      "Description": "We apologize for the inconvenience.",
      "Title": "An error occured while retrieving cargaison details."
    },
    "toggleDetailsButton": {
      "showLess": "Hide cargo details",
      "showMore": "Display cargo details"
    }
  },
  "Co2DashboardPage": {
    "compensation": {
      "description": "With OVRSEA, you can buy carbon equivalences to make your transportation more responsible.",
      "header": "Contribution",
      "helperText": {
        "percentageOffset": "Percentage offset",
        "totalEmitted": "Total carbon emission",
        "totalOffset": "Total offset"
      },
      "link": "Discover how ->",
      "mail": {
        "body": "I would like to offset XXX tons with you for year XXX.%0D%0A%0D%0Please give me the associated amount.",
        "buttonLabel": "Request offset",
        "subject": "Offsetting my shipments with OVRSEA"
      },
      "title": "Carbon offsetting for year {year}",
      "url": "https://en.ovrsea.com/blog-transport/reduction-and-compensation-of-co2-emissions-with-ovrsea"
    },
    "freightMode": {
      "air": "Air",
      "ocean": "Ocean",
      "rail": "Rail",
      "truck": "Truck"
    },
    "headers": {
      "exported": "Download",
      "header": "Your carbon footprint",
      "share": "Share",
      "thisYear": "This year"
    },
    "noData": {
      "checkPreviousYear": "Check your {previousYear} report.",
      "subtitle": "Please come back once a new shipment has ended.",
      "title": "Unfortunately, your {selectedYear} report is empty."
    },
    "shareModal": {
      "add": {
        "body": "Create a new account to invite a user who is not yet on Ovrsea.",
        "buttonLabel": "Create account",
        "titleLabel": "Add new user"
      },
      "copy": {
        "body": "Copy and share the link of this dashboard.",
        "buttonLabel": "Copy link",
        "titleLabel": "Copy the link"
      },
      "heading": "Share your carbon footprint",
      "share": {
        "body": "Send an e-mail to the users present on Ovrsea.",
        "buttonLabel": "Send",
        "mail": {
          "body": "The annual carbon report of our shipments is available on the OVRSEA platform.%0D%0A%0D%0APlease have a look using this link: https://app.ovrsea.com/co2-dashboard",
          "subject": "The annual carbon report of our shipments in {year} is available"
        },
        "titleLabel": "Share by e-mail"
      }
    },
    "summary": {
      "alert": {
        "description": "We determine your environmental performance using the Eco-Freight Score.",
        "link": "Discover the tool ->",
        "url": "https://ecofreight.ovrsea.com/presentation-en"
      },
      "benchmark": {
        "description1": "Compare your carbon intensity with the market standards.",
        "description2": "The carbon intensity is expressed in gCO2e emitted for 1 ton transported on 1 kilometer traveled (gCO2e/t.km).",
        "ovrseaCustomers": "OVRSEA customers",
        "title": "Carbon intensity benchmark",
        "yourIntensity": "Your intensity"
      },
      "emission": {
        "distanceInKmComparision": "It is the number of diesel car trips around the earth to generate your emissions ({distance} km)",
        "distanceInMilesComparision": "It is the number of diesel car trips around the earth to generate your emissions ({distance} miles)",
        "title": "Total carbon emissions"
      },
      "header": "Summary",
      "intensity": {
        "score": {
          "A": "Low",
          "B": "High",
          "C": "Too high"
        },
        "title": "Your carbon intensity"
      }
    },
    "unit": {
      "emission": "tCO2e",
      "intensity": "gCO2e/t.km"
    },
    "yearlyEmission": {
      "header": "Evolution of your carbon emissions",
      "title": "Track the emissions of your shipments month by month."
    }
  },
  "ConfirmBookingRequestCard": {
    "accept": "Confirm this booking",
    "decline": "Decline",
    "header": "Confirm this shipment"
  },
  "ContainerNumberCell": {
    "errors": {
      "containerError": "The container name must consist of 4 letters followed by 7 digits (eg BCDU1234567)"
    }
  },
  "ContainerPurchaseOrderList": {
    "seeMore": "Show details"
  },
  "ContainerVGM": {
    "VGMAvailable": "VGM available",
    "createVGM": "Submit VGM",
    "editVGM": "Edit VGM",
    "noVGM": "No VGM"
  },
  "CreateNote": {
    "categoriesRequired": "Which teams are affected by this note ?",
    "detailsRequired": "Field required"
  },
  "CustomsClearance": {
    "activities": {
      "customs_clearance_done": "Customs clearance done by {person}",
      "customs_clearance_requested": "Customs clearance requested by {person}",
      "customs_clearance_submitted": "Customs clearance submitted by {person}"
    },
    "clearanceLocation": "Clearance location",
    "clientReference": "Customs clearance reference",
    "customsClearance": "Customs clearance",
    "empty": {
      "bullet1": "<bold>Centralization</bold> of your EXA documents on the OVRSEA platform",
      "bullet2": "<bold>Control</bold> over your declarations, carried out by a single declarant who knows your products and your expectations",
      "bullet3": "<bold>Reactivity</bold> of your dedicated OVRSEA declarant",
      "linkLabel": "click here",
      "paragraph1": "OVRSEA can now <bold>centralize all your export customs clearances, even if we're not in charge of the shipment!</bold>",
      "paragraph2": "This means greater operational fluidity thanks to:",
      "paragraph3Part1": "If you are interested,",
      "paragraph3Part2": "to be contacted by our team.",
      "paragraph4Part1": "If you would like more information about our service,",
      "paragraph4Part2": "for a detailed presentation."
    },
    "extraction": "Extraction",
    "freightMethod": "Freight method",
    "label": {
      "targetClearanceFooter": "More info",
      "targetClearanceModalContent": "The date on which you would like your goods to be cleared through customs. Ideally, it should be at the latest the day before the goods are picked up.",
      "targetClearanceModalTitle": "Target clearance date and time"
    },
    "officeOfExit": "Office of exit",
    "requestCustomsClearance": "Request customs clearance",
    "status": {
      "awaiting_clearance": "Awaiting clearance",
      "cancelled": "Cancelled",
      "clearance_done": "Clearance done - final EXA available",
      "clearance_submitted": "Clearance submitted - Awaiting for exit"
    },
    "submit": "Request customs clearance",
    "summary": "Summary",
    "tab": "Export Customs Clearances",
    "tableFilters": {
      "active": "Active",
      "all": "All",
      "cancelled": "Cancelled",
      "finished": "Done"
    },
    "targetClearanceDateTime": "Target clearance date and time"
  },
  "CustomsTariffNumber": {
    "SubmitCustomsTariffNumbersForm": {
      "body": "Once added, OVRSEA will check the validity and compliance of your customs codes, and will inform you in case of customs regulatory changes.",
      "header": "Add your customs codes",
      "inputHelp": "Add your customs codes, separated by a comma",
      "nomenclature": "Nomenclature",
      "productSectionBody": "Upload a list of your product references and their associated customs codes",
      "productSectionButton": "Upload your product list",
      "productSectionHeader": "You have dozens of customs codes and associated product references?",
      "submit": "Submit your customs codes",
      "wrongTaricCodeHelp": "TARIC codes must be 10 digits long"
    },
    "emptyState": {
      "reason1": "• have their validity and compliance audited by OVRSEA",
      "reason2": "• improve the reliability of your declarations",
      "reason3": "• be informed by OVRSEA of changes in customs regulations",
      "title": "Centralize the customs codes associated to your products reference so you can"
    },
    "selectTable": {
      "byCode": "By customs code",
      "byProduct": "By product"
    },
    "status": {
      "compliant": "Compliant",
      "invalid": "Invalid",
      "under_validation": "Under validation"
    },
    "tab": "Customs Codes",
    "table": {
      "byCode": {
        "addButtonLabel": "Add your customs codes",
        "compliantCodes": "Compliant codes",
        "header": {
          "code": "Code",
          "customsNomenclature": "Nomenclature",
          "status": "Conformity status"
        },
        "invalidCodes": "Invalid codes"
      },
      "byProduct": {
        "compliantAssociations": "Compliant associations",
        "download": "Download",
        "empty": "No products with customs codes found",
        "header": {
          "description": "Product description",
          "htsCode": "🇺🇸 HTS Code",
          "sku": "Product reference",
          "taricCode": "🇪🇺 TARIC Code"
        },
        "invalidAssociations": "Invalid associations",
        "productsWithoutAssociations": "Products without associated code",
        "status": {
          "compliant": "Compliant association",
          "invalid": "Invalid association",
          "under_validation": "Under validation"
        },
        "underValidationAssociations": "Under verification associations"
      }
    }
  },
  "CustomsTariffNumbersDictionary": {
    "tab": "TARIC nomenclature",
    "table": {
      "content": {
        "expiredPill": "Expired",
        "validityTooltip": "This code will be replaced by {newCodeAfterExpiration} as of {validityEndDate}"
      },
      "headers": {
        "code": "Code",
        "description": "Description",
        "expirationDate": "Expiration date"
      },
      "searchBar": {
        "lastUpdate": "Last update",
        "match": {
          "exact": "Exact code match",
          "partial": "Partial code match"
        },
        "placeholder": "Search for a code"
      }
    }
  },
  "DetailsAndCargoTab": {
    "CargaisonDetails": {
      "NewContainer": {
        "containerType": {
          "placeholder": "Choose a container type"
        },
        "toBeCreated": "To be created"
      },
      "header": "Cargo",
      "headerFcl": "Containers"
    },
    "CargaisonTotals": {
      "headers": {
        "airTaxableWeight": "Taxable weight",
        "productsDescription": "Products description",
        "totalQuantity": "Total amount",
        "totalVolume": "Total Volume",
        "totalWeight": "Total weight",
        "weightMesurable": "Weight Mesurable"
      },
      "weightMesurableTooltip": {
        "few": "",
        "many": "",
        "one": "MAX(weight in tons, volume in cbm)",
        "two": "MAX(weight in kg / 333, volume in cbm)"
      }
    },
    "DeleteContainerDocumentModal": {}
  },
  "DocumentTab": {
    "noData": "No document for now."
  },
  "EmitedCarbon": {
    "carbonIntensity": "Carbon footprint",
    "link": "Find out more"
  },
  "ForceRefreshBanner": "✨ A new version has been released, please reload your page",
  "GoogleMapsSearchBar": {
    "AddAddressManually": {
      "addAddressInResults": "Add manually",
      "canAddManually": "You may however add this address manually."
    }
  },
  "LoadsCard": {
    "LoadsCardRow": {
      "headers": {
        "dimensions": "Dimensions",
        "quantity": "Quantity",
        "ref": "Ref",
        "special": "Special",
        "stackable": "Stackable",
        "type": "Type",
        "unitWeight": "Weight / U"
      }
    },
    "noLoads": "No cargo for this shipment"
  },
  "LocationModal": {
    "button": {
      "confirmDeletion": "Confirm",
      "create": "Create",
      "delete": "Are you sure you want to delete this location ? You will not be able to use it anymore for any shipment.",
      "share": "Share to connections"
    },
    "private": "Private",
    "restrictLocation": {
      "allConnections": "All of your connections can",
      "locationIs": "This location is",
      "public": "Public",
      "question": "Would you like to restrict access to it?",
      "restrict": "Restrict access"
    },
    "shareLocation": {
      "able": "They will be able to",
      "permission1": "access its details and contacts",
      "permission2": "use it as pickup or delivery location in quote and shipment requests",
      "questionFirstPart": "Would you like to share this location to ",
      "questionSecondPart": "all of your connections?"
    },
    "sharedBy": "Shared by",
    "title": {
      "create": "Add a location",
      "show": "Information about this location",
      "update": "Modify this location"
    }
  },
  "LocationStepField": {
    "label": "New location"
  },
  "OrDivider": {},
  "OrderNameAndIcon": {
    "HermesPopupTrigger": {}
  },
  "PreferedSchedulesCard": {},
  "ProductPage": {
    "CO2EmissionCard": {
      "cO2EmissionPerUnit": "CO2e emissions per unit",
      "totalCO2Emission": "Total CO2e emissions"
    },
    "FollowProduct": {
      "followDescription": "Receive notifications for this product ",
      "followLabel": "Follow",
      "followedDescription": "Stop receiving notifications for this product",
      "followedLabel": "Followed"
    },
    "ShippingCostCard": {
      "shippingCostPerUnit": "Shipping cost per unit",
      "totalShippingCost": "Total shipping cost"
    },
    "calculationMethodModal": {
      "body1": "Access easily reliable data to manage the costs and carbon emissions of your products.",
      "body2": "To make these calculations we take into account all the shipments handled by OVRSEA since <strong>{firstShipmentDate}</strong>.",
      "body3": "In total, you have transported <strong>{numberOfUnits} {numberOfUnits, plural, one{unit} other{units}} for {numberOfShipments} {numberOfShipments, plural, one{shipment} other{shipments}}</strong>.",
      "header": "Our calculation method",
      "listElement11": "We estimate the total cost of transportation at <strong>{totalShippingCost}</strong>.",
      "listElement12": "We divide this total by the number of units transported: <strong>{numberOfUnits}</strong>.",
      "listElement13": "This gives us an average cost per unit of <strong>{shippingCostPerUnit}</strong>.",
      "listElement21": "We estimate the total emissions of transportation at <strong>{totalCO2EmissionInTons} t CO2e</strong>.",
      "listElement22": "We divide this total by the number of units transported: <strong>{numberOfUnits}</strong>.",
      "listElement23": "This gives us an average emission per unit of <strong>{cO2EmissionPerUnitInKg} kg CO2e</strong>.",
      "listTitle1": "For the costs of a unit",
      "listTitle2": "For the carbon emissions of a unit"
    },
    "editProduct": {
      "addYourMissing": {
        "description": "Add your missing description",
        "hsCode": "Add your missing HS code"
      },
      "buttonTitle": "Edit product",
      "description": "Description",
      "descriptionPlaceholder": "Details of your product",
      "formHeader": "Edit product information",
      "hsCode": "HS code"
    },
    "emptyPage": "You have not carried out any shipment for this product.",
    "finishedShipments": "Finished shipments",
    "keyInsights": "Key insights",
    "nextDelivery": "Next delivery",
    "nextDeliveryCard": {
      "delivery": "Delivery",
      "inAdvance": "In advance.",
      "onTime": "On Time."
    },
    "nextDeliveryOn": "Next delivery on {nextDeliveryDate}",
    "productInformation": "Product information",
    "quantityShipped": "Quantity shipped",
    "table": {
      "delivered": {},
      "deliveryDate": "Delivery date",
      "destination": "Destination",
      "eta": "ETA",
      "freightForwarder": "Freight forwarder",
      "quantity": "Quantity shipped",
      "select": {
        "finished_Shipments": "Finished shipments",
        "inProgress_Shipment": "On-going shipments"
      },
      "shipmentName": "Shipment",
      "status": "Status",
      "transiting": {}
    },
    "units": "{quantity} units",
    "unitsInTransit": "Units in transit",
    "unitsReady": "Units ready to ship",
    "unknownDestination": "Unknown destination",
    "unknownQuantity": "Unknown quantity",
    "unknownQuantityPlural": "In {numberOfShipments} shipments",
    "unknownQuantitySingular": "In a shipment",
    "upcomingDeliveries": "Upcoming deliveries"
  },
  "ProductsPage": {
    "AddProductsModal": {
      "closeButton": "Close",
      "dropzone": {
        "description": "Drag and Drop here or choose file (.xls or .csv)",
        "title": "Product list"
      },
      "explanation1": "Upload a list of the product references available in your commercial invoices to improve our automatic reading of your invoices. (in Excel or .csv format)",
      "explanation2": "You will have access to your products in transit and detailed analytics for each product (cost per unit, CO2e emission per unit)",
      "faqLink": "Find a template and more information related to this feature on our related FAQ",
      "header": "Add your product list",
      "sendButton": "Send",
      "thankYou1": "Thank you ! Your product catalog has been sent to our team.",
      "thankYou2": "Come back in a few hours to find your products in transit on this page."
    },
    "addThisProductButton": "Add this product to your catalog",
    "addYourProductsButton": "Upload your product list",
    "addYourProductsToShipmentsButton": "Help us find your products",
    "downloadButton": "Download",
    "performanceModal": {
      "addYourProductsNow": "add your first products now",
      "badScore": {
        "bannerDescription": "Your coverage score is medium.",
        "bannerDetails": "Understand how to improve it",
        "contactProduct": "to improve the identification of your products in your invoices.",
        "contactProductLink": "Contact our product team",
        "scoreLine1": "Your coverage score is medium",
        "scoreLine2": "Some of your invoices have not been properly read."
      },
      "badTransportsCount": "{numberOfTransports} shipments",
      "bannerTitle": "Our AI reads your invoices",
      "goodScore": {
        "bannerDescription": "Congratulations, your coverage score is high!",
        "bannerDetails": "Check out more details",
        "scoreLine1": "Your coverage score is high",
        "scoreLine2": "Your product information are reliable. We recommend that you keep the same commercial invoices format."
      },
      "haveNoIdentifiedInvoices": "don’t have invoices identified.",
      "header": "Understand your coverage score",
      "noExcel": "Invoices in Excel format are not recognized by the AI.",
      "noProductsBanner": "This page is unfortunately empty. Come back after submitting a commercial invoice or",
      "scoreExplanationLine": "This coverage score depends on the number of shipments that have product references identified by our AI.",
      "warning": "Warning"
    },
    "table": {
      "awaitingDeparture": "Ready to ship",
      "description": "Description",
      "emptyMessage": "No products found on your shipments",
      "inTransit": "In transit",
      "lastDelivery": "Last Delivery",
      "name": "Name",
      "nextDelivery": "Next Delivery",
      "noData1": "No products recognised at the moment!",
      "noData2": "Unfortunately, we have not yet recognised your products via your commercial invoices. If the problem persists, please contact our product team to improve the tracking of your products with OVRSEA.",
      "noDelivery": "No delivery planned",
      "productReference": "Product reference",
      "turnOffNotificationsForProduct": "Stop receiving notifications for this product",
      "turnOnNotificationsForProduct": "Receive notifications for this product",
      "unitsInNextDelivery": "Units in next delivery",
      "unknownQuantity": "Unknown quantity"
    },
    "tabs": {
      "allProducts": "Products",
      "orderPreparation": "Order preparation",
      "orders": "Purchase Orders"
    },
    "titlePO": "Order management",
    "titleProducts": "Products"
  },
  "ProductsTableContainer": {},
  "PurchaseOrdersTableContainer": {
    "emptyMessage": "No PO for now."
  },
  "ShipmentsPage": {
    "containers": "Containers in transit",
    "planning": "Planning",
    "schedules": "Schedules",
    "shipments": "Shipments"
  },
  "ShipownersPage": {
    "ShowShipowner": {
      "ShipownerInfo": {
        "days": "days",
        "exportPaymentTerms": "Export payment terms",
        "importPaymentTerms": "Import payment terms",
        "noNoteForThisShipowner": "No note for this shipowner",
        "none": "None",
        "notDocumented": "Not documented",
        "notDocumentedLoadType": "Load Type not documented",
        "paymentTerms": "Payment terms",
        "shipownerIsAGroup": "This shipowner is also a group"
      },
      "contactDetails": "Contact Details",
      "modifyShipownerButtonLabel": "Edit shipowner"
    }
  },
  "TasksCard": {
    "DeleteTaskModal": {
      "areYouSure": "Are you sure ?",
      "deleteConfirm": "Yes, delete",
      "deleteTask": "Delete this task ?",
      "deletionForbidden": "Deletion forbidden",
      "errorTaskDeletion": "You cannot delete a task",
      "understood": "Understood",
      "warningMessageOpflow": "⚠️ This is an opflow task"
    }
  },
  "TrackingLink": {
    "RequestAccessCard": {
      "buttonLabel": "Request access"
    },
    "RequestAccessModal": {
      "body": "You are one step away from great shipment monitoring. Once submitted, we’ll get back to you promptly.",
      "fields": {
        "company": {
          "label": "Company",
          "placeholder": "Type your company name",
          "required": "Company name must be filled"
        },
        "email": {
          "label": "Email address",
          "placeholder": "Type your email address",
          "required": "Email must be filled",
          "valid": "Email must be valid"
        },
        "name": {
          "label": "Name",
          "placeholder": "Type your full name",
          "required": "Name must be filled"
        }
      },
      "title": "Request access to the platform"
    }
  },
  "UnavailableData": "Not available",
  "ViewerModalError": {
    "cantLoad": "We could not load this file preview.",
    "downloadBelow": "You may however download the file by clicking below:",
    "oops": "Oops!"
  },
  "WallCard": {
    "ClientShareMenuField": {}
  },
  "about": {
    "document": {
      "title": "About"
    }
  },
  "accept-proposition-modal": {
    "NRAdisclaimers": {
      "line1": "* This quote does not include taxes, customs duties or import VAT.",
      "line2": "This quote is only applicable for a shipment as described above.",
      "line3": "In case of an unexpected event, additional charges may apply per Carrier's FMC Tariff and Bill of Lading.",
      "line4": "**Sailing dates are communicated for information and will be updated as per shipping lines schedule.",
      "line5": "By confirming below, you acknowledge these notices and accept our <linkTo>General Terms of Sales.</linkTo>"
    },
    "NRAdisclaimersOvrseaNvocc": {
      "line5": "By confirming below, you acknowledge these notices and accept our <linkTo>General Terms of Sales</linkTo> that apply to the services provided by Ovrsea as a Freight Forwarder in this shipment."
    },
    "cancellationFeesDisclaimers": {
      "air": {
        "line1": "*** Cancellation fees:",
        "line2": "- 25% - 5 or more days before departure",
        "line3": "- 50% - 4 days before departure",
        "line4": "- 75% - 3 days before departure",
        "line5": "- 100% - 2 days before departure"
      },
      "oceanOrRail": {
        "line1": "*** Cancellation fees:",
        "line2": "For a cancellation 7 days or less before departure:",
        "line3": "- 100{propositionCurrency} + 50{propositionCurrency} per container",
        "line4": "- 100% of truck transportation fees for any cancellation within 48 hours prior to pick-up."
      },
      "truck": {
        "line1": "*** Cancellation fees:",
        "line2": "- 100% of the total for any cancellation within 48 hours prior to pick-up."
      }
    },
    "disclaimers": {
      "line1": "* This quote does not include taxes, customs duties or import VAT.",
      "line2": "This quote is only applicable for a shipment as described above.",
      "line3": "In case of an unexpected event, additional charges may apply.",
      "line4": "** Next departure dates are applicable only when accepting the quote occurs the same day as it was sent.",
      "line5": "By confirming below, you acknowledge these notices and accept our <linkTo>General Terms of Sales.</linkTo>"
    },
    "header": {
      "bis": "Confirm selected quote"
    }
  },
  "acceptBookingRequestConfirmModal": {
    "header": "Accept this booking request?"
  },
  "acceptBookingRequestConfirmText": {},
  "acceptInvitation": {
    "alreadyAccepted": {
      "buttonLink": "Login"
    },
    "errors": {
      "alreadyAccepted": "This invite has already been accepted.",
      "invalidtoken": "The activation link is not valid"
    },
    "form": {
      "cgv": {
        "link": "https://en.ovrsea.com/terms-and-conditions-of-sales",
        "linkText": "I have read and accept the <link>General Terms and Conditions of Sales</link>"
      },
      "label": {
        "confirmPassword": "Confirm your password",
        "password": "Password",
        "submit": "Go to my account"
      }
    },
    "text": {
      "welcome": {
        "bis": "👋 Hello {userName}"
      },
      "welcomeDescription": {
        "bis": "Please choose a password to activate your {name} account. "
      }
    }
  },
  "actionAndActivity": {
    "activityDocumentList": {
      "deletedDocumentBis": "Document deleted"
    }
  },
  "actions": {
    "addContainer": "Add container",
    "deletePurchaseOrder": "Delete purchase order",
    "editContact": "Edit contact",
    "editPurchaseOrder": "Edit purchase order",
    "previewDocument": "Preview document",
    "removeBillingEmail": "Remove billing email",
    "removeContainer": "Remove container",
    "removeDocument": "Remove document",
    "removeEmail": "Remove email",
    "removeLoad": "Remove load",
    "resetDate": "Clear date"
  },
  "activities": {
    "aiProductFeedback": {
      "aiProductFeedbackAccurateHeader": "<strong>{firstname}</strong> has confirmed that the products information identified for this shipment are correct.",
      "aiProductFeedbackInaccurateHeader": "<strong>{firstname}</strong> has reported an error on the products information identified for this shipment",
      "feedbackDetailsPlural": "The information of {inaccurateAttribute} are incorrect.",
      "feedbackDetailsSingular": "The information {inaccurateAttribute} is incorrect.",
      "inaccurateInformations": {
        "description": "Description",
        "hsCode": "HS Code",
        "other": "Other",
        "price": "Unit price",
        "quantity": "Quantity",
        "sku": "Product reference (SKU)"
      }
    },
    "allocationPeriodUpdated": {
      "removed": "{person} has removed allocation week {oldWeek}-{oldYear} for this shipment",
      "set": "{person} has set allocation period for week {newWeek}-{newYear}",
      "updated": "{person} has updated allocation period from week {oldWeek}-{oldYear} to week {newWeek}-{newYear}"
    },
    "incotermSet": {
      "header": "{person} has set incoterm to {newIncoterm}"
    },
    "incotermUpdated": {
      "header": "{person} has updated incoterm from {oldIncoterm} to {newIncoterm}"
    },
    "insurance_updated": {
      "insurance": "Change in insurance: {oldInsurance} > {newInsurance} by {person}",
      "no_value": "No insurance"
    },
    "routingUpdated": {
      "arrivalHarbor": { "air": "Arrival airport", "ocean": "Arrival harbor", "rail": "Arrival station", "truck": "Arrival" },
      "deliveryLocation": { "fcl": "Unloading", "lcl": "Delivery" },
      "departureHarbor": { "air": "Departure airport", "ocean": "Departure harbor", "rail": "Departure station", "truck": "Departure" },
      "header": "{person} has updated the route",
      "pickupLocation": { "fcl": "Loading", "lcl": "Pickup" }
    },
    "shipment_booked": {
      "header": "Booking done with"
    },
    "shipmentShared": {
      "owner": "You shared this shipment with <strong>{relatedClientName}</strong>",
      "related": "<strong>{ownerClientName}</strong> shared this shipment with you"
    }
  },
  "algoliaSearchBar": {
    "clients": {
      "label": {
        "phone": "Phone"
      }
    },
    "contacts": {
      "label": {
        "phone": "Phone",
        "phone2": "Phone 2",
        "shipownerType": "Job"
      }
    },
    "highlightResult": {
      "address": "Address:",
      "billingNumber": "Invoice number:",
      "bookingNumber": "Booking number:",
      "city": "City:",
      "company": "Company:",
      "containerNumber": "Container number:",
      "country": "Country:",
      "description": "Description:",
      "internalReference": "Internal reference:",
      "name": "Name:",
      "postalCode": "Postal code:",
      "proposition": "Proposition number:",
      "reference": "Reference:",
      "supplier": "Supplier:"
    },
    "indices": {
      "title": {
        "clients": "Clients",
        "contacts": "Contacts in Phone Book",
        "customsClearanceRequests": "Custom clearance requests",
        "locations": "Locations",
        "orderPreparation": "Order Preparation",
        "purchaseOrders": "Purchase Orders",
        "quotations": "Quotes",
        "shipments": "Shipments",
        "shipowners": "Vendors in Phone Book"
      }
    },
    "locations": {
      "label": {
        "address": "Address"
      }
    },
    "orders": {
      "label": {
        "route": "Route",
        "status": "Status"
      }
    },
    "products": {
      "freightMethod": "Freight mode",
      "unitsInNextDelivery": {}
    },
    "purchaseOrder": {
      "label": {
        "status": "Status",
        "supplier": "Supplier"
      }
    },
    "searchBar": {
      "placeholder": {
        "hermes": "Search by product name, shipment reference..."
      },
      "resetTitle": "Reset search",
      "submitTitle": "Submit search"
    },
    "shipowners": {
      "label": {
        "phone": "Phone",
        "shipownerType": "Job"
      }
    }
  },
  "atlas": {
    "finalCheck": {
      "FinalCheckPricesTotalsAndDocumentsByShipowner": {
        "suppliersWithSingleInvoice": "Suppliers with single invoice",
        "suppliersWithoutSingleInvoice": "Suppliers without single invoice"
      }
    }
  },
  "attributes": {
    "added": "added",
    "additionalInformations": "Comment",
    "approvedExporter": "Approved Exporter Status",
    "billingEmailAddress": "Email for invoicing",
    "clientName": "Shipment reference",
    "contact": "Contact",
    "customsClearanceLocation": "Customs clearance location",
    "customsClearanceOffice": "Customs Office",
    "deleted": "deleted",
    "deliveryTargetDate": "Desired delivery date",
    "eur1": "EUR1",
    "false": "No",
    "loadingReference": "Loading reference",
    "needsAppointment": "Appointment required",
    "originalBls": "Original BLs",
    "pickupTargetDate": "Cargo Ready Date",
    "productsDescription": "Cargo description",
    "receivingHours": "Receiving hours",
    "t1": "T1",
    "tailBoardNeeded": "Liftgate required",
    "true": "Yes",
    "undefined": "undefined",
    "updated": "modified",
    "vatNumber": "VAT Number",
    "vatReverseCharge": "Reverse VAT"
  },
  "auth": {
    "footer": {
      "cgv": "General Terms and Conditions of Sales"
    }
  },
  "awb": {
    "generate": "🏷 Generate MAWB"
  },
  "billing": {
    "available": {
      "billingDueDateText": "Payment by bank transfer before {dueDate}.",
      "billingNumberText": {
        "bis": "OVRSEA Invoice"
      },
      "billingRefText": {
        "bis": "Client reference"
      },
      "buttonText": "See on OVRSEA",
      "dueDateLabel": "Payment terms",
      "firsText": "You have trusted OVRSEA for a shipment and we thank you for that.",
      "greeting": "Dear Sir, Madam,",
      "ovrseaGreetingText": "Have a pleasant day,",
      "secondText": "Please find attached corresponding invoice for your kind settlement.",
      "supportText": "If needed, you may contact us directly on our platform or by replying to this email to billing@ovrsea.com.",
      "title": "Invoice {billingNumber} available for this shipment"
    },
    "document": {
      "title": "Billings"
    },
    "due": {
      "fifthText": "Should there be any question, please contact us at ",
      "fourthText": "If your bank transfer occurred in the meantime, you may ignore this email.",
      "thirdText": "Please proceed to prompt payment and indicate the invoice reference on the bank transfer. OVRSEA's IBAN is available on the invoice."
    },
    "show": {
      "table": {
        "billingNumber": "Invoice number"
      }
    },
    "table": {
      "InvoicesTotals": {
        "andSelected": "and {count} invoice(s) for",
        "invoicesSelected": "{count} invoice(s) selected for"
      },
      "billingNumberbis": "Invoice reference",
      "dueDate": "Due date",
      "issueDate": "Issue date",
      "priceHt": "Pre-tax freight price",
      "select": {
        "bill": {
          "available": "Available",
          "due": "Due",
          "litigious": "Litigious",
          "paid": "Paid",
          "partiallyPaid": "Partially paid",
          "writeOff": "Write-off"
        }
      },
      "shipment": "Shipment",
      "status": "Status",
      "totalPrice": "Invoice total price",
      "unavailable": "Unavailable"
    }
  },
  "booking": {
    "document": {
      "BookingDocumentUploadBlock": {
        "dragAndDropOrChooseFile": "Drag and Drop here or choose file",
        "uploadFile": "Upload file"
      },
      "BookingDocumentsSection": {
        "and": "and",
        "commercialInvoice": "Commercial Invoice",
        "documentsUploadedTitle": "You already shared your {docTypeList} with us",
        "packingList": "Packing List",
        "uploadAvailableDocuments": "Please send us the following documents if they are already available:",
        "uploadOtherDocuments": "If you need to send other documents, please upload them below:"
      },
      "datePrefix": "On",
      "title": "Booking information"
    }
  },
  "bookingConfirmation": {
    "EUR1Label": {
      "bis": "This shipment requires an <strong>EUR1</strong>"
    },
    "T1Label": {
      "bis": "This shipment requires a <strong>T1</strong>"
    },
    "approvedExporterLabel": "Is your company an Approved Exporter in the EU?",
    "approvedExporterProof": "Attach proof of Approved Exporter",
    "billingEmailAddressLabel": "Email address for billing",
    "billingEmailAddressPlaceholder": "e.g. billing@company.com",
    "billingSectionTitle": "Company information",
    "carbonCompensation": {
      "firstLine": "I would like to contribute to the global effort towards Net Zero by financing reforestation projects in France, that will capture <bold>{emittedCo2}</bold> of CO2.",
      "header": "Contribute to Net Zero",
      "secondLine": "Your contribution of <bold>{compensationCost}</bold> will appear on the shipment invoice and will be fully donated to our partner certified Label Bas Carbone."
    },
    "cargoDeliverySectionTitle": "Delivery information",
    "cargoPickupSectionTitle": "Pick-up information",
    "cargoReady": "Today",
    "cargoReadyLabel": "Cargo availability for pick-up",
    "cargoReadyWhen": "Ready on",
    "changeDeliveryLocationInfo": "If you wish to change location for delivery, please contact our operational team from the Shipment page.",
    "changePickupLocationInfo": "If you wish to change location for pick-up, please contact our operational team from the Shipment page.",
    "comBilling": "Commercial Invoice",
    "commentaryLabel": "Additional information",
    "commentaryPlaceholder": "Comments on booking, cargo, pick-up & delivery locations, etc.",
    "company": "Company",
    "companyLabel": "Shipper company",
    "companyNamePlaceholder": "Company name (required)",
    "createContactLabel": "Create new contact",
    "createNewCompany": "Add a company",
    "customsClearanceLocationLabel": "Customs Clearance address to fill on the T1",
    "customsClearanceOfficeLabel": "Customs Office",
    "customsClearanceOfficePlaceholder": "e.g.: Roissy",
    "deletePopupContent": "Delete",
    "deliveryLocation": "Delivery location",
    "deliveryLocationAddress": "Delivery location and address",
    "deliveryLocationCommentLabel": "Additional information for delivery location",
    "deliveryLocationCommentPlaceholder": "Additional information for delivery location",
    "deliveryLocationContactLabel": "Contact for delivery",
    "deliveryLocationSectionTitle": "Details about delivery",
    "deliveryStepHeader": "Please specify the following information regarding delivery location for the shipment.",
    "document": {
      "title": "Booking information"
    },
    "documents": "Documents",
    "documentsForHazardousCargo": "MSDS or DGD",
    "documentsHeader": "If available already, please share with us the following documents:",
    "documentsTitle": "Documents",
    "finalization": "Finalisation",
    "headerBis": "Thank you for trusting OVRSEA",
    "ignoreStep": "Skip this step",
    "importCargoPickupSectionTitle": "Availibity of cargo",
    "importCargoReadyLabel": "Cargo ready date",
    "importLoadingRefLabel": "Shipper reference",
    "importLocationSelectFieldHint": "If not available in your contact list:",
    "importLocationSelectFieldHintSecondLine": "Add a location",
    "importPickupStepHeader": "Please provide information about the shipper for us to prepare this shipment.",
    "informations": "Information",
    "loadingRefLabel": "Loading reference",
    "loadingTailBoardNeeded": "Liftgate needed for loading",
    "locationPlaceholder": "Please select",
    "missingLocationInfos": "Following information is missing for the selected location:",
    "needsAppointment": "Appointment needed",
    "networkAddress": "Address",
    "networkCity": "City (required)",
    "networkCountry": "Country",
    "newLocation": "New location",
    "nextStepPopupContent": "Next",
    "noAvailableContacts": "No contact available",
    "or": "OR",
    "originalBlsLabel": {
      "bis": "This shipment requires <strong>Original BLs</strong>"
    },
    "packingList": "Packing List",
    "pickupLocation": "Pick-up location",
    "pickupLocationAddress": "Pick-up location and address",
    "pickupLocationCommentLabel": "Comments on pick-up location",
    "pickupLocationCommentPlaceholder": "Additional information on pick-up location",
    "pickupLocationContactLabel": "Contact for pick-up",
    "pickupLocationContactLabelForImport": "Contact for the shipment",
    "pickupLocationForImport": "Shipper information",
    "pickupLocationSectionTitle": "Details about pick-up",
    "pickupLocationSectionTitleForImport": "Shipper contact at pick-up",
    "pickupStepHeader": "Please verify the following information for pick-up location of the shipment.",
    "pickupSupplierLocation": "Supplier contact",
    "previousStep": "Previous",
    "productsDescriptionLabel": "Cargo description",
    "productsDescriptionPlaceholder": "Provide any additional cargo information",
    "productsDescriptionTitle": "Transported goods",
    "section": {
      "carbonOffset": {
        "thankYou": "Thank you for your contribution!",
        "title": "Carbon offset"
      }
    },
    "shipmentClientNameLabel": "Shipment name",
    "shipmentClientNamePlaceholder": "Shipment name",
    "shipmentInformationsTitle": "Information for this shipment",
    "shipownerContact": "Shipper contact",
    "shipownerSectionTitle": "Shipper contact at pick-up",
    "specificsSectionTitle": "Specific instructions",
    "subheader": "Please check the information below so that our teams can book space promptly.",
    "subheaderFirstLine": "Thank you for trusting OVRSEA for this shipment.",
    "subheaderSecondLine": "Please verify and fill in the following information for us to better organize your shipment.",
    "tailBoardNeededWarning": "Adding the need of a liftgate may result in additional charges, unless specified by our teams",
    "title": "Additional information",
    "unloadingTailBoardNeeded": "Liftgate needed for unloading",
    "unsavedLocationMessage": "Please validate or cancel this before moving to the next step",
    "vatNumberLabel": "VAT Number",
    "vatNumberPlaceholder": "VAT Number",
    "vatReverseChargeLabel": "Is your company registered for reverse VAT in France?",
    "vatReverseChargeProof": "Attach proof of reverse VAT"
  },
  "bookingContactLocation": {
    "alertErrorLabel": "There are some errors:",
    "card": {},
    "client": {
      "card": {
        "subTitle": "In order to collect your goods we need the following information.",
        "title": "Please provide us information for pick-up location"
      },
      "form": {
        "contactLabel": "Contact for pick-up",
        "label": {
          "appointmentNeeded": "Is an appointment needed?",
          "comment": "Comments",
          "commentHint": "Indicate all the necessary information for pick-up",
          "receivingHours": "Receiving hours",
          "receivingHoursHint": "eg: 6am - 10pm",
          "tailboardNeeded": "Is a liftgate needed for loading?"
        },
        "pickupInfo": "Pickup address",
        "submitButtonText": "Validate"
      }
    },
    "contactModal": {
      "cancelButtonText": "Cancel",
      "contactInformation": "Contact information",
      "email": "Email address",
      "name": "Contact name",
      "namePlaceholder": "Name",
      "onlyOneFieldRequired": "Only one field is required",
      "phone": "Phone number",
      "submitButtonText": "Create",
      "title": "Create a contact"
    },
    "supplier": {
      "card": {
        "subTitle": "Our teams will then book space promptly.",
        "title": "Please provide us information about the shipper"
      },
      "form": {
        "contactLabel": "Contact for the shipment",
        "submitButtonText": "Validate",
        "supplierLabel": "Shipper information"
      },
      "locationModal": {
        "form": {
          "contact": {
            "email": "Email address",
            "name": "Contact name",
            "phone": "Phone number"
          },
          "countryHint": "Country",
          "countryOtherAddress": {
            "city": "City",
            "postalCode": "Postal code"
          },
          "countryUsaAddress": {
            "city": "City",
            "postalCode": "ZIP",
            "state": "State"
          },
          "supplierNameHint": "Supplier Name"
        },
        "title": "Add a location"
      }
    }
  },
  "bookingDone": {
    "bodyTitle": "Your shipment has been booked",
    "buttonText": "See on OVRSEA",
    "message": "We are pleased to confirm that this shipment has been booked with {shipownerName}.",
    "messageBis": "We are pleased to confirm that this shipment has been booked with {shipownerName}.",
    "shareTrackingLink": "Help your colleagues and partners keep track of this shipment by sharing this",
    "title": "Shipment booked",
    "trackingLink": "public tracking link"
  },
  "browser": {
    "unsupported": {
      "discourageIE": {
        "ctaLink": "Switch to Microsoft Edge"
      },
      "header": "Improve your experience",
      "subheader": {
        "few": "",
        "many": "",
        "one": "You are using a browser that is not compatible with our application.",
        "two": "Try one of the following browsers for an optimal experience:"
      }
    }
  },
  "bugPage": {
    "errorDescription": "Oops, something broke !",
    "errorPageTitle": "Erreur",
    "errorSecondLine": "We're doing our very best to deliver a stable and reliable user experience. Sometimes, a bug escapes !",
    "errorThirdLineOne": "Feel free to contact me at",
    "errorThirdLineTwo": "to report it, we will fix it ASAP. Thank you !",
    "fourofour": "Page not found",
    "homeLink": "Back to Dashboard",
    "notFoundPageTitle": "Page Not Found"
  },
  "carbon-compensation": {},
  "carbonEmissionsCard": {
    "emissionsAvoidedLabel": "CO2 avoided",
    "emissionsLabel": "CO2 emitted",
    "intensityLabel": "Carbon intensity",
    "title": "Carbon footprint"
  },
  "checkoutCard": {
    "fifthStep": {
      "properties": {}
    },
    "renderDetailedContainers": {},
    "renderDetailedPackages": {},
    "renderPackage": {}
  },
  "clientTasks": {
    "AcceptBookingRequest": {
        "description": "{supplierName} has created a new booking request. Please review it to define how to attribute this shipment.",
        "modalTitle": "Review request",
        "orderTypeQuestion": "How would you like to attribute this shipment?",
        "title": "New booking request from {supplierName}"
      },
    "ApproveDraftBl": {
      "action": "Open draft",
      "modal": {
        "commentErrorLabel": "Hang on! Tell us which parts should be amended.",
        "commentHint": "Write a message...",
        "commentLabel": "Comments about the draft",
        "requiredField": "This field is required",
        "title": "{blType} Draft approval"
      },
      "title": "B/L - Bill of Lading draft ready for approval."
    },
    "AskContainersInfo": {
      "containers": "Containers",
      "selectContainers": "Select containers...",
      "sendEmailTo": "Send email to"
    },
    "AskContainersInfoContainer": {
      "askContainersInfo": "Ask client to check container information or to provide it"
    },
    "ContainerInfoField": {
      "bookingNumber": "Booking number",
      "containerNumber": "Container number",
      "grossWeight": "Gross weight",
      "leadNumber": "Seal number",
      "tareWeight": "Tare weight of container",
      "vgmWeight": "VGM weight",
      "weightMethod1": "Method 1 - Weighing",
      "weightMethod2": "Method 2 - Addition of masses",
      "weightMethodUsed": "Method used to calculate weight"
    },
    "FillContainersInfo": {
      "disclaimer": "By ticking this box, we certify that the aforementionned VGM was calculated in accordance with the criteria defined by SOLAS convention and authorize Foresea Technologies SAS (commercial name : OVRSEA) to transmit this document to adequate authorities and also to the maritime company. All the consequences and fees related to an incorret VGM are exclusively at the expense of the expeditor and without possible recourse to Foresea Technologies.",
      "fillAllFields": "Please check the accuracy of the information below, or to provide them if missing.",
      "foldDown": "Hide",
      "required": "Required field",
      "submit": "Submit",
      "title": "Please check container information."
    },
    "UploadBlModal": {
      "title": "Please upload {blType} draft document"
    },
    "UploadDocuments": {
      "button": "Upload file",
      "prompt": "Upload your files or  in this zone",
      "title": "Please upload the following documents",
      "types": {
        "dgd": "DGD",
        "invoice": "Commercial Invoice",
        "msds": "MSDS",
        "packingList": "Packing list"
      }
    },
    "askDocuments": {
      "content": "Select the documents you need",
      "error": "At least one document must be selected",
      "title": "Ask client to send documents"
    },
    "rating": {
      "button": {
        "ignore": "Ignore"
      },
      "modal": {
        "header": "Your feedback is a precious contribution for us",
        "hint": "Could you tell us how was your experience on this shipment ?",
        "placeholder": "Reactivity of the team, delay of quotes answers, incidents handling..."
      },
      "task": "How was your experience on this shipment with OVRSEA ?"
    },
    "reveal": "Fournir"
  },
  "clients": {
    "document": {
      "title": "Clients"
    },
    "network": {
      "index": {
        "tab": {
          "hermes": {
            "connections": "Connections",
            "locations": "Locations"
          }
        }
      }
    }
  },
  "colis": {
    "text": "Package"
  },
  "comment": {
    "comments": "Comments",
    "competitivePrices": "Price is competitive",
    "header": "Title",
    "nonCompetitivePrices": "Price is not competitive",
    "other": "Other",
    "tooLong": "Too long"
  },
  "company": {
    "selectfield": {
      "extraAction": {}
    }
  },
  "confirm": {
    "deleteDocument": "Delete {name}"
  },
  "connections": {
    "addConnection": "Add a connection",
    "description": "Your accounts are connected based on the rights below. Refer to your sales representative for modifications.",
    "emptyState": {
      "buttonLabel": "Submit connection request",
      "description": "Monitor your connections here. Request your first connection below !",
      "title": "Manage your connections"
    },
    "requestConnectionForm": {
      "comments": {
        "label": "Comments",
        "placeholder": "Ex: share all my shipments to Hong-Kong"
      },
      "companyName": {
        "label": "Company name",
        "placeholder": "Company",
        "required": "Company name required"
      },
      "contacts": {
        "label": "Contacts in that company",
        "placeholder": "name@commpany.com; name2@company.com"
      },
      "descriptionBlock1": "Invite other parties to join the OVRSEA platform and enable end-to-end visibility on your shipments.",
      "descriptionBlock2": "When you submit your invitation request, we will promptly create access to the platform for the contacts you enter below.",
      "relationType": {
        "label": "This company is your...",
        "options": {
          "agent": "Agent or broker",
          "child": "Subsidiary",
          "client": "Client",
          "logistics": "Logistics Partner (3PL)",
          "other": "Other",
          "parent": "Parent company",
          "sister": "Sister company",
          "supplier": "Supplier"
        },
        "required": "Relationship type required"
      },
      "submit": "Request Connection",
      "submitSuccess": "Connection request was sucessfully submitted",
      "title": "Add a connection"
    },
    "requestModificationModal": {
      "body": "Please refer to your Sales Manager to modify your connection with {clientName}.",
      "title": "Request modification"
    },
    "roles": {
      "agent": "Agent",
      "buyer": "Client",
      "child": "Subsidiary",
      "client": "Client",
      "parent": "Parent company",
      "restrictedPartner": "Restricted partner",
      "supplier": "Supplier",
      "unrestrictedPartner": "Unrestricted partner"
    },
    "subtitle": "You are connected with <strong>{connectionsNumber} {connectionsNumber, plural, one {party} other {parties}}</strong>. They can interact with your shipments based on their permissions",
    "title": "Your connections",
    "visibility": {
      "allFinancialDetails": {
        "theirs": "They can consult quotes and invoices from your shipments.",
        "title": "See financials",
        "yours": "You can consult quotes and invoices from their shipments."
      },
      "allShipments": {
        "theirs": "They can access all your shipments.",
        "title": "Access all shipments",
        "yours": "You can access all their shipments directly on your platform."
      },
      "allowed": "Allowed",
      "blocked": "Blocked",
      "editDisabled": "This permission cannot be changed",
      "makingRequestsAllowed": {
        "theirs": "They can make quote and shipment requests for you.",
        "title": "Make requests",
        "yours": "You can make quote and shipment requests for them."
      },
      "makingRequestsBlocked": {
        "theirs": "They cannot make quote or shipment requests for you.",
        "title": "Make requests",
        "yours": "You cannot make quote and shipment requests for them."
      },
      "noFinancialDetails": {
        "theirs": "They cannot consult quotes and invoices from your shipments.",
        "title": "See financials",
        "yours": "You cannot consult quotes and invoices from their shipments."
      },
      "sharedShipments": {
        "theirs": "They cannot access your shipments unless you share them.",
        "title": "Access all shipments",
        "yours": "You cannot access their shipments unless they share them."
      },
      "theirs": "{relationName}'s rights on you",
      "validatingRequestsAllowed": {
        "theirs": "They can validate quote and shipment requests on your behalf.",
        "title": "Validate requests",
        "yours": "You can validate quote and shipment requests on their behalf."
      },
      "validatingRequestsBlocked": {
        "theirs": "They cannot validate shipment requests on your behalf.",
        "title": "Validate requests",
        "yours": "You cannot validate shipment requests on their behalf."
      },
      "yours": "Your rights on {relationName}"
    }
  },
  "contactCard": {
    "emailAddress": "E-mail address"
  },
  "containerCard": {
    "totalProducts": "Total products"
  },
  "containers": {
    "table": {
      "atRiskDemDet": "At risk of D&D fees",
      "container": "Container",
      "delivery": "Delivery",
      "deliveryPlanned": "{quantity, plural, one {Planned unloading} other {Planned unloadings}}",
      "deliveryToBePlanned": "{quantity, plural, one {Unloading to schedule} other {Unloadings to schedule}}",
      "destination": "Destination",
      "emptyTable": "No containers in transit at the moment.",
      "estimated": "Estimated",
      "eta": "ETA",
      "inTransit": "At sea",
      "origin": "Origin",
      "resetFilters": "Reset all filters",
      "type": "Type",
      "underCustomsControl": "Under customs control"
    }
  },
  "controls": {
    "message": {
      "select": {
        "bis": "Select"
      }
    }
  },
  "countries": {
    "ad": "Andorra",
    "ae": "United Arab Emirates",
    "af": "Afghanistan",
    "ag": "Antigua-and-Barbuda",
    "ai": "Anguilla",
    "al": "Albania",
    "am": "Armenia",
    "an": "Netherlands Antilles",
    "ao": "Angola",
    "ar": "Argentina",
    "as": "American Samoa",
    "at": "Austria",
    "au": "Australia",
    "aw": "Aruba",
    "ax": "Åland, Islands",
    "az": "Azerbaijan",
    "ba": "Bosnia and Herzegovina",
    "bb": "Barbados",
    "bd": "Bangladesh",
    "be": "Belgium",
    "bf": "Burkina Faso",
    "bg": "Bulgaria",
    "bh": "Bahrain",
    "bi": "Burundi",
    "bj": "Benin",
    "bl": "Saint-Barthelemy",
    "bm": "Bermuda",
    "bn": "Brunei Darussalam",
    "bo": "Bolivia",
    "br": "Brazil",
    "bs": "The Bahamas",
    "bt": "Bhutan",
    "bv": "Bouvet Island",
    "bw": "Botswana",
    "by": "Belarus",
    "bz": "Belize",
    "ca": "Canada",
    "cc": "Cocos (Keeling), Islands",
    "cd": "Democratic Republic of the Congo",
    "cf": "Central African Republic",
    "cg": "Congo",
    "ch": "Switzerland",
    "ci": "Ivory Coast",
    "ck": "Cook, Islands",
    "cl": "Chile",
    "cm": "Cameroon",
    "cn": "China",
    "co": "Colombia",
    "cr": "Costa Rica",
    "cs": "Serbia and Montenegro",
    "cu": "Cuba",
    "cv": "Cape Verde",
    "cx": "Christmas Island",
    "cy": "Cyprus",
    "cz": "Czech Republic",
    "de": "Germany",
    "dj": "Djibouti",
    "dk": "Denmark",
    "dm": "Dominica",
    "do": "Dominican Republic",
    "dz": "Algeria",
    "ec": "Ecuador",
    "ee": "Estonia",
    "eg": "Egypt",
    "eh": "Western Sahara",
    "er": "Eritrea",
    "es": "Spain",
    "et": "Ethiopia",
    "fi": "Finland",
    "fj": "Fiji",
    "fk": "Falkland Islands",
    "fm": "Federated States of Micronesia",
    "fo": "Faroe Islands",
    "fr": "France",
    "ga": "Gabon",
    "gb": "United Kingdom",
    "gd": "Grenada",
    "ge": "Georgia",
    "gf": "French Guyana",
    "gh": "Ghana",
    "gi": "Gibraltar",
    "gl": "Greenland",
    "gm": "The Gambia",
    "gn": "Guinea",
    "gp": "Guadeloupe",
    "gq": "Equatorial Guinea",
    "gr": "Greece",
    "gs": "South Georgia and Sandwich Islands",
    "gt": "Guatemala",
    "gu": "Guam",
    "gw": "Guinea-Bissau",
    "gy": "Guyana",
    "hk": "Hong Kong",
    "hm": "Heard Island and McDonald Islands",
    "hn": "Honduras",
    "hr": "Croatia",
    "ht": "Haiti",
    "hu": "Hungary",
    "id": "Indonesia",
    "ie": "Ireland",
    "il": "Israel",
    "in": "India",
    "io": "British Indian Ocean Territory",
    "iq": "Iraq",
    "ir": "Iran",
    "is": "Iceland",
    "it": "Italy",
    "jm": "Jamaica",
    "jo": "Jordania",
    "jp": "Japan",
    "ke": "Kenya",
    "kg": "Kyrgyzstan",
    "kh": "Cambodia",
    "ki": "Kiribati",
    "km": "Comoros",
    "kn": "Saint Kitts and Nevis",
    "kp": "North Korea",
    "kr": "South Korea",
    "kw": "Kuwait",
    "ky": "Cayman Islands",
    "kz": "Kazakhstan",
    "la": "Laos",
    "lb": "Lebanon",
    "lc": "Saint Lucia",
    "li": "Liechtenstein",
    "lk": "Sri Lanka",
    "lr": "Liberia",
    "ls": "Lesotho",
    "lt": "Lithuania",
    "lu": "Luxembourg",
    "lv": "Latvia",
    "ly": "Libya",
    "ma": "Morocco",
    "mc": "Monaco",
    "md": "Moldavia",
    "me": "Montenegro",
    "mg": "Madagascar",
    "mh": "Marshall Islands",
    "mk": "Republic of Macedonia",
    "ml": "Mali",
    "mm": "Myanmar",
    "mn": "Mongolia",
    "mo": "Macau",
    "mp": "Northern Mariana Islands",
    "mq": "Martinique",
    "mr": "Mauritania",
    "ms": "Montserrat",
    "mt": "Malta",
    "mu": "Mauritius",
    "mv": "Maldives",
    "mw": "Malawi",
    "mx": "Mexico",
    "my": "Malaysia",
    "mz": "Mozambique",
    "na": "Namibia",
    "nc": "New Caledonia",
    "ne": "Niger",
    "nf": "Norfolk Island",
    "ng": "Nigeria",
    "ni": "Nicaragua",
    "nl": "Netherlands",
    "no": "Norway",
    "np": "Nepal",
    "nr": "Nauru",
    "nu": "Niue",
    "nz": "New Zealand",
    "om": "Oman",
    "pa": "Panama",
    "pe": "Peru",
    "pf": "French Polynesia",
    "pg": "Papua New Guinea",
    "ph": "Philippines",
    "pk": "Pakistan",
    "pl": "Poland",
    "pm": "Saint Pierre and Miquelon",
    "pn": "Pitcairn Islands",
    "pr": "Puerto Rico",
    "ps": "Palestine, State of",
    "pt": "Portugal",
    "pw": "Palau",
    "py": "Paraguay",
    "qa": "Qatar",
    "re": "Réunion",
    "ro": "Romania",
    "rs": "Serbia",
    "ru": "Russia",
    "rw": "Rwanda",
    "sa": "Kingdom of Saudi Arabia",
    "sb": "Solomon Islands",
    "sc": "Seychelles",
    "sd": "Sudan",
    "se": "Sweden",
    "sg": "Singapore",
    "sh": "Saint Helena",
    "si": "Slovenia",
    "sj": "Svalbard and Jan Mayen",
    "sk": "Slovakia",
    "sl": "Sierra Leone",
    "sm": "San Marino",
    "sn": "Senegal",
    "so": "Somalia",
    "sr": "Suriname",
    "st": "São Tomé and Príncipe",
    "sv": "El Salvador",
    "sx": "Sint Maarten",
    "sy": "Syria",
    "sz": "Swaziland",
    "tc": "Turks and Caicos Islands",
    "td": "Chad",
    "tf": "French Southern and Antarctic Lands",
    "tg": "Togo",
    "th": "Thailand",
    "tj": "Tajikistan",
    "tk": "Tokelau",
    "tl": "East Timor",
    "tm": "Turkmenistan",
    "tn": "Tunisia",
    "to": "Tonga",
    "tr": "Turkey",
    "tt": "Trinidad and Tobago",
    "tv": "Tuvalu",
    "tw": "Taiwan",
    "tz": "Tanzania",
    "ua": "Ukraine",
    "ug": "Uganda",
    "um": "United States Minor Outlying Islands",
    "us": "USA",
    "uy": "Uruguay",
    "uz": "Uzbekistan",
    "va": "Vatican City",
    "vc": "Saint Vincent and the Grenadines",
    "ve": "Venezuela",
    "vg": "British Virgin Islands",
    "vi": "United States Virgin Islands",
    "vn": "Vietnam",
    "vu": "Vanuatu",
    "wf": "Wallis and Futuna",
    "ws": "Samoa",
    "ye": "Yemen",
    "yt": "Mayotte",
    "za": "South Africa",
    "zm": "Zambia",
    "zw": "Zimbabwe"
  },
  "createLocationForm": {
    "confirmCreationForm": {
      "alertTitle": "A pinpoint was found via Google for",
      "body": "Verifying the overall coordinates of this location will enable a safer and faster quoting process!",
      "cancelButtonLabel": "Skip",
      "confirmButtonLabel": "Confirm pinpoint",
      "header": "Pinpoint found for this location"
    },
    "errors": {
      "address": "Address: required field",
      "city": "City: required field",
      "companyName": "Company name: required field",
      "country": "Country: required field",
      "state": "State: required field",
      "zipCode": "Zipcode: required field"
    }
  },
  "createMessage": {
    "noNotification": "No one will be notified",
    "notifiedPersons": "will be notified",
    "sendMessage": "Send",
    "uploadPropositionAlert": "Please fill out the form to submit your proposition so that {clientName} can consider it.",
    "viewableBy": "Share with {numberOfParties, plural, =0{# party} one{# party} other{# parties}}",
    "writeMessage": "Write your message or mention others with @"
  },
  "creativeCommon": {
    "attribution": {
      "fileIcons": "Document icons created by Retinaicons of Flaticon"
    }
  },
  "dashboard": {
    "client": {
      "blacklist": {
        "body": {
          "mainText": "You cannot ask for quotes anymore with your account. Your credentials remain active to allow you access to historical shipments and documents.",
          "redbullSecondText": "We would like to inform you that due to the lack of activity on your ovrsea.com account, we have decided to close it.\n\nUnfortunately, the lack of shipments carried out by OVRSEA for your company does not correspond to our desire to establish recurring commercial relationships with our partners.\n\nYou will still be able to log in to your account to consult documents relating to your previous shipments.\n\nIf you have any questions, please send an email to romain@ovrsea.com.\n\nWe thank you for your understanding and wish you all the best for the future.\n\nRomain from the OVRSEA team",
          "secondText": "Should you have any question, please contact your dedicated Account Executive."
        },
        "btnClose": "Close",
        "title": "Your company account has been deactivated"
      }
    },
    "document": {
      "title": "Dashboard"
    },
    "empty": {
      "cta": "Receive a quote",
      "supplierTitle": "Launch your OVRSEA shipment in just a few clicks ",
      "title": "Receive quotes from OVRSEA in just a few clicks"
    },
    "hermes": {
      "cards": {
        "asked": "Asked {hours}h ago",
        "askedOn": "Asked on",
        "cargoReadyOn": "Cargo ready on",
        "crdOn": "CRD on {date}",
        "createdAgo": "Created {dateAgo}"
      },
      "headers": {
        "actionsToTake": {
          "plural": "Actions to take",
          "singular": "Action to take"
        },
        "availableOffers": {
          "plural": "Offers to validate",
          "singular": "Offer to validate"
        },
        "availableTraffics": {
          "plural": "traffics operated with pricing grids",
          "singular": "traffics operated with pricing grids"
        },
        "doneShipments": {
          "plural": "{length} finished shipments",
          "singular": "{length} finished shipment"
        },
        "draftQuotations": {
          "plural": "{length} draft quotations to complete",
          "singular": "{length} draft quotation to complete"
        },
        "inProgress": {
          "plural": "{length} ongoing shipments",
          "singular": "{length} ongoing shipment"
        },
        "pending": {
          "plural": "{length} planned shipments",
          "singular": "{length} planned shipment"
        },
        "pendingActions": {
          "plural": "{length} shipments require your attention",
          "singular": "{length} shipment requires your attention"
        },
        "pendingBookingRequests": {
          "plural": "Requests to validate",
          "singular": "Request to validate"
        },
        "pendingQuotations": {
          "plural": "{length} quotes in progress",
          "singular": "{length} quote in progress"
        },
        "quoteRequestsToAnswer": {
          "plural": "Quotes to answer",
          "singular": "Quote to answer"
        },
        "shipmentRatings": {
          "plural": "Shipments to review",
          "singular": "Shipment to review"
        },
        "shipmentsToBook": {
          "plural": "Shipments to book",
          "singular": "Shipment to book"
        },
        "shipmentsToInvoice": {
          "plural": "Shipments to invoice",
          "singular": "Shipment to invoice"
        }
      },
      "shipments": {
        "deliveryDate": "Delivery {date}",
        "departureDate": "Starts {date}",
        "etd": "ETD {date}",
        "pickupDate": "Pickup {date}"
      }
    }
  },
  "dashboardHermes": {
    "actions": {},
    "planning": {
      "tabs": {
        "arrivals": "Arrivals",
        "deliveries": "Deliveries",
        "departures": "Departures",
        "pickups": "Pickups"
      },
      "weekRange": "Week {weekNumber} - {fromDate} - {toDate}"
    },
    "submitVgm": "Submit VGM",
    "subscribeToNewsletter": {
      "CTA": "Subscribe to newsletter",
      "body": "Receive your market digest and advice from OVRSEA weekly"
    }
  },
  "declineBookingRequestConfirmModal": {
    "header": "Decline this booking request?"
  },
  "declineBookingRequestConfirmText": {
    "header": "Decline"
  },
  "default": {
    "document": {
      "title": "OVRSEA - The digital freight forwarder"
    },
    "excludingTaxes": "TOTAL (excl. taxes)",
    "message": {
      "select": "Select"
    }
  },
  "defaults": {
    "accessFolder": "Go to shipment",
    "available": "available",
    "availablePlural": "available",
    "book": "Book",
    "bulk": "Bulk",
    "cargo": "Cargo",
    "cargoReadyDate": "Cargo ready",
    "collapse": "Collapse",
    "comment": "Comment",
    "company": "Carrier",
    "container": {
      "controlledTemperature": "Temperature-controlled",
      "hazardous": "Hazardous",
      "lithium": "Batteries",
      "magnetic": "Magnetic",
      "name": "Container",
      "refrigerated": "Refrigerated"
    },
    "containers": "Containers",
    "copyOf": "Copy of {value}",
    "create": "Create",
    "date-will-expire": "Expires on",
    "datePrefix": "On",
    "day": "day",
    "day-of-week": {
      "narrow": {
        "daily": "Daily",
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "Su",
        "thursday": "Th",
        "tuesday": "Tu",
        "wednesday": "We"
      }
    },
    "days": "days",
    "decline": "Decline",
    "deliveryHarbor": "Destination Port",
    "deliveryTargetDate": "Desired delivery",
    "destination": "Destination",
    "detail": "Details",
    "details": "Details",
    "dimensions": "Dimensions",
    "direct": "Direct",
    "disclose": {
      "less": "Reduce",
      "more": "Show more"
    },
    "download": "Download",
    "duplicate": "Replicate",
    "emitedCo2": "CO2 emitted",
    "exchangeRate": "Indicative exchange rate :",
    "expand": "Expand",
    "expiration": "Expiration",
    "expire": "Expires",
    "expired-date": "Expired on",
    "expiredOn": "Expired on",
    "filter": "Filter",
    "flightFrequencies": "Frequency of flights",
    "freight-rate": "Freight price",
    "freightMode": "Freight mode",
    "fullTruck": "Full-Truck",
    "hazardousClass": "Class/UN Number",
    "heightSymbol": "H",
    "hello": "Hello",
    "hideMap": "Hide map",
    "hour": "hour",
    "hours": "hours",
    "incoterm": "Incoterm",
    "indirect": "Indirect",
    "insurance": "Insurance",
    "insuranceAdValorem": "Ad Valorem Insurance",
    "itinerary": "Route",
    "knowMore": "Learn more",
    "lengthSymbol": "L",
    "lithium": "Batteries",
    "load": {
      "height": "Height",
      "length": "Length",
      "packages": "Packages",
      "quantity": "Quantity",
      "ref": "Reference",
      "specificities": "Specificities",
      "stackable": "Stackable",
      "type": "Type",
      "types": {
        "crate": "Rigid Crate",
        "euro": "120 x 80 cm Pallet",
        "iso": "120 x 100 cm Pallet",
        "package": "Package",
        "specific": "Specific Pallet",
      },
      "volume": "Volume",
      "weight": "Weight / U",
      "width": "Width",
    },
    "loadingReference": "Loading reference",
    "magnetic": "Magnetic",
    "mapNotSupported": "Your browser needs to be updated to display maps.",
    "marketValue": "Commercial Value",
    "me": "me",
    "merchandise": "Transported Goods",
    "merchandiseDescription": "Cargo description",
    "message": "Message",
    "navigation": {
      "billing": "Invoices",
      "co2Footprint": "Carbon footprint",
      "customs": "Customs",
      "dashboard": "Dashboard",
      "documents": "Documents",
      "gestion": "Management",
      "network": "Network",
      "planning": "Planning",
      "products": "Orders",
      "quotations": "Quotes",
      "quote-requests": "Quote Requests",
      "reporting": "Reporting",
      "shipments": "Shipments",
      "traffics": "Traffics",
      "warehouse": "Warehouse"
    },
    "negotiatedOffers": "Negotiated Offers",
    "newQuotation": "New quote",
    "next-departure": "Next sailing",
    "next-departures": "Next sailings",
    "next-flight": "Next flight",
    "noData": "No data",
    "noDocumentTypeFound": "No type found",
    "noResults": "No result",
    "notStackable": "Non stackable",
    "notSubscribed": "Not subscribed",
    "noted": "Noted",
    "on": "on",
    "origin": "Origin",
    "package": "Package",
    "packages": "Packages",
    "pallet": "Pallet",
    "pallets": "Pallets",
    "pickupHarbor": "Origin Port",
    "pickupTargetDate": "Cargo availability",
    "propositions": "{numberOfPropositions, plural, one{# proposition} other{# propositions}}",
    "quote": "Quote",
    "rename": "Rename",
    "replyPrompt": "You have an unsent reply, are you sure you wish to leave the page?",
    "requiredField": "Required field",
    "results": "results",
    "seeDetails": "Show details",
    "selected": "Selected",
    "share": "share",
    "shares": "shares",
    "showMap": "Show map",
    "solidBox": "Rigid crate",
    "solidBoxes": "Rigid crates",
    "specialInstructions": "Specific Instructions",
    "specificities": "Specifics",
    "specificity": "Specifics",
    "stackable": "Stackable",
    "summary": "Summary",
    "tables": {
      "location-name": {
        "default": "Warehouse"
      }
    },
    "taxableWeight": "Chargeable weight",
    "temperatureControlled": "Temperature controlled",
    "thisWeek": "This week",
    "toFill": "To fill",
    "total": "Total",
    "totalChargeableWeight": "Chargeable weight",
    "totalDimensions": "Total dimensions",
    "totalDuration": "Total journey",
    "totalVolume": "Total volume",
    "totalWeight": "Total weight",
    "traffic": "Traffic",
    "transit": "Transit time",
    "transit-time": {
      "days": "days",
      "direct-flight": "Direct flight"
    },
    "transitTimeDTPHelp": "Door-to-Port = pre-carriage & operation at origin port",
    "transitTimeHelp": "Total journey duration includes Port-to-Port transit time and estimates for durations of terminal handlings as well as pre- and/or on-carriage depending on incoterm.",
    "transitTimePTDHelp": "Port-to-Door = operations at destination port & on-carriage",
    "type": "Type",
    "unitDimensions": "Unit dimensions",
    "units": "units",
    "unloadingReference": "Unloading reference",
    "updatedFromNow": "Updated {fromNow}",
    "urgentRequest": "This request is urgent",
    "validForDepartureBy": {
      "detailed": {
        "air": "Rate valid if flight departs by",
        "ocean": "Rate valid if vessel departs by",
        "rail": "Rate valid if train departs by",
        "truck": "Rate valid if truck departs by"
      },
      "inGateDate": "Valid if in-gate at {departureLocode} by",
      "pickupDate": "Valid for pickup by",
      "short": "Valid for a departure from {departureLocode} by"
    },
    "validate": "Validate",
    "validation": {
        "number": "Must be a number",
        "positive": "Must be positive"
    },
    "validity": "Validity",
    "validityHelp": "In case of departure after this date, the rate can evolve depending on the market situation.",
    "via": "Via",
    "warning": "Warning",
    "week": "Week",
    "widthSymbol": "W"
  },
  "delete": {
    "document": "Delete"
  },
  "developer": {
    "document": {
      "title": "Developer"
    }
  },
  "dgr": "Dangerous",
  "document": {
    "delete": "Delete",
    "download": "Download",
    "table": {
      "createdAt": "Added on",
      "documentType": "Type",
      "extension": "Format",
      "foreseaName": "Folder",
      "name": "Name",
      "unavailable": "Not available"
    }
  },
  "documents": {
    "deleteModal": {},
    "document": {
      "title": "Documents"
    },
    "dropzone": "Drop files or click here to upload",
    "table": {
      "emptyTable": {
        "document": "No document yet."
      }
    }
  },
  "documentsTab": {
    "ovrseaDocuments": {
      "viewerModal": {
        "browserTooOld": "Your browser does not support previewing documents.",
        "fileTooBig": "File is too large to preview.",
        "imaWihtoutVatCustoms": "IMA documents must have VAT or customs amount",
        "noDocument": "This document does not exist",
        "noPdfToShow": "No PDF file to display",
        "notAvailable": "Preview not available.",
        "preview": "Preview"
      }
    }
  },
  "download": {
    "documentBis": "Download",
    "downloadPropositions": "Download all quotes",
    "downloadReport": "Export as CSV",
    "exportShipmentReport": "Download shipments data",
    "winddleExport": "Export for Winddle"
  },
  "dropzone": {
    "browse": {},
    "dragDrop-or": {}
  },
  "eShipping": {
    "shareForm": {
      "awaitingDetails": "Awaiting packaging details",
      "consignee": "Consignee",
      "copied": "Copied",
      "copy": "Copy link",
      "description": "Before generating the eShipping link, please ensure that the following information is available and accurate.",
      "notify": "Notify",
      "packagingDetails": "Packaging details",
      "save": "Save",
      "shipper": "Shipper"
    },
    "verificationForm": {
      "format": "3 letters + 4 digits",
      "invalidReference": "This reference is invalid. Please try with another reference.",
      "title": "To access the file, please type the file reference"
    }
  },
  "editCargo": {
    "title": "Edit cargo",
  },
  "editQuotationDates": {
    "cargoReadyDate": "Cargo ready date",
      "deliveryTargetDate": "Desired delivery date",
      "openModalButton": "Edit dates",
      "title": "Edit target dates for pickup and delivery"
  },
  "editRoute": {
    "title": "Edit route",
  },
  "email": {
    "agentInformationShared": {
      "button": "See on OVRSEA",
      "content": "Below are the contact details of our departure agent so that you or your supplier can get in touch with them",
      "title": "Contact of agent at departure"
    },
    "appointmentAccepted": {
      "body": "{userName} ({companyName}) has accepted appointment request. It is now planned.",
      "title": "Appointment confirmed"
    },
    "appointmentCancelled": {
      "body": "{userName} ({companyName}) has cancelled the appointment.",
      "title": "Appointment cancelled"
    },
    "appointmentMarkedAsDone": {
      "delivery": {
        "body": "The delivery of cargo has been confirmed.",
        "title": "Cargo delivered"
      },
      "loading": {
        "body": "The loading of the container has been confirmed.",
        "title": "Container loaded"
      },
      "pickup": {
        "body": "The pickup of cargo has been confirmed.",
        "title": "Cargo picked up"
      },
      "unloading": {
        "body": "The unloading of the container has been confirmed.",
        "title": "Container unloaded"
      }
    },
    "appointmentRequested": {
      "body": "{userName} ({companyName}) has requested a new appointment.",
      "title": "New appointment request"
    },
    "appointmentRescheduled": {
      "body": "{userName} ({companyName}) has suggested another appointment schedule.",
      "title": "Appointment time change requested"
    },
    "appointments": {
      "body": "{userName} ({companyName}) has requested a new appointment.",
      "button": "See on OneChain",
      "container": "Container",
      "delivery": "Delivery",
      "loading": "Loading",
      "pickup": "Pickup",
      "references": "References",
      "title": "New appointment request",
      "type": "Type",
      "unloading": "Unloading"
    },
    "approveBl": {
      "button": "See on OVRSEA",
      "subTitle": {
        "air": "Draft AWB is available for your validation.",
        "ocean": "Draft BL is available for your validation."
      }
    },
    "blApproved": {
      "emailLabel": "Email: ",
      "nameLabel": "Name: ",
      "shipmentLabel": "Shipment: ",
      "userValidatorLabel": "User who validated: "
    },
    "bookingRequestAccepted": {
      "title": "Kudos! You have a booking request accepted by {userName} from {nameOfClient}",
      "viewOnAtlas": "View on Atlas"
    },
    "bookingRequestDeclined": {
      "text": "Booking request {foreseaName} declined by",
      "title": "Booking request declined",
      "viewOnAtlas": "View on Atlas"
    },
    "containerAdded": {
      "containerAdded": "Container added ",
      "label": {
        "containerType": "Container type: ",
        "hazardous": "Hazardous: ",
        "hazardousDetails": "Hazardous details: ",
        "loadingReference": "Loading reference: ",
        "positDate": "Posit Date: "
      },
      "toQuotationText": "to quote",
      "toShipmentText": "to shipment "
    },
    "containerDeleted": {
      "containerDeleted": "Container deleted ",
      "fromQuotationText": "from quote",
      "fromShipmentText": "from shipment ",
      "label": {
        "containerType": "Container type: ",
        "hazardous": "Hazardous: ",
        "hazardousDetails": "Hazardous details: ",
        "loadingReference": "Loading reference: ",
        "positDate": "Posit Date: "
      }
    },
    "containerUpdated": {
      "containerUpdatedIn": "Container edited in",
      "label": {
        "containerName": "Container name",
        "containerNumber": "Container number",
        "containerType": "Container type ",
        "grossWeight": "Gross weight",
        "hazardous": "hazardous ",
        "hazardousDetails": "Hazardous details ",
        "leadNumber": "Leading number",
        "loadingReference": "Loading reference ",
        "newValueTitle": "New value",
        "numberOfPackages": "Number of packages",
        "numberOfPallets": "Number of pallets",
        "oldValueTitle": "Old value",
        "positDate": "Posit Date ",
        "tareWeight": "Tare weight",
        "volume": "Volume"
      },
      "toQuotationText": "the quote",
      "toShipmentText": "the shipment "
    },
    "containersInfoAsked": {
      "body": "We invite you to verify the accuracy of the information on your container(s). If any information is missing - VGM, seal number - we invite you to provide them directly on our platform.",
      "button": "Check information",
      "thanks": "Thank you in advance.",
      "title": "Container information to check on your shipment."
    },
    "defaults": {
      "tmsButton": "See on OneChain"
    },
    "documentsAsked": {
      "button": "See on OVRSEA",
      "message": {
        "plural": "Please upload the following documents for this shipment:",
        "singular": "Please upload the following document for this shipment:"
      },
      "thanks": "Thank you in advance,",
      "title": {
        "plural": "Documents to provide on your shipment",
        "singular": "Document to provide on your shipment"
      }
    },
    "globalFooter": {
      "cgv": "Privacy and General Terms & Conditions",
      "helpCenter": "Help Center",
      "madeByOVRSEA": "Designed by OVRSEA"
    },
    "invitationAccepted": {
      "button": "🚀  Go to OVRSEA",
      "subTitle": "Our platform Hermès will provide you with the optimal experience of shipment handling for {clientName}.",
      "supportTextOne": "If you have any questions",
      "supportTextTwo": "Our teams are available on the platform and on the phone to give you a prompt reply.",
      "title": "{userName}, you are now on OVRSEA 🎉"
    },
    "invoiceUploaded": {
      "body": "{issuerName} has uploaded an invoice for this shipment.",
      "buttonLabel": "See on OneChain",
      "title": "New invoice available"
    },
    "loadTypeUpdated": {
      "label": {
        "after": "After: ",
        "before": "Before: ",
        "containerType": "container's type: ",
        "containers": "{containerCount} Container(s) ",
        "grossWeight": "Gross weight:",
        "hazardous": "Hazardous: ",
        "hazardousDetails": "Hazardous details: ",
        "leadNumber": "Leading number: ",
        "loadingReference": "Loading reference: ",
        "numberOfPackages": "Number of packages: ",
        "numberOfPallets": "Number of pallets: ",
        "positDate": "Posit Date: ",
        "tareWeight": "Tare weight: ",
        "volume": "Volume: "
      },
      "text": "The following cargo has been removed:",
      "title": "Load's type edited ",
      "toQuotationText": "to the quote",
      "toShipmentText": "to shipment ",
      "viewInAtlas": "View in Atlas"
    },
    "loadUpdated": {
      "label": {
        "hazardous": "Hazardous: ",
        "hazardousDetails": "Hazardous details: ",
        "lithium": "Lithium: ",
        "loadType": "Type: ",
        "magnetic": "Magnetic: ",
        "newValueTitle": "New value: ",
        "numberOfPackages": "Number of packages: ",
        "oldValueTitle": "Old value: ",
        "refrigerated": "Refrigerated: ",
        "totalVolume": "Total volume: ",
        "totalWeight": "Total weight: "
      },
      "loadAdded": "Load added "
    },
    "loadsUpdated": {
      "label": {
        "commodity": "Commodity",
        "fullTruck": "Full truck",
        "hazardous": "Hazardous: ",
        "lithium": "Lithium: ",
        "magnetic": "Magnetic: ",
        "newCargaisonTitle": "New loads: ",
        "oldCargaisonTitle": "Old loads: ",
        "properties": "Properties",
        "refrigerated": "Refrigerated: ",
        "stackable": "Stackable: ",
        "totalVolume": "Total volume: ",
        "totalWeight": "Total weight: ",
        "vrac": "Vrac"
      },
      "loadsUpdated": "Loads updated ",
      "toQuotationText": "to the quote",
      "toShipmentText": "to shipment ",
      "viewInAtlas": "View in Atlas"
    },
    "multipleDeliveriesPlanned": {
      "containerDeliveryDateColumn": "Delivery date",
      "containerNumberColumn": "Container n°",
      "containerTypeColumn": "Type",
      "content": "Please find below the delivery planning of your {numberOfContainers} containers for the shipment {fullShipmentName} to {fullDeliveryAddress}",
      "goToOvrseaButtonLabel": "See on OVRSEA"
    },
    "newInvitation": {
      "button": "Activate my account",
      "mainText": "To access the optimal experience for shipment management, you only need to activate your account below.",
      "noticeText": "You have received this email because you were signed up to ovrsea.com. If you do not wish to sign up, you can ignore this email - your account will not be activated.",
      "subTitle": "You have been invited to join the {clientName} account on OVRSEA.",
      "title": "Hello {userName}, you're almost there!"
    },
    "notificationSettings": {},
    "orderCancelled": {
      "body": "{userName} ({nameOfClient}) has cancelled this file.",
      "buttonLabel": "See on OneChain",
      "comments": "Comments",
      "reason": "Reason",
      "title": "File cancelled"
    },
    "propositionAccepted": {
      "title": "Kudos! You have a proposition accepted from {nameOfClient}",
      "viewOnAtlas": "View on Atlas"
    },
    "propositionDeclined": {
      "body": "{userName} ({nameOfClient}) has declined your proposition for this shipment.",
      "comments": "Comments",
      "directRoute": "Direct in {transitTimeInDays} days",
      "indirectRoute": "Via {transitHarbors} in {transitTimeInDays} days",
      "nextDeparture": "Next departure on {nextDepartureDate}",
      "reason": "Reason",
      "title": "Your proposition has been declined"
    },
    "quotation": {
      "air": "Air",
      "barge": "Barge",
      "bulk": "Bulk",
      "cargo": "Cargo",
      "controlledTemperature": "Temperature-controlled",
      "deliveryAirport": "Destination Airport",
      "deliveryHarbor": "Destination Port",
      "deliveryLocation": "Delivery Location",
      "deliveryStation": "Destination Station",
      "deliveryTargetDate": "Desired delivery",
      "fullTruck": "Full-Truck",
      "hazardous": "Dangerous",
      "insuranceAdValorem": "Ad Valorem Insurance",
      "lithium": "Batteries",
      "lithiumAlone": "Batteries packed loose",
      "lithiumContained": "Batteries contained in equipment",
      "lithiumPacked": "Batteries packed with equipment",
      "magnetic": "Magnetic",
      "merch": "Transported Goods",
      "notStackable": "Non stackable",
      "notSubscribed": "Not subscribed",
      "ocean": "Ocean",
      "package": "Package",
      "packages": "Packages",
      "pallet": "Pallet",
      "pallets": "Pallets",
      "pickupAirport": "Origin Airport",
      "pickupHarbor": "Origin Port",
      "pickupLocation": "Pick-up Location",
      "pickupStation": "Origin Station",
      "pickupTargetDate": "Cargo availability",
      "productDescription": "Cargo Description",
      "quotationSummary": "Quote Summary",
      "rail": "Rail",
      "shipmentTransmitted": "Your shipment request has successfully been posted.",
      "solidBox": "Rigid crate",
      "solidBoxes": "Rigid crates",
      "specialInstructions": "Specific Instructions",
      "specificities": "Cargo Details",
      "stackable": "Stackable",
      "taxableWeight": "Chargeable Weight",
      "totalDimensions": "Total Dimensions",
      "totalVolume": "Total Volume",
      "totalWeight": "Total Weight",
      "transmitted": "Your quote request has successfully been posted.",
      "truck": "Truck",
      "unitDimensions": "Unit Dimensions",
      "viewOnOVRSEA": "See on OVRSEA",
      "willGetBackToYou": "Our teams are working their best to give you a prompt reply."
    },
    "quotationDeclined": {
      "bestPriceObtained": "Best price obtained: ",
      "commentDetailsLabel": "Details reason:",
      "commentHeaderLabel": "Header's reason:",
      "title": "Quote {foreseaName} declined by {nameOfClient}",
      "viewOnAtlas": "View on Atlas"
    },
    "quotationUndoDeclined": {
      "text": "Quote {foreseaName} from {nameOfClient} has been reactivated"
    },
    "shipmentCancelled": {},
    "shipmentProductsAvailable": {
      "buttonText": "See on OVRSEA",
      "postTableText": "Once delivered, you will have access to <strong>detailed analytics on the shipping cost & CO2 impact</strong> per unit shipped.",
      "preButtonText": "If you notice any errors, please report it directly on OVRSEA.",
      "preTableText": "Here are the main products we identified for this shipment.",
      "subtitle": "Your products have been added",
      "table": {
        "description": "Description",
        "quantity": "Units in transit",
        "reference": "Product reference"
      }
    },
    "shipmentRating": {
      "answer": "Your answer will be a precious contribution for us.",
      "cta": "Share my feedback on this shipment",
      "feedback": "To keep improving the quality of our service, we would like to have your feedback on this shipment. How was your experience with OVRSEA's operational service on this shipment?",
      "formality": "Have a pleasant day,",
      "signature": "The OVRSEA team",
      "thanks": "Thank you for choosing OVRSEA to arrange your shipment.",
      "title": "Goods delivered on {deliveryDate}"
    },
    "shipmentShared": {
      "bodyPtOne": "Hello,",
      "bodyPtThree": "Benefit from real-time tracking and notifications for this shipment directly on our platform.",
      "bodyPtTwo": "OVRSEA is delighted to share with you this shipment carried out on behalf of {sharerName}."
    },
    "shipmentsDelayed": {
      "firstLine": "Please find below your on-going shipments with a recent ETA delay.",
      "goToFolders": "See on OVRSEA",
      "ovrsea": "The OVRSEA team",
      "secondLine": "Your operational representatives are working their best to adapt the rest of the shipment and will keep you informed.",
      "table": {
        "day": "day",
        "days": "days",
        "headers": {
          "initialEta": "initial ETA",
          "newEta": "New ETA",
          "oldEta": "Previous ETA",
          "shipment": "Shipment",
          "variant": "Delay"
        },
        "hour": "hour",
        "hours": "hours"
      },
      "title": "{count, plural, one {A} other {#}} {count, plural, one {shipment had its} other {shipments had their}} ETA updated recently"
    },
    "title": {
      "agentInformationShared": {
        "plural": "",
        "singular": "The contact of our agent is available - :foreseaName: - :clientName:"
      },
      "appointmentAccepted": {
        "plural": "",
        "singular": "Appointment confirmed - :foreseaName: - :clientName:"
      },
      "appointmentCancelled": {
        "plural": "",
        "singular": "Appointment cancelled - :foreseaName: - :clientName:"
      },
      "appointmentRequested": {
        "plural": "",
        "singular": "New appointment request - :foreseaName: - :clientName:"
      },
      "appointmentRescheduled": {
        "plural": "",
        "singular": "Appointment time change requested - :foreseaName: - :clientName:"
      },
      "billingAvailable": {
        "plural": "",
        "singular": "Invoice :billingNumber: available - Shipment :foreseaName: - :clientName:"
      },
      "blToApprove": {
        "plural": "",
        "singular": "[Important] Draft :freightMethod: to validate - :foreseaName: - :clientName:"
      },
      "bookingDone": {
        "plural": "",
        "singular": "Booking done - Shipment :foreseaName: - :clientName:"
      },
      "containersInfoAsked": {
        "plural": "",
        "singular": "[Important] Container information to check - :clientName: :foreseaName:"
      },
      "deliveryDone": {
        "plural": "",
        "singular": "Cargo delivered - :foreseaName: - :clientName:"
      },
      "deliveryPlanned": {
        "plural": "",
        "singular": "Delivery planned on :plannedDate:"
      },
      "deliveryPlannedForContainer": {
        "plural": "",
        "singular": "Delivery of container :containerNumber: planned on :plannedDate:"
      },
      "documentsAsked": {
        "plural": "[Important] Documents to provide :foreseaName: - :clientName:",
        "singular": "[Important] Document to provide :foreseaName: - :clientName:"
      },
      "etaAdvanced": {
        "plural": "",
        "singular": "ETA advanced to :formattedEta: (-:absoluteDelay: :delayUnit:) - :foreseaName:"
      },
      "etaDelayed": {
        "plural": "",
        "singular": "ETA postponed to :formattedEta: (+:absoluteDelay: :delayUnit:) - :foreseaName: - :clientName:"
      },
      "invitationAccepted": {
        "plural": "",
        "singular": "Your OVRSEA account is created"
      },
      "invoiceUploaded": {
        "plural": "",
        "singular": "New invoice uploaded - :foreseaName: - :shipmentName:"
      },
      "invoicesDue": {
        "plural": "[Important] Invoices due: shipments - :folderNames:",
        "singular": "[Important] Invoice due: shipment - :folderNames:"
      },
      "invoicesSummary": {
        "plural": "",
        "singular": "Invoices summary - :clientName: x OVRSEA"
      },
      "loadingDone": {
        "plural": "",
        "singular": "Container loaded - :foreseaName: - :clientName:"
      },
      "multipleDeliveriesPlanned": {
        "plural": "",
        "singular": "Delivery of the containers - :foreseaName: - :clientName:"
      },
      "newBookingRequest": {
        "plural": "",
        "singular": "[Important] New booking request to validate - :foreseaName: - :clientName:"
      },
      "newDocumentsByOvrsea": {
        "plural": "New documents - :foreseaName: - :clientName:",
        "singular": "New document - :foreseaName: - :clientName:"
      },
      "newInvitation": {
        "plural": "",
        "singular": "Activate your account"
      },
      "newMessage": {
        "plural": "",
        "singular": "New message - :foreseaName: - :clientName:"
      },
      "newQuotation": {
        "plural": "",
        "singular": "New quote request :foreseaName: - :clientName:"
      },
      "newQuotationAtlas": {
        "plural": "",
        "singular": "New quote request :foreseaName: - :clientName:"
      },
      "newShipment": {
        "plural": "",
        "singular": "New shipment :foreseaName: - :orderClientName:"
      },
      "newShipmentAtlas": {
        "plural": "",
        "singular": "New request :foreseaName: - :clientName:"
      },
      "orderPreparationCancelled": {
        "plural": "",
        "singular": "Order preparation cancelled - :foreseaName: - :shipmentName:"
      },
      "orderPreparationRequest": {
        "plural": "",
        "singular": "New order to prepare - :foreseaName: - :shipmentName:"
      },
      "pickingReadyForPickup": {
        "plural": "",
        "singular": "Cargo ready for pickup - :foreseaName: - :shipmentName:"
      },
      "pickupDone": {
        "plural": "",
        "singular": "Cargo picked up - :foreseaName: - :clientName:"
      },
      "propositionAvailable": {
        "plural": "[Important] New propositions available - :foreseaName: - :clientName:",
        "singular": "[Important] New proposition available - :foreseaName: - :clientName:"
      },
      "propositionDeclined": {
        "plural": "",
        "singular": "Proposition declined - :foreseaName: - :shipmentName:"
      },
      "propositionUpdated": {
        "plural": "[Important] Propositions updated - :foreseaName: - :clientName:",
        "singular": "[Important] Proposition updated - :foreseaName: - :clientName:"
      },
      "quotationShared": {
        "plural": "",
        "singular": ":clientOwnerName: shared quote :foreseaName: with you"
      },
      "quoteRequestAccepted": {
        "plural": "",
        "singular": "New shipment entrusted by :nameOfClient: - :foreseaName: - :shipmentName:"
      },
      "quoteRequestCancelled": {
        "plural": "",
        "singular": "Quote request cancelled - :foreseaName: - :shipmentName:"
      },
      "quoteRequestDeclined": {
        "plural": "",
        "singular": "Offer declined :foreseaName: - :shipmentName:"
      },
      "quoteRequestSent": {
        "plural": "",
        "singular": "New quote request from :nameOfClient: - :foreseaName: - :shipmentName:"
      },
      "shipmentArrived": {
        "plural": "",
        "singular": "Goods delivered on :arrivalDate: - :foreseaName:"
      },
      "shipmentAssigned": {
        "plural": "",
        "singular": "New shipment entrusted by :nameOfClient: - :foreseaName: - :shipmentName:"
      },
      "shipmentCancelled": {
        "plural": "",
        "singular": "Shipment cancelled - :foreseaName: - :shipmentName:"
      },
      "shipmentDelayed": {
        "plural": ":shipmentCount: shipments had their ETA updated recently",
        "singular": "A shipment had its ETA updated recently"
      },
      "shipmentProductsAvailable": {
        "plural": "",
        "singular": "New products added on :foreseaName: - :clientName:"
      },
      "shipmentShared": {
        "plural": "",
        "singular": ":clientOwnerName: shared shipment :foreseaName: with you"
      },
      "shipmentToInvoice": {
        "plural": "",
        "singular": "Shipment to invoice - :foreseaName: - :shipmentName:"
      },
      "unansweredQuoteReminder": {
        "plural": "",
        "singular": "[Important] Quote for a nearby departure unanswered - :foreseaName: - :clientName:"
      },
      "unloadingDone": {
        "plural": "",
        "singular": "Container unloaded - :foreseaName: - :clientName:"
      }
    },
    "unansweredQuoteReminder": {
      "doYouStillNeedOvrsea": "Do you still want Ovrsea to help you on this shipment?",
      "hi": "Hi {contactName}",
      "ovrseaTeam": "The OVRSEA team",
      "quoteDetails": {
        "nextDepartureDirectPath": "Direct in {transitTimeInDays} days",
        "nextDeparturePathViaHarbors": "Via {transitHarbors} in {transitTimeInDays} days",
        "nextDepartureText": "Next departure on {nextDepartureDate}",
        "subtractedCO2EmissionsPercentageLabel": "-{percentage}% CO2 EMISSIONS"
      },
      "seeOnOvrsea": "See on OVRSEA",
      "shipmentNoLongerNecessary": "This shipment is no longer necessary",
      "text": "Cargo of one of your shipment from {origin} to {destination} is soon ready for pick-up but the quote is still unanswered.",
      "title": "One quote for a nearby departure is unanswered",
      "yourFeedback": "Your feedback on our offers will help us organise the shipment in the best way (pick-up, booking...) and improve our future offers."
    },
    "undoCancelShipment": {
      "title": "Shipment reactivated"
    }
  },
  "error": {
    "loadable": {},
    "validityExpiredForProposition": "Warning: the validity date of at least one proposition is expired."
  },
  "errorDisplay": {
    "errorTitle": "An error has occurred. Please contact us with the following code:"
  },
  "etaUpdated": {
    "button": "See on OVRSEA",
    "contentAdvanced": "We are pleased to inform you that planned arrival at {harborName} {locode} has been advanced by {delayUnit}.",
    "contentDelay": "We are sorry to inform you that planned arrival at {harborName} {locode} has been delayed by {delayUnit}.",
    "impactedUnits": "Impacted units",
    "keyProduct": "Key product",
    "newEtaLabel": "New ETA",
    "oldEtaLabel": "Previous ETA",
    "outcome": "Our operational teams are working their best to adapt the rest of the shipment and will keep you informed.",
    "titleAdvanced": "ETA {harborName} advanced to {eta}",
    "titleDelay": "ETA {harborName} postponed to {eta}"
  },
  "etailsAndCargoTab": {
    "CargaisonDetails": {
      "addContainerButton": "Add container",
      "editButton": "Edit"
    }
  },
  "events": {
    "descriptions": {
      "activity": {
        "documentUploadClient": "{documentValue} uploaded",
        "documentUploadOvrsea": "{documentValue} uploaded by Ovrsea"
      },
      "agent_information_shared": "Agent at departure updated",
      "appointment_cancelled": "<strong>{userName}</strong> cancelled the appointment for <strong>{containerNumber}</strong> (<strong>{containerSize}</strong>) on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
      "appointment_marked_as_done": {
        "delivery_fcl": "<strong>{userName}</strong> confirmed that the unloading of the container <strong>{containerNumber}</strong> (<strong>{containerSize}</strong>) has been done on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "delivery_lcl": "<strong>{userName}</strong> confirmed that the delivery of the cargo has been done on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_fcl": "<strong>{userName}</strong> confirmed that the loading of container <strong>{containerNumber}</strong> (<strong>{containerSize}</strong>) has been done on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_lcl": "<strong>{userName}</strong> confirmed that the pickup of the cargo has been done on <strong>{datetime}</strong> at <strong>{locationName}</strong>."
      },
      "appointment_planned": {
        "delivery_fcl": "Unloading of container {containerNumber} ({containerSize}) scheduled on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "delivery_lcl": "Delivery of cargo scheduled on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_fcl": "Loading of a {containerSize} container scheduled on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_lcl": "Pickup of cargo scheduled on <strong>{datetime}</strong> at <strong>{locationName}</strong>."
      },
      "appointment_requested": {
        "delivery_fcl": "<strong>{userName}</strong> requested an appointment for the unloading of container {containerNumber} ({containerSize}) on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "delivery_lcl": "<strong>{userName}</strong> requested an appointment for the delivery of cargo on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_fcl": "<strong>{userName}</strong> requested an appointment for the loading of a {containerSize} container on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_lcl": "<strong>{userName}</strong> requested an appointment for the pickup of cargo on <strong>{datetime}</strong> at <strong>{locationName}</strong>."
      },
      "appointment_rescheduled": {
        "delivery_fcl": "<strong>{userName}</strong> requested an appointment for the unloading of container {containerNumber} ({containerSize}) on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "delivery_lcl": "<strong>{userName}</strong> requested an appointment for the delivery of cargo on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_fcl": "<strong>{userName}</strong> requested an appointment for the loading of a {containerSize} container on <strong>{datetime}</strong> at <strong>{locationName}</strong>.",
        "pickup_lcl": "<strong>{userName}</strong> proposed an alternate schedule for the pickup of cargo on <strong>{datetime}</strong>."
      },
      "arrival_to_pod": "Cargo has arrived on <strong>{arrivalDate}</strong> to <strong>{portOfDestination}</strong>",
      "billing_available": "Your invoice <strong>{billingNumber}</strong> is now available for payment. Thank you in advance for your payment.",
      "billing_closed": "{person} has closed the billing",
      "billing_due": "Your invoice <strong>{billingNumber}</strong> is due. Please proceed to its prompt payment.",
      "billing_owner_assigned": "{person} assigned as Billing",
      "billing_paid": "Your invoice <strong>{billingNumber}</strong> is now paid. Thank you for the payment.",
      "billing_reopened": "{person} has reopened the billing",
      "bl_approved": "Draft BL approved by {person}",
      "bl_approved_activity": "Draft {blType} approved",
      "bl_approved_by": "{person} has approved the {blType} draft",
      "bl_approved_self": "You have approved the {blType} draft",
      "bl_rejected": "Draft BL returned for correction",
      "bl_rejected_by": "{person} has sent the {blType} draft back for modification",
      "bl_rejected_self": "You have sent the {blType} draft back for modification",
      "bl_to_approve": "Draft {blType} to approve",
      "booked": "Booking done",
      "booking_asked": "Booking request sent by {person}",
      "booking_asked_by": "{person} has created a shipment",
      "booking_asked_self": "You have created a shipment",
      "booking_asked_simple": "Booking request sent",
      "booking_informations_sent": "Booking informations sent",
      "booking_informations_sent_by": "{person} has verified shipment information",
      "booking_informations_sent_self": "You have verified shipment information",
      "booking_request_accepted": "Booking request accepted",
      "booking_request_asked": "New booking request",
      "booking_request_declined": "Booking request declined",
      "by": "by",
      "carbon_offset": "You chose to compensate the {CO2Weight} of CO2 emitted by this shipment and we'd like to thank you for it. Cost of {CO2Price} will appear on the shipment invoice.",
      "cargo_picked_up": "Cargo has been picked up on {pickupDate} at {pickupLocation}",
      "cargo_updated": "Cargo update",
      "cargo_updated_by": "{person} has updated cargo",
      "cargo_updated_self": "You have updated cargo",
      "client_billing_informations_updated": "Client billing informations updated",
      "container_deliveries_planned": {
        "header": "Planning of deliveries confirmed",
        "line": "Container {containerNumber} on"
      },
      "container_delivery_planned": "Delivery of container nᵒ {containerNumber} planned on {date}",
      "container_picked_up": "Container {containerNumber} has been picked up on {datetime} at {pickUpLocation}",
      "containers_info_asked": "Container details ready for approval",
      "credit_note_available": "Credit note available",
      "customs_clearance_location_added": "Customs clearance location added",
      "deleted_document_by": "{person} has shared a document, deleted since",
      "deleted_document_self": "You have shared a document, deleted since",
      "delivery_container_planned_updated_advance": "Delivery of container {containerNumber} advanced to",
      "delivery_container_planned_updated_delay": "Delivery of container {containerNumber} postponed to",
      "delivery_location_added": "Delivery location added",
      "delivery_location_updated": "Delivery location information updated",
      "delivery_planned": "Delivery planned on {date}",
      "delivery_planned_updated_advance": "Delivery date advanced to",
      "delivery_planned_updated_delay": "Delivery date postponed to",
      "delivery_target_date_updated": "Desired delivery date modified",
      "departure_from_pol": "Cargo has departed on <strong>{departureDate}</strong> from <strong>{portOfLoading}</strong> with <strong>{freightShipownerName}</strong>",
      "departure_from_pol_on_flight": "Cargo has departed on <strong>{departureDate}</strong> from <strong>{portOfLoading}</strong> with <strong>{freightShipownerName}</strong> on flight <strong>{flightNumber}</strong>",
      "departure_from_pol_on_vessel": "Cargo has departed on <strong>{departureDate}</strong> from <strong>{portOfLoading}</strong> with <strong>{freightShipownerName}</strong> on <strong>{vesselName}</strong>",
      "document_deleted": "{documentValue} deleted",
      "document_deleted_bis": "{documentValue} deleted",
      "document_deleted_simple": "Document deleted",
      "document_uploaded": "New document available",
      "document_uploaded_by": "{person} has shared a document",
      "document_uploaded_self": "You have shared a document",
      "documents_asked": {
        "plural": "{person} has requested the following documents",
        "singular": "{person} has requested the following document"
      },
      "email_received": "Email received from {name}",
      "email_sent": "Email sent to {name}",
      "eta_updated_advance": "ETA {harbor} advanced to",
      "eta_updated_day": "day",
      "eta_updated_delay": "ETA {harbor} postponed to",
      "eta_updated_hour": "hour",
      "etd_updated_advance": "ETD {harbor} advanced to",
      "etd_updated_delay": "ETD {harbor} postponed to",
      "file_cancelled": "{userName} ({companyName}) has cancelled this file",
      "has_shared_propositions": "{person} has shared new proposals",
      "has_updated_propositions": "{person} has updated proposals",
      "many_deleted_documents": "{documentsValues} deleted",
      "many_deleted_documents_by": "{person} has shared {docLength} {docLength, plural, one {document} other {documents}}, deleted since",
      "many_deleted_documents_self": "You have shared {docLength} {docLength, plural, one {document} other {documents}}, deleted since",
      "many_documents_uploaded": "New documents available",
      "many_documents_uploaded_by": "{person} has shared {docLength} {docLength, plural, one {document} other {documents}}",
      "many_documents_uploaded_self": "You have shared {docLength} {docLength, plural, one {document} other {documents}}",
      "manyDocumentsUploaded": "{docLength} {docLength, plural, one {document uploaded} other {documents uploaded}}",
      "manyDocumentsUploadedNotification": "Documents uploaded",
      "manyDocumentsUploadedPlural": {
        "bis": "{nbDocs} documents uploaded"
      },
      "manyDocumentsUploadedSingular": {
        "bis": "\t {nbDocs} document uploaded"
      },
      "multiple_deliveries_planned_updated": {
        "header": "Planning of deliveries updated",
        "line": "Container {containerNumber} on"
      },
      "new_ai_commercial_invoice_extract": "New products identified from commercial invoice",
      "new_message": "New message",
      "new_propositions_shared": "New proposals have been shared",
      "operations_owner_assigned": "{person} assigned as Ops",
      "pickup_location_added": "Supplier contact added",
      "pickup_location_updated": "Pickup location information updated",
      "pickup_supplier_location_updated": "Supplier contact updated",
      "pickup_target_date_updated": "Cargo ready date modified",
      "proposition_declined": "Proposition declined",
      "proposition_deleted": "Quote removed",
      "propositions_received": "New proposition available",
      "propositions_updated": "Proposition updated",
      "propositions_updated_multiple": "Proposals have been updated",
      "purchase_orders_updated": "PO final quantities have been updated",
      "quotation_accepted": {
        "ativity": "Quote accepted, awaiting booking",
        "default_message": "Quote accepted",
        "title": "Quote accepted by {person}"
      },
      "quotation_accepted_by": "{person} has accepted the quote",
      "quotation_accepted_self": "You have accepted the quote",
      "quotation_archived": "Quote archived",
      "quotation_asked": "Quote request sent by {person}",
      "quotation_asked_by": "{person} has requested a quote",
      "quotation_asked_self": "You have requested a quote",
      "quotation_asked_simple": "Quote request sent",
      "quotation_declined": "Quote declined",
      "quotation_declined_by": "{person} has declined the quote",
      "quotation_declined_self": "You have declined the quote",
      "quotation_purchase_ready": "Quote passed as purchase ready by {person}",
      "quotation_purchase_ready_simple": "Quote passed as purchase ready",
      "quotation_status_to_not_answered": "Quote passed as 'not answered' by {person}",
      "quotation_status_to_propositions_sent": "Sending propositions ignored by {person}",
      "quotation_to_be_requoted": "Quote to be requoted",
      "quotation_unarchived": "Quote reactivated",
      "quotation_undo_decline": "Quote reactivated",
      "quotation_waiting_for_external": "Quote stand-by by {person}",
      "request_considered": "Quote considered by {person}",
      "sales_owner_assigned": "{person} assigned as Sales",
      "shipment_arrived_location": "at {locationName}",
      "shipment_arrived_text_activity": "Shipment delivered on",
      "shipment_arrived_text_notification": "Delivered on",
      "shipment_awaiting_booking": "Booking request to {shipowner} done by {person}",
      "shipment_booked": "Booking done",
      "shipment_booked_activity_quotation": "Booking done - Shipment {shipmentValue} created",
      "shipment_cancelled": "Shipment cancelled",
      "shipment_created_by": "{person} has launched this shipment",
      "shipment_created_self": "You have launched this shipment",
      "shipment_finished": "Shipment finished",
      "shipment_litigious": "{person} marked the shipment as litigious",
      "shipment_litigious_removed": "{person} marked the shipment as not litigious",
      "shipment_pricing_request_considered": "Quote considered by {person}",
      "shipment_purchase_ready": "Shipment purchase ready by {person}",
      "shipment_rating_dismissed": "{person} has not given feedback for this shipment",
      "shipment_rating_negative": "{person} has expressed dissatisfaction on OVRSEA's service for this shipment",
      "shipment_rating_positive": "{person} has expressed satisfaction on OVRSEA's service for this shipment",
      "shipment_status_to_propositions_sent": "Sending propositions ignored by {person}",
      "shipment_to_be_priced": "Shipment to be requoted",
      "shipment_uncancelled": "Shipment reactivated",
      "submit_vgm": "VGM to submit",
      "submitVgm": {
        "container": "VGM of container {containerNumber} to submit"
      },
      "unanswered_quote_reminder_email_sent": "Unanswered quote reminder sent",
      "undo_decline": "Undo declined",
      "vgm_edit_request": "VGM edit request",
      "vgm_edit_request_fcl": "Edit requested for the VGM of the container {containerValue} ",
      "vgm_submitted": "VGM submitted",
      "vgm_submitted_fcl": "VGM of the container {containerNumber} submitted"
    }
  },
  "feed": {
    "entries": {
      "palletization_done": "{userName} has indicated that cargo has been ready for pickup since {crd}",
      "task_done": {
        "order_preparation_cancelled": "{userName} has cancelled this order preparation request",
        "order_preparation_requested": "{userName} has requested the preparation of this order",
        "palletization_done": "{userName} has indicated that cargo has been ready for pickup since {crd}"
      }
    },
    "subscribe": "Subscribe",
    "unsubscribe": "Unsubscribe"
  },
  "feedbackMessages": {
    "accept_booking_request": "Booking request validated",
    "access_requested": "Access to the platform correctly submitted",
    "accurate_shipment_products_table": "Thank you for giving us feedback ! It will help us improve the algorithm",
    "add_load": "Load(s) added",
    "appointment_cancelled_success": "Appointment cancelled",
    "archive_network": "Company archived",
    "archive_proposition_success": "Proposition archived",
    "archive_quotations": "Quote archived",
    "archive_shipments": "Shipment archived",
    "ask_containers_info_successful": "Request on containers info correctly transmitted",
    "assign_operations_manager": "Operations manager assigned",
    "assign_operations_owner_manager": "Operations owner assigned",
    "assign_pricing_manager": "Pricing manager assigned",
    "assign_pricing_owner_manager": "Pricing owner assigned",
    "assign_sales_manager": "Sales manager assigned",
    "assign_sales_owner_manager": "Sales owner assigned",
    "block_user": "User blocked",
    "book_quotation": "Quote booked",
    "book_shipment": "Shipment booked",
    "booking_updated": "Booking updated",
    "cancel_order_preparation": "Order preparation request cancelled",
    "cancel_shipment": "Shipment cancelled",
    "change_order_owner": "Owner changed",
    "client_allproducts_reporting_error": "We encountered an error ❌",
    "client_container_reporting_error": "We encountered an error ❌",
    "clipboard_copied": "Copied!",
    "co2_dashboard_reporting_error": "We encountered an error ❌",
    "company_archived": "Company archived",
    "company_created": "Company added",
    "confirm_user": "User confirmed",
    "consider_quotation": "Quote taken into account",
    "consignee_updated": "Consignee Updated",
    "contact_location_added": "Contact location added successfully!",
    "contact_updated": "Contact updated",
    "contacts_created": "Contact created",
    "container_number_not_valid": "This container number is not valid",
    "container_updated": "Container updated",
    "containers_updated": "Containers updated",
    "create_booking_request": "Booking request created",
    "create_client": "Client created",
    "create_everoad_offer": "Offer created on Everoad",
    "create_final_check": "Final check created",
    "create_note": "Note created",
    "create_proposition": "Proposition created",
    "create_sales_proposition": "Sales proposition created",
    "create_shipment_incident": "Incident created successfully",
    "create_shipowner": "Shipowner created",
    "create_tracking_record": "Tracking created",
    "create_vgm": "VGM created",
    "customs_tariff_numbers_submitted": "Customs codes added!",
    "dbx_add_tag": "Tad added",
    "dbx_archive_folder": "Dropbox folder archived",
    "dbx_billing_upload": "Billing uploaded on Dropbox",
    "dbx_delete_file": "File(s) deleted",
    "dbx_err_billing_upload": "Error while uploading billing on Dropbox",
    "dbx_err_create_folder": "Error while creating folder",
    "dbx_err_delete_file": "Error while deleting files",
    "dbx_err_download_file": "Error while downloading file",
    "dbx_err_list_folder": "Error while creating folder",
    "dbx_err_list_team_namespaces": "Erorr while searching team spaces",
    "dbx_err_preview": "Error while loading URL",
    "dbx_err_search_file": "Error while searching files",
    "dbx_err_thumbnail": "Error while loading thumbnails",
    "dbx_err_transfer_file": "Error while transferring files",
    "dbx_err_upload_files": "Error while uploading documents",
    "dbx_file_transfer_atlas_to_dbx": "File(s) transferred to Dropbox",
    "dbx_file_transfer_dbx_to_atlas": "File(s) transferred to Atlas",
    "dbx_folder_created": "Folder created",
    "dbx_remove_tag": "Tag deleted",
    "dbx_update_tag": "Tag updated",
    "dbx_upload_files": "Documents uploaded",
    "deadline_postponed": "Deadline postponed",
    "decline_booking_request": "Booking request declined",
    "decline_proposition": "Proposition declined",
    "decline_quotation": "Quote declined",
    "default_error": "Error",
    "default_success": "Success",
    "delete_activity": "Activity deleted",
    "delete_container": "Container deleted",
    "delete_documents": "Document(s) deleted",
    "delete_load": "Cargo deleted",
    "delete_location": "Location deleted",
    "delete_message": "Message deleted",
    "delete_quotation": "Quote deleted",
    "delete_shared_client": "Shared client deleted",
    "document_deleted": "Document deleted",
    "document_uploaded": "Document uploaded",
    "documents_uploaded": "Documents uploaded",
    "download_propositions_success": "Your quotes were downloaded successfully!",
    "edit_client": "Information updated",
    "edit_company": "Company updated",
    "edit_container": "Container updated",
    "edit_document_attributes": "Documents updated",
    "edit_documents_category": "Categories updated",
    "edit_load": "Cargo updated",
    "edit_note": "Note updated",
    "edit_product_success": "Product successfully edited",
    "edit_shared_client": "Shared clients updated",
    "edit_user": "User updated",
    "edit_user_fail": "Error while updating user",
    "edit_vgm": "VGM updated",
    "elane_success": "Data sent",
    "email_already_taken": "Email already used",
    "error": "An error occured, please contact support",
    "error_accept_booking_request": "An error occured",
    "error_access_requested": "An error occured while requesting access to the platform",
    "error_add_load": "Error while adding cargo",
    "error_appointment_cancelled": "Error while cancelling appointment",
    "error_archive_network": "Error while archiving company",
    "error_archive_quotations": "Error while archiving shipment",
    "error_archive_shipments": "Shipment archived",
    "error_assign_operations_manager": "Error while assigning",
    "error_assign_operations_owner_manager": "Error while assigning",
    "error_assign_pricing_manager": "Error while assigning",
    "error_assign_pricing_owner_manager": "Error while assigning",
    "error_assign_sales_manager": "Error while assigning",
    "error_assign_sales_owner_manager": "Error while assigning",
    "error_billing_already_sent": "Invoice was already sent, you cannot modify its number",
    "error_billing_number_already_used": "This invoice number is already used",
    "error_billing_update": "An error occurred",
    "error_book_quotation": "Error while booking quote",
    "error_book_shipment": "Error while booking shipment",
    "error_booking_updated": "Error while updating booking",
    "error_cancel_order_preparation": "Error while cancelling order preparation request",
    "error_cancel_shipment": "Error while cancelling shipment",
    "error_change_order_owner": "Error while changing owner",
    "error_consider_quotation": "Error while taking quote into account",
    "error_contact_updated": "Error while updating contact",
    "error_contacts_created": "Error while creating contact",
    "error_contracted_traffics_performance_reporting": "An error occured ❌",
    "error_create_booking_request": "An error occured",
    "error_create_client": "Error while adding client",
    "error_create_everoad_offer": "Offer could not be created on Everoad",
    "error_create_final_check": "An error occured",
    "error_create_note": "Error while creating route",
    "error_create_proposition": "An error occured",
    "error_create_sales_proposition": "Error while creating Sales Proposition",
    "error_create_shipment_incident": "Error while creating incident",
    "error_create_tracking_record": "Error while creating tracking",
    "error_create_vgm": "Error while creating VGM",
    "error_dbx_add_tag": "Error while adding tag",
    "error_dbx_archive_folder": "Error while archiving Dropbox file",
    "error_dbx_remove_tag": "Error while deleting tag",
    "error_dbx_update_tag": "Error while updating tag",
    "error_decline_booking_request": "An error occured",
    "error_decline_quotation": "An error occured",
    "error_delete_activity": "Error while deleting activity",
    "error_delete_container": "Error while deleting container",
    "error_delete_documents": "Error while deleting document(s)",
    "error_delete_load": "Error while deleting cargo",
    "error_delete_message": "Error while deleting message",
    "error_delete_quotation": "Error while deleting quote",
    "error_delete_shared_client": "Error while deleting shared client",
    "error_document_uploaded": "Error while uploading document",
    "error_documents_uploaded": "Documents could not be loaded, please retry",
    "error_edit_category_total_price": "No price with variable margin found in this category. Please edit each line to modify the price of this category.",
    "error_edit_client": "Error while updating information",
    "error_edit_container": "Error while updating container",
    "error_edit_document_attributes": "Error while updating documents",
    "error_edit_documents_category": "Error while updating categories",
    "error_edit_load": "Error while updating cargo",
    "error_edit_note": "Error while updating note",
    "error_edit_shared_client": "Error while updating shared clients",
    "error_edit_vgm": "Error while updating VGM",
    "error_exchange_rates_updated": "Error while updating exchange rates",
    "error_finish_shipment": "Error while updating shipment status",
    "error_follow_quotations": "Error while subscribing to quote",
    "error_hide_dashboard_quotations": "Error while hiding quote from dashboard",
    "error_inProgress_shipment": "Error while updating shipment status",
    "error_invitation_accepted": "Error when creating your account",
    "error_kronos_freight_method": "Freight type is missing",
    "error_kronos_lcl_harborAddress": "Information is missing",
    "error_kronos_loads_containers": "Please fill in Containers or Loads",
    "error_kronos_ocean_load_type": "Load type is missing",
    "error_light_quotation": "An error occured.",
    "error_location_created": "Error while creating location",
    "error_location_created_from_show_network": "Error while creating location",
    "error_location_not_found": "Location not found",
    "error_location_update": "Error while updating route",
    "error_mark_billing_as_litigious": "Error while marking invoice as litigious",
    "error_message_sent": "Error while sending message",
    "error_message_transferred": "Error while transferring your message",
    "error_new_invitation": "An error occurred while creating the invite",
    "error_new_quotation": "Error while creating quote request",
    "error_new_shipment": "Error while creating shipment",
    "error_pass_shipment_as_billing_done": "An error occured",
    "error_pass_shipment_as_billing_open": "An error occured",
    "error_proposition_accepted": "Error while accepting quote",
    "error_proposition_accepted_shipment_created": "Error while accepting quote",
    "error_purchase_orders_uploaded": "An error occured",
    "error_quotation_updated": "Error while updating proposition",
    "error_rate_shipowner": "There was an issue with your ratings. Please try again",
    "error_ratesToDb_arraysConsistency": "Rates sizes, thresholds and Container Types are not consistent",
    "error_ratesToDb_category": "Rate category incorrect",
    "error_ratesToDb_containerTypes": "Container types incorrect",
    "error_ratesToDb_containerTypesRateUnit": "A rate with container types must have TC for unit",
    "error_ratesToDb_currency": "Currency is incorrect",
    "error_ratesToDb_freightMode": "Freight mode is incorrect",
    "error_ratesToDb_harbor_countryCode": "Country code is missing",
    "error_ratesToDb_harbor_harborCode": "Port code is missing",
    "error_ratesToDb_max": "Max is incorrect",
    "error_ratesToDb_min": "Min is incorrect",
    "error_ratesToDb_name": "Name or Display Name incorrect",
    "error_ratesToDb_no_rate_no_rates": "Neither rate, nor rates",
    "error_ratesToDb_rate": "Rate incorrect",
    "error_ratesToDb_rateApplicability": "Rate applicability incorrect",
    "error_ratesToDb_rateUnit": "Unit incorrect",
    "error_ratesToDb_rates": "Rates incorrect",
    "error_ratesToDb_ratesUnsorted": "Rates not sorted",
    "error_ratesToDb_shipownerName": "Vendor incorrect",
    "error_ratesToDb_success": "Rates were not added.",
    "error_ratesToDb_thresholds": "Thresholds incorrect",
    "error_ratesToDb_thresholdsUnsorted": "Thresholds not sorted",
    "error_ratesToDb_transitTime": "Transit Time incorrect",
    "error_ratesToDb_validity": "Validity incorrect",
    "error_ratesToDb_validity_format": "Validity format incorrect",
    "error_rename_document": "Error while updating name",
    "error_reply_sent": "Error while sending reply",
    "error_schedule_missing_harbor_locode": "Habor locode was not found",
    "error_share_order": "Error while sharing order",
    "error_shipment_rate_field": "Error while updating field",
    "error_shipment_rate_field_incorrect": "Incorrect field",
    "error_shipment_updated": "Error while updating shipment",
    "error_subscribe_users_to_order": "Error",
    "error_subscribed_to_newsletter": "An error occured",
    "error_take_message_into_account": "An error occured",
    "error_unarchive_network": "Error whil restoring company",
    "error_unarchive_quotations": "Error while unarchiving quote",
    "error_unarchive_shipments": "Shipment restored",
    "error_uncancel_shipment": "Error while reactivating shipment",
    "error_undo_decline_quotation": "An error occured",
    "error_unfollow_quotations": "Error while unsubscribing to quote",
    "error_unhide_dashboard_quotations": "Error while hiding quote request from dashboad",
    "error_unsubscribe_user_from_order": "Error",
    "error_update_CO2_offset_status": "Error while updating CO2 !",
    "error_update_activity": "Error while updating activity",
    "error_update_booking_informations": "An error occured",
    "error_update_cargo": "Error while updating cargo",
    "error_update_final_check": "An error occured",
    "error_update_flow": "Error while updating flow",
    "error_update_message": "Error while updating message",
    "error_update_order_client_name": "Error while updating",
    "error_update_proposition_rate": "Error while updating proposition rate",
    "error_update_purchase_order": "Error while updating PO",
    "error_update_quotation": "Error while updating quote request",
    "error_update_route": "Error while updating route",
    "error_update_shipment": "Error while updating shipment",
    "error_update_shipment_incident": "An error occured while updating the incident",
    "error_update_shipowner_contact": "Error while updating contact",
    "error_update_tracking_record": "Error while updating tracking",
    "error_upload_bl_validation": "Error while uploading BL",
    "error_user_not_found": "Cannot find user",
    "exchange_rates_updated": "Exchange rates updated",
    "finish_shipment": "Shipment closed",
    "follow_quotations": "You are now following this quote request",
    "follow_shipments": "You are now following this shipment",
    "freight_forwarder_assigned": "Freight forwarder successfully assigned",
    "freight_forwarder_proposition_created": "Your proposition has been submitted",
    "freight_forwarders_added": "Freight forwarders added to the quote request",
    "hide_dashboard_quotations": "Quote request hidden from dashboard",
    "hide_dashboard_shipments": "Shipment hidden from dashboard",
    "inProgress_shipment": "Shipment in progress",
    "inaccurate_shipment_products_table": "Thank you for reporting an issue , our technical team will investigate it",
    "insert_shipment_rates": "Rates added",
    "invitation_accepted": "Your account has been created!",
    "loads_edited": "Cargo updated",
    "location_archived": "Location archived",
    "location_created": "Location created",
    "location_created_from_show_network": "Location created",
    "location_deleted": "Location deleted",
    "location_update": "Location updated",
    "mark_billing_as_litigious": "Invoice marked as litigious",
    "message_sent": "Message sent",
    "message_transferred": "Message forwarded",
    "missing_fields": "Some fields aren't correctly filled",
    "new_invitation": "Invite sent successfully!",
    "new_quotation": "Quote request created",
    "new_quotation_success": "Your quote request was successfully sent to our teams!",
    "new_shipment": "Shipment created",
    "no_container_carrier": "This carrier does not handle tracking by container number",
    "pass_shipment_as_billing_done": "Billing closed",
    "pass_shipment_as_billing_open": "Billing reopened",
    "post_booking_informations": "Booking information was udpated",
    "proposition_accepted": "Quote accepted",
    "proposition_accepted_shipment_created": "Quote accepted, shipment created",
    "purchase_orders_updated": "Purchase Orders updated",
    "purchase_orders_uploaded": "Purchase Orders uploaded",
    "quotation_updated": "Quote updated",
    "rate_shipowner": "We successfully saved your ratings! ",
    "ratesToDb_success": "Rates were successfully added.",
    "rename_document": "Document renamed",
    "reply_sent": "Reply sent",
    "request_connection_form_success": "Connection request was sucessfully submitted",
    "set_proposition_as_purchase_ready_success": "Proposition set as purchase ready",
    "share_order": "Order shared",
    "shipment_booked": "Shipment has been booked!",
    "shipment_rated": "Thank you for your feedback.",
    "shipment_rating_task_ignored": "Feedback request(s) dismissed",
    "shipment_updated": "Shipment updated",
    "shipper_consignee_updated": "Shipper and consignee updated",
    "shipper_updated": "Shipper Updated",
    "subscribe_users_to_order": "Subscription added",
    "subscribed_to_newsletter": "You subscribed to the newsletter",
    "take_message_into_account": "Message taken into account",
    "test_message": "Test feedback message (EN)",
    "tracking_link_copied": "Tracking link copied to your clipboard",
    "traffic_request_success": "Traffic request taken into account",
    "turned_off_notifications_for_product": "Notifications have been turned off for this product",
    "turned_on_notifications_for_product": "Notifications have been turned on for this product",
    "unarchive_network": "Company restored",
    "unarchive_proposition_success": "Proposition unarchived",
    "unarchive_quotations": "Quote request restored",
    "unarchive_shipments": "Shipment restored",
    "uncancel_shipment": "Shipment reactivated",
    "undo_decline_quotation": "Quote reactivated",
    "unfollow_quotations": "You are no longer following this quote request",
    "unfollow_shipments": "You are no longer following this shipment",
    "unhide_dashboard_quotations": "Quote now shows again on dashboard",
    "unhide_dashboard_shipments": "Shiment now shows again on dashboard",
    "unsubscribe_user_from_order": "Subscription removed",
    "update_CO2_offset_status": "CO2 status updated successfully !",
    "update_activity": "Activity updated",
    "update_booking_informations": "Booking information was sent successfully",
    "update_cargo": "Cargo updated",
    "update_container_delivery_dates": "Containers updated",
    "update_filter": "Your filter settings have been updated",
    "update_final_check": "Final Check updated",
    "update_flow": "Flow updated",
    "update_message": "Message updated",
    "update_order_client_name": "Name updated",
    "update_password_fail": "Error while updating password",
    "update_password_success": "Password updated",
    "update_proposition_rate": "Update successful!",
    "update_proposition_success": "Proposal updated",
    "update_purchase_order": "Purchase Order updated",
    "update_quotation": "Quote updated",
    "update_route": "Route updated",
    "update_shipment": "Shipment updated",
    "update_shipment_incident": "Incident successfully modified!",
    "update_shipment_owner": "Client owner updated successfully !",
    "update_shipment_rate_field": "Field updated",
    "update_shipowner_contact": "Contact updated",
    "update_tracking_record": "Tracking updated",
    "upload_bl_validation": "BL uploaded",
    "upload_file_fail": "Oops ! The upload failed, please try again or contact us directly",
    "upload_file_validation": "Your file has been successfully transmitted to our teams !",
    "wrong_purchase_order_file_extension": "Only CSV files can be uploaded"
  },
  "fifth": {
    "cargaisonCard": {
      "title": {}
    },
    "cargoDisplay": {
      "loadingRef": "Loading reference",
      "positPrefix": "Pick-up date"
    }
  },
  "fifthRenderCargaisonVracTotalWeight": "Total weight",
  "fifthRenderCargaisonVracVolumeWeight": "Total volume",
  "files-upload-modal": {
    "file-list": {
      "type-label": "Document type"
    }
  },
  "flow": {
    "document": {
      "title": "Flow"
    }
  },
  "flows": {
    "document": {
      "title": "Flows"
    },
    "table": {
      "delivery": "Destination",
      "name": "Name",
      "pickup": "Origin",
      "selection": {}
    }
  },
  "forms": {
    "booking": {
      "addContact": {
        "choosePhoneOrEmail": "You must provide either phone or email of the contact (or both)",
        "contactNameRequired": "Contact name is required",
        "emailInvalid": "Email is invalid",
        "fromCountriesRequired": "At least one country of loading is required",
        "phoneInvalid": "Phone number is invalid: it must start with '00' (followed by country indicator) and have at least 6 digits",
        "provideValidEmail": "Please provide a valid email",
        "shipownerRequired": "Shipowner is required",
        "toCountriesRequired": "At least one country of discharge is required"
      },
      "addLocation": {
        "addressRequired": "Supplier address is required",
        "cityRequired": "City is required",
        "companyRequired": "Company name is required",
        "countryRequired": "Country is required",
        "postalCodeRequired": "Postal code is required",
        "stateRequired": "State is required"
      }
    },
    "common": {
      "cantBeBlank": "Can't be blank",
      "incorrectValue": "This value is incorrect",
      "invalidEmail": "Email address is invalid",
      "passwordNotMatch": "Passwords do not match",
      "requiredField": "Field required"
    },
    "editClient": {
      "errors": {}
    },
    "editUser": {
      "title": "Edit user"
    },
    "loads": {
      "isRequired": "At least one pallet or package required"
    },
    "newClientInvitation": {
      "errors": {
        "language": "Choose a language.",
        "salesforceAccountId": "Invalid Salesforce Account ID",
        "salesforceId": "Invalid Salesforce ID "
      },
      "labels": {
        "companyName": "Company name",
        "email": "Email address",
        "firstName": "First name",
        "industry": "Industry",
        "language": "Language",
        "lastName": "Family name",
        "opsManager": "Operations Manager",
        "phone": "Phone number",
        "salesManager": "Sales Manager",
        "salesforceId": "Salesforce Account ID",
        "siretNumber": "Identification number",
        "siretNumberSecondary": "= SIRET for 🇫🇷, CIF for 🇪🇸, DUF for 🇺🇸"
      },
      "placeholders": {
        "companyName": "Company name",
        "email": "Email address",
        "firstName": "First name",
        "industry": "Industry",
        "lastName": "Family name",
        "phone": "Phone number",
        "salesforceId": "Salesforce Account ID",
        "siretNumber": "SIRET/CIF/DUN"
      }
    },
    "newUser": {
      "labels": {
        "email": "Email",
        "firstName": "First name",
        "language": "Language",
        "lastName": "Last name",
        "phone": "Phone number",
        "roles": {
          "admin": "Can modify user rights and company settings.",
          "manager": "Can modify user rights.",
          "member": "Can only modify their files and visualize others.",
          "title": "Role",
          "viewer": "Can only visualize"
        }
      },
      "submitSuccessButton": "Close",
      "successView": {
        "textOne": " has been added",
        "textOneBis": " as ",
        "textTwo": "A signup link has been sent to their email address."
      },
      "title": "Add user on OVRSEA",
      "titleSubmitted": "Added successfully"
    },
    "shipowner": {
      "AgentShipownerFields": {
        "pod": "POD"
      },
      "AirlineShipownerFields": {
        "IATAcode": "IATA code",
        "registered": "Registered"
      },
      "SharedFields": {
        "IsOeaField": {
          "title": "OEA"
        },
        "createShipowner": "Create a shipowner",
        "exportPaymentDelayDays": "Export payment delay (days)",
        "group": "Group",
        "importPaymentDelayDays": "Import payment delay (days)",
        "lclOrFcl": "LCL or FCL",
        "notes": "Notes",
        "offices": "Offices",
        "paymentDelayDays": "Payment delay (days)",
        "select": "Select",
        "submitCreateShipowner": "Create the shipowner",
        "submitUpdateShipowner": "Validate the changes",
        "updateShipowner": "Edit a shipowner"
      },
      "ShipownersCommonFields": {
        "address": "Principal address",
        "city": "City",
        "email": "Email",
        "name": "Name",
        "phone": "Phone",
        "postalCode": "Postal code",
        "shipownerType": "Shipowner type"
      }
    },
    "validate": {
      "hasMaximumCharactersAmount": "Must contain {amount} characters maximum",
      "isAlphaNumeric": "Must be alphanumeric",
      "isLoadReference": "Must contain letters and numbers only",
      "isNotNegative": "Must be greater than or equal to 0",
      "isNumber": "Must be a number",
      "isPositive": "Must be greater than 0",
      "isRequired": "Field required"
    }
  },
  "frontAuth": {
    "document": {
      "title": "Front authentication"
    }
  },
  "harborsSettings": {
    "document": {
      "title": "Ports Airports"
    }
  },
  "header": {
    "employeesPhoneNumber": {
      "backupSalesOwner": "Substitute sales manager",
      "noDefined": "Not specified",
      "opsOwner": "Operations manager",
      "outOfOffice": "Out of office 🌴",
      "salesOwner": "Sales manager"
    }
  },
  "hermes": {
    "dashboard": {
      "PendingActionCard": {
        "bl": "BL",
        "bl_to_approve": "Validate BL draft",
        "booking_supplier_contact": "Provide information for supplier",
        "bookingRequest": "Confirm request",
        "dgd": "DGD",
        "invoice": "Commercial Invoice",
        "msds": "MSDS",
        "packingList": "Packing list",
        "pickup_client_contact": "Provide information for pick up location",
        "submit_vgm": "Check container info",
        "uploadDocuments": "Upload documents"
      },
      "ShipmentRatingCard": {
        "ignore": "Ignore",
        "ignore_all": "Ignore all"
      },
      "WeeklyCalendar": {
        "arrivals": "Arrivals",
        "departures": "Departures",
        "pickups": "Pickups"
      },
      "billing": {
        "DashboardBillingCard": {
          "billed": "Billed",
          "finishedOn": "Finished on",
          "finishedSince": "Finished since",
          "inProgress": "In progress"
        },
        "status": {
          "since": {
            "bis": "since {daysDiff} days"
          }
        }
      }
    },
    "header": {
      "following-filter": {
        "clear": "Reset filter",
        "label": "Display folders from",
        "myselfOnly": "My files"
      },
      "logisticAgentFilters": {
        "allClients": "All clients",
        "allModes": "All modes",
        "clients": "Clients",
        "freightModes": "Freight modes",
        "hideList": "Hide list",
        "popoverBody": "Define the shipments and quote requests you will see on your dashboard and the different tabs.",
        "popoverTitle": "Filter OneChain on...",
        "showList": "Show list",
        "someClients": "Some clients",
        "someModes": "Some modes"
      }
    }
  },
  "inspectVgmModal": {
    "acknowledgedChangeRequest": "Your change request is being treated.",
    "sendChangeRequest": "Send change request",
    "submittedByDatePrefix": "on the",
    "vgmSubmittedBy": "Submitted by"
  },
  "invoiceDueInFiveDays": {
    "billingLabel": "OVRSEA invoices",
    "firstTextSingular": "Please find attached invoice. Thank you in advance for its prompt payment before incoming due date. ",
    "greetingText": "Dear Sir or Madam,",
    "modalityLabel": "Payment mode",
    "modalityText": "Payment by wire transfer before {dueDate}",
    "secondTextSingular": "Should there be any question regarding this invoice, please contact us on app.ovrsea.com or at billing@ovrsea.com.",
    "titleSingular": "Invoice will be due in 5 days."
  },
  "invoices": {
    "due": {
      "fifthText": "Should there be any question, please send an email to ",
      "firsText": {},
      "fourthText": "If you have recently paid the due amount, please ignore this email.",
      "secondText": {},
      "thirdText": {}
    },
    "status": {},
    "summary": {
      "invoicesDueTotalPriceText": "Total amount of due invoices : {totalInvoice}.",
      "invoicesLitigiousText": "Total amount of litigious invoices : {totalInvoice}",
      "tableHeader": {
        "invoiceTotal": "Total invoice Incl."
      },
      "thirdText": "Should there be any question, please send us an email to"
    }
  },
  "isAutomatedQuotationEnabled": {
    "fullscreenLoading": "We are searching amongst all routes and carriers available for your quote"
  },
  "layout": {
    "breadcrumb": {
      "companies": {},
      "quotation": "Quotes",
      "quotationBis": "Quotes"
    },
    "error": {
      "notFound": "This shipment was not found"
    },
    "errorField": "An error occurred. Please contact with the following code :",
    "normal": {
      "button": {
        "connect": {
          "login": "Login"
        }
      },
      "header": {
        "sem": {
          "contactSection": {
            "emergency": "In case of emergency, you may also call your Ops and Sales account managers during office hours.",
            "inquiry": "If you have any inquiry, please write us on the page of the concerned quote or shipment.",
            "teamResponse": "Our teams will provide a swift reply!"
          },
          "disconnect": "Log out",
          "invoiceAndUser": "Account settings",
          "myContacts": "Dedicated contacts",
          "myProfile": "My profile",
          "notifications": "Notification settings"
        }
      }
    }
  },
  "links": {
    "modification": "Modification request",
    "seeAttachment": "See attached file",
    "show": "See",
    "showLocation": "See location",
    "upload": "Upload"
  },
  "loadMessages": {
    "dimensions": {
      "package": "{packageCount, plural, one {Package} other {Packages}}",
      "pallet": "{palletCount, plural, one {Pallet} other {Pallets}}",
      "special": "Special",
      "totalVolume": "Total volume",
      "totalWeight": "Total weight",
      "volume": "Volume",
      "weight": "Weight"
    },
    "loadType": {},
    "properties": {
      "hazardous": "Hazardous",
      "lithium": "Lithium",
      "magnetic": "Magnetic",
      "refrigerated": "Refrigerated",
      "stackable": "Stackable"
    }
  },
  "loads-modal": {
    "title": "Cargo details for this quote"
  },
  "locations": {
    "ownerClientLabel": "Owned by you",
    "sharedClientLabel": "Shared to you",
    "table": {
      "cell": {
        "actions": {
          "share": "Share location"
        },
        "ownerSelf": "You",
        "visibility": {
          "public": "Public",
          "tooltip": "Shared to all of your connections"
        }
      },
      "header": {
        "actions": "Actions",
        "address": "Address",
        "contacts": "Contacts",
        "name": "Name",
        "owner": "Owner",
        "visibility": "Access"
      },
      "selection": {
        "all": "All locations",
        "connections": "Connections' locations",
        "currentClient": "Your locations"
      },
      "zeroResult": {
        "location": "No locations yet."
      }
    }
  },
  "miscellaneous": {
    "AreYouSure": "Are you sure?",
    "andOr": "And / Or",
    "archive": "Archive",
    "button": {
      "add": "Add",
      "back": "Back",
      "cancel": "Cancel",
      "change": "Change",
      "charge": "Upload",
      "close": "Close",
      "confirm": "Confirm",
      "continue": "Continue",
      "copy": "Copy",
      "create": "Create",
      "decline": "Decline",
      "delete": "Delete",
      "download": "Download",
      "duplicate": "Duplicate",
      "hasCopied": "Copied!",
      "ignore": "Ignore",
      "modify": "Edit",
      "next": "Next",
      "other": "Other",
      "previous": "Previous",
      "refresh": "Refresh",
      "remove": "Remove",
      "reply": "Reply",
      "reset": "Reset",
      "send": "Send",
      "undoDelete": "Undo delete",
      "unzoom": "Zoom out",
      "valid": "Validate",
      "zoom": "Zoom In"
    },
    "calendar": {
      "nextMonth": "Next month",
      "previousMonth": "Previous month"
    },
    "company": {
      "VAT": "TVA Number",
      "contactEmail": "Contact email",
      "contactName": "Contact name",
      "contactPhone": "Contact phone",
      "name": "Company name"
    },
    "country": {
      "china": "China",
      "france": "France",
      "india": "India",
      "other": "Other(s)",
      "usa": "USA"
    },
    "delivery": "Delivery",
    "dropzone": {
      "buttonText": "Upload file",
      "description": "Drag and Drop here or choose file to run the algorithm"
    },
    "ecoFreighScoreLabel": {},
    "edit": "Edit",
    "error": "An error occured.",
    "hazardous": "Hazardous",
    "lclOrFcl": {
      "fcl": "Container",
      "lcl": "Grouping"
    },
    "location": {
      "additionalInformations": "Additional information",
      "address": "Address",
      "city": "City",
      "contactName": "Contact name",
      "country": "Country",
      "delivery": "Delivery location",
      "name": "Location name",
      "needsAppointment": "Appointment required",
      "pickup": "Pickup location",
      "postalCode": "Postal code",
      "receivingHours": "Receiving hours",
      "tailBoardNeeded": "Liftgate required for loading/unloading"
    },
    "modify": "Edit",
    "new": "New",
    "no": "No",
    "noData": "Not specified",
    "notSpecified": "Not specified",
    "optional": "(optional)",
    "optionalSimple": "optional",
    "restore": "Restore",
    "search": "Search",
    "seeAll": "See all",
    "seeLess": {
      "bis": "Show less"
    },
    "seeMore": "Show more",
    "shipmode": {
      "air": "Air",
      "barge": "Barge",
      "ocean": "Ocean",
      "rail": "Rail",
      "truck": "Truck"
    },
    "stepper": "Step {currentStepNumber} of {stepsCount}",
    "unarchive": "Restore",
    "unsure": "I don't know",
    "urgent": "Urgent",
    "with": "Yes",
    "without": "No",
    "yes": "Yes"
  },
  "modal": {
    "edit": {
      "vgm": {
        "cancelText": "Close",
        "confirmText": "Modification request",
        "contentText": "Do you want to submit VGM again ? Click on the button below and we will contact you",
        "contentTextBis": "Modification request received by Ovrsea, in process."
      }
    }
  },
  "modalBlToApprove": {
    "approve": "Approve",
    "reject": "Reject"
  },
  "modals": {
    "shipownerEditNotice": {
      "text": "An invoice has been attached to this supplier in the Final check. To remove this supplier, you must first delete the related invoice from the shipment's documents.",
      "title": "You can't remove this supplier"
    },
    "wallModal": {
      "deleteDocumentheader": "Do you confirm deleting the document?"
    }
  },
  "modify": {
    "document": {
      "categoryBis": "Edit"
    }
  },
  "network": {
    "contacts": {
      "infos": {},
      "list": {
        "noData": "No contact assigned for now",
        "noSupplierContact": "No supplier contact for now"
      }
    },
    "document": {
      "title": "Network"
    },
    "fields": {
      "contactSelectField": {
        "addContactForm": {
          "addButton": "Add",
          "fillOneOrBoth": "Please fill at least one of these 2 fields",
          "mail": "Contact email",
          "missingContactInformation": "Information is needed",
          "nameRequired": "Name",
          "newContact": "New Contact",
          "phone": "00 33 1 23 45 67 89",
          "unsavedChanges": "Please validate or cancel this contact creation"
        }
      }
    },
    "index": {
      "tab": {
        "hermes": {
          "locations": "Locations"
        }
      }
    },
    "location": {
      "contacts": "Contact(s)",
      "edit": {
        "contacts": {
          "createNewContact": "Add a contact",
          "placeholder": "Add a contact"
        },
        "country": {}
      },
      "form": {
        "addCompany": "Add legal company name",
        "addContact": "Add contacts",
        "addLocationTypes": "Add type",
        "chooseClient": "Select client",
        "chooseCompany": "Select company",
        "chooseCountry": "Select country",
        "chooseShipowner": "Select shipowner",
        "chooseState": "Select state",
        "label": {
          "additionalInformations": "Additional information",
          "address": "Address",
          "city": "City",
          "company": "Company",
          "country": "Country",
          "name": "Legal company name",
          "needsAppointment": "Appointment required",
          "postalCode": "Postal code",
          "receivingHours": "Opening hours",
          "state": "State",
          "tailBoardNeeded": "Liftgate required"
        },
        "networkAddress": "Address (number + street)",
        "networkName": "Ex.: Shanghai Warehouse",
        "newContact": "Create a new contact",
        "receivingHours": "ex: 9h - 18h"
      },
      "rightPanel": {
        "additionalInfos": "Additional information",
        "details": "Details",
        "hours": "Opening hours",
        "needsAppointment": "Need to book an appointment",
        "tailBoardNeeded": "Liftgate required for the loading/unloading"
      },
      "table": {
        "menuItem": {}
      }
    },
    "modals": {
      "locationModal": {
        "contactCardOpened": "Please add a contact or click cancel."
      }
    },
    "show": {
      "details": {
        "header": "General Information"
      },
      "edit": {
        "error": {}
      },
      "locations": {}
    },
    "table": {
      "emptyTable": "No companies yet",
      "locations": {
        "select": {
          "new": "New location"
        }
      },
      "locationsNb": "Locations number",
      "menuItem": {
        "restoreText": "Restore"
      },
      "name": "Name",
      "select": {}
    }
  },
  "new": {
    "message": {
      "buttonText": "Reply",
      "newMessageLabel": "New",
      "previousMessageLabel": "Previous message",
      "title": "New Message "
    },
    "newtork": {
      "location": {
        "button": {}
      }
    },
    "shipment": {
      "cancel": "Cancel this shipment",
      "createdByFreightForwarder": "{userName} ({forwarderName}) has launched a new shipment for you.",
      "deliveryHarbor": "Port of Discharge",
      "deliveryLocation": "Destination",
      "disclaimer": "If you don’t need this shipment or which to entrust it to another forwarder, you can cancel this shipment.",
      "fclOrlcl": "FCL/LCL",
      "freightForwarderReference": "Freight Forwarder Reference",
      "freightMethod": "Freight mode",
      "incoterms": "Incoterms",
      "linkLabel": "Kudos! You have a new shipment ({foreseaName}) from {nameOfClient}",
      "newShipmentLaunched": "New shipment launched",
      "pickupHarbor": "Port of Loading",
      "pickupLocation": "Origine",
      "taxableWeight": "Taxable Weight",
      "teus": "TEUs"
    }
  },
  "newBookingRequest": {
    "buttonText": "See on OVRSEA",
    "firstText": "Your supplier {supplierName} has initiated a new booking request.",
    "formTitle": "New booking request creation",
    "label": {
      "clientHint": "Choose a client",
      "commodity": "Commodity",
      "commodityAvailabilityDate": "Date of availability of commodities",
      "commodityDescription": "Commodity description",
      "commodityDescriptionHint": "Products description, DGR, etc.",
      "commodityNotice": "In case the goods are dangerous, please inform them below.",
      "documents": "Documents",
      "insurance": "Insurance for the shipment",
      "noShipmentOwnerAvailable": "No user available.",
      "noSupplierAvailable": "No supplier available.",
      "noUsersToNotify": "No other user available.",
      "purchasesOrder": "Purchase Orders",
      "shipmentNameHint": "Eg: Supplier - Brand - MM/AA",
      "shipmentOwner": "Shipment owner",
      "shipmentOwnerHint": "Choose a owner",
      "supplier": "Supplier",
      "supplierHint": "Choose a supplier.",
      "usersToNotify": "Additional users to notify",
      "usersToNotifyHint": "Eg: second responsible for shipment"
    },
    "secondText": "Please visit our platform to review and validate it.",
    "thank": "Thank you in advance.",
    "title": "New booking request to validate"
  },
  "newBookingRequestForm": {
    "header": {
      "title": "New booking request"
    },
    "labels": {
      "clientOwner": "Buyer",
      "userOwner": "Buyer's contact"
    }
  },
  "newDocumentsByOvrsea": {
    "buttonText": "See on OVRSEA",
    "documentsLabel": "Documents",
    "title": {
      "plural": "New documents available on your shipment",
      "singular": "New document available on your shipment"
    }
  },
  "newOrderFile": {
    "button": "New file",
    "modal": {
      "bookingRequest": {
        "description": "Make a booking request for one of your clients",
        "title": "Booking request"
      },
      "description": "Select the type of file you want to create",
      "erp": {
        "description": "Use your ERP data to create a new transport order",
        "title": "Shipment - ERP"
      },
      "orderPreparation": {
        "description": "Request an order preparation",
        "title": "Order preparation"
      },
      "requestForQuotes": {
        "description": "Query different forwarders in your panel",
        "title": "Request for quotes"
      },
      "shipmentOrder": {
        "description": "Entrust a shipment to one of your forwarders",
        "title": "Shipment"
      },
      "title": "Create a file"
    }
  },
  "newOrderPreparationRequest": {
    "general": "General",
    "goods": "Goods",
    "instructions": "Instructions",
    "orSpecifyPackagingDetails": "... or specify the packaging details of cargo",
    "ownerInChargeOfMovingGoodsInFcaExport": {
      "choice1": "Your forwarder",
      "choice2": "The client’s forwarder",
      "question": "Which forwarder will deliver goods?"
    },
    "pickingAlreadyRequestedDescription": "The preparation for the following picking numbers have already been requested",
    "pickingAlreadyRequestedTitle": "{numberOfAlreadyRequestedPickings, plural, one {Picking already requested} other {Pickings already requested}}",
    "selectOrderPreparation": "Select order preparations",
    "selectPackagedOrders": "Select packaged orders...",
    "title": "Request an order preparation",
  },
  "newQuotation": {
    "company": {
      "selectfield": {
        "extraAction": {}
      }
    },
    "errors": {
      "deliveryDate": "Desired delivery date must be after cargo availability date",
      "integer": {
        "loadsQuantity": "Quantity must be an integer",
        "positive": {
          "finalTotalQuantity": "Final quantity must be a positive integer"
        }
      },
      "number": {
        "cargoValue": "Please enter a valid number for commercial value.",
        "loadsHeight": "Height must be a number",
        "loadsLength": "Length must be a number",
        "loadsWeightPerUnit": "Unit weight must be a number",
        "loadsWidth": "Width must be a number",
        "mustBePositive": "Please enter a positive number",
        "totalVolume": "Total volume: must be a number",
        "totalWeight": "Total weight: must be a number"
      },
      "pickupDate": "Please fill an estimated cargo ready date",
      "required": {
        "cargoValue": "Please enter commercial value.",
        "finalTotalQuantity": "A final quantity is missing",
        "freightForwarders": "Please select at least one freight forwarder",
        "loadsHeight": "Height is missing",
        "loadsLength": "Length is missing",
        "loadsQuantity": "Quantity is missing",
        "loadsWeightPerUnit": "Unit weight is missing",
        "loadsWidth": "Width is missing",
        "title": "This field is required",
        "totalVolume": "Total volume: required field",
        "totalWeight": "Total weight: required field"
      }
    }
  },
  "newQuotation-incoterms-readDetailedArticle": "See detailed article",
  "newQuotationForm": {
    "caption": {
      "clientName": "The client can modify it afterwards",
      "logisticAgentReference": "You can modify it afterwards"
    },
    "draft": {
      "foreseaNameMissing": "It should not be possible to update a draft RFQ that's not associated to a file"
    },
    "footer": {
      "termsOfSaleLink": "General Terms of Sales",
      "termsOfSaleText": "By confirming below, you acknowledge these notices and accept our"
    },
    "label": {
      "addContainer": "Add",
      "addLocation": "Add a location",
      "address": "Address",
      "alertCloseCrdCostEstimationDescription": "Please check clients inputs and unflag the quote if it's not a cost estimation.",
      "alertCloseCrdCostEstimationTitle": "This quote is flagged as Cost Estimation but CRD is known and is less than 2 weeks away",
      "answerDeadline": "Answer desired before",
      "askForwarders": "Which forwarders would you like to query?",
      "attachment": "Attached file",
      "attachmentSingular": "Attached file",
      "attachments": "{numberOfAttachments, plural, one {Attached file} other {{numberOfAttachments} attached files}}",
      "attachmentsPlural": "attached files",
      "bulk": "Bulk",
      "bulkTotalVolume": "Total volume",
      "bulkTotalWeight": "Total weight",
      "cargoPlaneWarning": "Shipment by cargo plane (H > 160cm)",
      "cargoValue": "Commercial value of goods",
      "city": "City",
      "clientName": "Shipment name",
      "clientRoleOnOrder": "For this file, your client is the",
      "comment": "Specific instructions",
      "confirmAddLocation": "Add location",
      "container": "Container",
      "controlledTemperatureRange": "Temperature range",
      "costEstimationCrdInfoDescription": "Cargo availability will be considered as a rough estimate by our operational team.",
      "costEstimationCrdInfoTitle": "This quotation has been marked as a cost estimation",
      "costEstimationInfo": "For quotations requiring only a global cost estimate or used for commercial proposal.",
      "country": "Country",
      "countryState": "State",
      "createDeliveryLocation": "New delivery location",
      "createPickupLocation": "New pick-up location",
      "deliveryDate": "Desired delivery",
      "deliveryHarborAir": "Destination airport",
      "deliveryHarborOcean": "Destination port",
      "deliveryHarborRail": "Destination train station",
      "deliveryLocation": "Delivery location",
      "desiredPickupDate": "Desired pick-up date",
      "documents": "Documents",
      "documentsWarning": "Attaching documentation concerning dangerous products will drastically accelerate quoting time.",
      "entrustForwarder": "Which forwarder would you like to entrust this shipment to?",
      "euroPallet": "120 x 80 cm Pallet",
      "fixedRatesAsterisk": "* These prices can be revised if better rates are available",
      "fixedRatesTitlePlural": "You have {numberOfApplicableFixedRates} negotiated freight prices available for this route",
      "fixedRatesTitleSingular": "You have {numberOfApplicableFixedRates} negotiated freight price available for this route",
      "fixedRatesTooltip": "For further information on negotiated freight prices, please contact your Sales Representative directly.",
      "freightAllIn": "Freight all-in",
      "freightMethod": "Freight mode",
      "fullLoad": "full",
      "fullTruck": {},
      "googlePlaceLabel": "Suggested by Google",
      "hazardous": "Dangerous goods",
      "hazardousDetails": "Details for dangerous goods",
      "insurance": "We offer our clients an Ad Valorem insurance in partnership with ERGO.",
      "insuranceBody": "Ad Valorem insurance is a supplemental insurance policy purchased for each transportation, the cost of which is determined by the value of your goods. It provides full coverage, as opposed to the RCT (default insurance), which provides only partial coverage.",
      "insuranceCifWarning": "Shippers are obliged to carry or subscribe to a shipment insurance under {selectedIncoterm} incoterm.",
      "insuranceDisclaimer": "By taking out insurance, you undertake to provide OVRSEA with the most accurate information possible concerning the nature and value of the goods transported.",
      "insuranceKnowMore": "Read more",
      "insuranceKnowMoreUrl": "https://en.ovrsea.com/solutions/ad-valorem-insurance",
      "insurancePreselectionReason": "We have preselected this option as you regularly insure your shipments.",
      "insuranceSecondLabel": "Would you like to subscribe to it?",
      "isCostEstimation": "This request is for a rough estimate / a price estimation.",
      "isoPallet": "120 x 100 cm Pallet",
      "lessDetails": "Less detail",
      "lithium": "Lithium batteries",
      "lithiumAlone": "Batteries packed loose",
      "lithiumCategory": "Packaging of batteries",
      "lithiumContained": "Batteries contained in equipment",
      "lithiumPacked": "Batteries packed with equipment",
      "loadType": "Shipment type",
      "locationCompanyName": "Legal company name",
      "locationOrAddressBis": "Type in the location’s address",
      "logisticAgentReference": "Your shipment reference",
      "magnetic": "Magnetic goods",
      "moreDetails": "More details",
      "mostUsedHarbors": "Most used",
      "noLocationOptionsMessage": "No location found, please add a location below",
      "noPurchaseOrders": "No purchase orders available",
      "otherHarbors": "Others",
      "ownerQuotation": "Owner of this quote",
      "package": {
        "bis": "Package"
      },
      "palletizedSpecific": "Specific Pallet",
      "parties": {
        "connectionsLabel": "Select connections to share this request with",
        "subscribersLabel": "Select contacts to subscribe to this request"
      },
      "pickupDate": "Cargo availability",
      "pickupDateFooter": "Ready date not known yet?",
      "pickupDateModalContentFirstLine": "In that case please fill an estimated date at the proper month. This will help us find the best quotes for you.",
      "pickupDateModalContentSecondLine": "If our offer will be used for a quote on your end, please mention it in the specific instructions.",
      "pickupDateModalTitle": "Cargo ready date is not known yet?",
      "pickupHarborAir": "Origin airport",
      "pickupHarborOcean": "Origin port",
      "pickupHarborRail": "Origin train station",
      "pickupLocation": "Pick-up location",
      "pickupLocationFcl": "Pick-up location",
      "productsDescription": "Commodity description",
      "purchaseOrders": "Purchase Orders",
      "purchaseOrdersLoading": "Loading your purchase orders",
      "reduceRouteSteps": "Delete steps",
      "refrigerated": "Temperature-controlled",
      "rigidBox": "Rigid Crate",
      "route": "Steps are predefined by the selected incoterm.",
      "sapOrders": "Select orders from SAP",
      "sapOrdersAlternative": "...or precise the detail of the cargo",
      "saveDraft": "Save draft",
      "saveDraftConfirm": "Proceed anyway",
      "saveDraftConfirmationBody": "When saving this form as a draft, involved parties, purchase orders, freight forwarders won't be saved.",
      "selectAllFreightForwarders": "Query all of them",
      "selectPurchaseOrders": "Select purchase orders",
      "shipownerAddress": "Address",
      "showAllRouteSteps": "Show all steps",
      "specialInstructions": "Specific instructions",
      "specialProducts": "Specific goods in this shipment",
      "specific": "Pallets or Packages",
      "stackable": "Additional properties",
      "stackableDescription": "Cargo is stackable",
      "submit": "Request a quote",
      "submitShipment": "Request shipment",
      "unitSystem": "Cargo units",
      "uploadButton": "Attach documents",
      "uploadButtonHint": "Commercial Invoice, MSDS, DGD...",
      "uploadPackingList": "Attach Packing List",
      "uploadPurchaseOrdersButton": "Add POs",
      "urgent": "This quote request is urgent",
      "urgentDisclaimerOne": "Urgent requests will be dealt with top priority from our teams.",
      "urgentDisclaimerThree": "Urgency does not always allow us to guarantee the best offers. Whenever it is possible, we recommend to send us normal requests.",
      "urgentDisclaimerTwo": "If the cargo is available within the coming days, please book our offer within the hour to ensure space on-board.",
      "weightPerUnit": "Unit Weight",
      "withInsurance": "Subscribe for this shipment",
      "withoutInsurance": "Do not subscribe"
    },
    "placeholder": {
      "air": "Air",
      "clientName": "File reference, Purchase Orders, description",
      "controlledTemperatureRange": "Ex. : 5° - 25°C",
      "date": "DD MM YYYY",
      "googleSearch": "Search an address...",
      "hazardousDetails": "UN + Dangerous Class",
      "imperial": "in / lbs",
      "incoterm": "Other",
      "lithiumCategory": "Select packaging",
      "metric": "cm / kg",
      "ocean": "Ocean",
      "owner": "Owner",
      "ownerClient": "Choose a client",
      "productsDescription": "Please provide the commodity type and/or HS codes.",
      "rail": "Train",
      "receivingHours": "ex: 9h - 18h",
      "truck": "Truck"
    },
    "quotationDuplication": {
      "loading": {
        "first": "We are currently loading your quotations.",
        "second": "It should not take more than 10 seconds."
      },
      "modal": {
        "closeButtonContent": "Cancel",
        "header": "Select your template",
        "nothingFound": "No template found.",
        "searchbarPlaceholder": "Search a template",
        "submitButtonContent": "Use this template"
      },
      "openModalButton": "Start with a template",
      "suppliersLocationWarning": {
        "description": "This quote is based on locations from your network, complete the route with your own locations.",
        "title": "Locations unavailable in your directory"
      },
      "warningMessage": {
        "description": "This quote has been replicated from a template, make sure every information is correct before submitting your request.",
        "title": "Have you checked everything?"
      }
    },
    "section": {
      "cargo": "Cargo",
      "comment": "Comments",
      "freight": "Freight",
      "freightForwarders": "Assignment",
      "general": "General",
      "insurance": "Ad Valorem Insurance",
      "negotiatedPropositions": "Your negotiated offers",
      "ownerSelection": "To be invoiced to",
      "packaging": "Packaging",
      "parties": "Parties",
      "pickingReferences": "Picking References",
      "pickingReferencesTip": "Copy and paste your picking references. Accepted separators: spaces, commas and /",
      "route": "Route",
      "specialProducts": "Transported goods",
      "title": "Request a quote",
      "titlePicking": "Prepare new shipping",
      "titleShipment": "Request a shipment",
      "trackShipment": "Track a shipment"
    }
  },
  "newShipment": {
    "buyer": "Buyer",
    "clientRole": "For this shipment, {clientName} is the",
    "clientTransporter": "Specify the transporter of the client",
    "decideLater": "Decide Later",
    "forwarders": "Forwarder",
    "forwardersDescription": "Select the forwarder to whom you want to entrust this shipment",
    "orderType": {
      "question": "What would you like to do?",
      "quotation": {
        "description": "Request for quotes",
        "title": "Benchmark several forwarders"
      },
      "shipment": {
        "description": "Send a shipment order",
        "title": "Entrust the shipment to a forwarder"
      }
    },
    "requestedBy": "Requested on behalf of",
    "seller": "Seller",
    "shouldLabelPackages": "Labeling packages is required",
    "shouldPalletise": "Palettisation is required",
    "stepTwo": {
      "hazardous": "Hazardous",
      "lithium": "Battery",
      "magnetic": "Magnetic",
      "refrigerated": "Refrigerated"
    },
    "transporter": "Transporter"
  },
  "news-card": {
    "categories": {
      "capacity": "🚢 Capacity",
      "companies": "🏢 Companies",
      "geopolitics": "🌍 Geopolitics",
      "national_days_off": "📆 National days off",
      "other": "Other",
      "rates": "💰 Rates",
      "regulatory": "🛃 Regulatory",
      "strikes": "💥 Strikes",
      "weather_conditions": "🌤️ Weather conditions"
    },
    "emptyStateFirstLine": "Everything is fine!",
    "emptyStateSecondLine": "Our teams are on alert to keep you posted.",
    "freightMethods": {
      "air": "✈️ Air",
      "barge": "⛴️ Barge",
      "ocean": "🚢 Ocean",
      "rail": "🚄 Rail",
      "truck": "🚚 Truck"
    },
    "header": "News on shipping",
    "info": "We collect each week shipping information from our partners around the world.",
    "regions": {
      "africa": "Africa",
      "america": "America",
      "asia": "Asia",
      "central_south_america": "Central & South America",
      "europe": "Europe",
      "middle_east": "Middle East",
      "north_america": "North America",
      "oceania": "Oceania",
      "world": "World"
    },
    "world": "World"
  },
  "notificationMessages": {},
  "notifications": {
    "body": {
      "statusCircle": {}
    },
    "date": {
      "today": "Today"
    },
    "descriptions": {
      "billing_available": {
                "description": "A new invoice is available for this shipment.",
                "title": ""
            },
            "bl_to_approve": {
                "description": "BL draft available for you validation",
                "title": "Validate BL draft"
            },
            "booking_request_asked": {
                "description": "New booking request by {supplierName} available for your approval",
                "title": "Validate booking request"
            },
            "container_deliveries_planned": {
                "description": "The delivery for {numberOfContainers} containers is planned starting {firstContainerDeliveryDate}",
                "title": ""
            },
            "containers_info_asked": {
                "description": "Container identification and cargo weight to complete or provide",
                "title": "Check container information"
            },
            "delivery_planned": {
                "description": "The delivery is planned for {deliveryDate}.",
                "title": ""
            },
            "documents_asked": {
                "description": "{documentTypes} to provide",
                "title": "Upload missing documents"
            },
            "documents_uploaded_by_client": {
                "description": "New documents are available. You can view them on the shipment.",
                "title": ""
            },
            "documents_uploaded_by_ovrsea": {
                "description": "New documents are available. You can view them on the shipment",
                "title": ""
            },
            "eta_updated": {
                "description": "New ETA: The arrival of this shipment has been {delayedOrAdvanced} by {numberOfTimeUnits} {timeUnits}.",
                "title": ""
            },
            "eta_updated_day_plural": "days",
            "eta_updated_day_singular": "day",
            "eta_updated_hour_plural": "hours",
            "eta_updated_hour_singular": "hour",
            "new_ai_commercial_invoice_extract": {
              "description": "Your products have been detected",
              "title": ""
            },
            "new_message": {
              "description": "New message",
              "title": ""
            },
            "proposition_updated": {
                "description": "{numberOfPropositions} new {numberOfPropositions, plural, one{ proposition} other{ propositions}}, expiring on {minValidityDate}",
                "title": "Review updated quote"
            },
            "propositions_received": {
                "description": "{numberOfPropositions} {numberOfPropositions, plural, one{ proposition} other{ propositions}}, expiring on {minValidityDate}",
                "title": "Review new quote"
            },
            "quotation_created": {
                "description": "Your quote request has been successfully transmitted.",
                "title": ""
            },
            "rate_shipment": {
                "description": "This shipment is finished! Please rate it to help us improve our service",
                "title": "Give feedback on shipment"
            },
            "shipment_arrived": {
                "description": "Your cargo has been delivered on {deliveryDate}.",
                "title": ""
            },
            "shipment_booked": {
                "description": "This shipment has been booked with {shipownerName}.",
                "title": ""
            },
            "shipment_shared": {
                "description": "{partnerName} has shared this shipment with you. You can check the information of this shipment.",
                "title": ""
            }
    },
    "error": "Error in notification recovery",
    "eta": {
      "advanced": "advanced",
      "delayed": "delayed"
    },
    "header": {
      "markAllAsRead": "Mark all as read",
      "title": "Notifications",
      "unreadMessages": "Unread",
      "unreadNotifications": "Unread"
    },
    "message": {
      "actionButtonLabel": "Mark as read",
      "header": {
        "settings": "Notification settings",
        "title": "Messages"
      }
    },
    "noActionDataBody": "You have completed all important actions.",
    "noActionDataTitle": "All done!",
    "noData": "No notification",
    "tabs": {
      "action": "Important actions",
      "information": "Information"
    }
  },
  "order": {
    "cargo": {
      "container": {
        "container": "Container",
        "errors": {
          "name": "The container name must consist of 4 letters followed by 7 digits (eg BCDU1234567)",
        },
        "gross": "Gross weight",
        "hazardous": "This container is hazardous",
        "identification": "Identification",
        "loading": "Loading",
        "loadingRef": "Loading reference",
        "manage": {
          "add": "Add new containers",
          "added": "{count} {count, plural, one {container} other {containers}} will be added",
          "deleted": "{count} {count, plural, one {container} other {containers}} will be deleted",
          "remove": "Remove existing containers",
          "title": "Manage containers",
        },
        "modal": "Edit container",
        "number": "Container number",
        "order": "Orders",
        "packages": "Total packages",
        "pallets": "Total pallets",
        "pos": "Purchase orders",
        "posit": "Positioning date",
        "products": "Total products",
        "reference": "Loading reference",
        "referencing": "Referencing",
        "seal": "Seal number",
        "tare": "Tare weight",
        "type": "Type",
        "typeLong": "Container type",
        "unloading": {
          "ref": "Unloading references",
          "section": "Unloading",
          "separators": "Accepted separators: spaces, commas and /.",
          "tip": "Copy and paste unloading references (INBSHIP, WMS References, etc.).",
        },
        "volume": "Volume",
      },
      "loads": {
        "bulk": {
          "edit": "Edit bulk cargo",
          "switch": "Switch to packaged goods"
        },
        "edit": "Update cargo",
        "hazardous": "This cargo is hazardous",
        "outOfGauge": "Out of gauge",
        "switch": "Switch to bulk"
      },
      "totals": {
        "containers": "Containers",
        "description": {
          "empty": "No description",
          "label": "Cargo description"
        },
        "grossWeight": "Total gross weight",
        "packages": "Packages",
        "pallets": "Pallets",
        "pos": "Purchase orders",
        "products": "Shipped products",
        "teus": "TEUs",
        "units": "Total units",
        "volume": "Total volume",
      },
      "vgm": {
        "see": "See vgm",
      },
    },
    "repriceForm": {
      "details": "Details",
      "force_motive": "Select a motive.",
      "motive": "Motive",
      "question": "Are you sure you want to reprice this shipment?",
      "reminder": "Reminder"
    },
    "route": {
      "location": {
        "warehouse": "Warehouse"
      }
    },
    "show": {
      "billing": {
        "available": {
          "bis": "Available"
        },
        "due": {
          "jours": {
            "bis": "Due"
          }
        },
        "litigious": "Litigious",
        "paid": {
          "bis": "Paid"
        }
      }
    }
  },
  "orderCard": {
    "orderButton": {
      "bookingRequest": {
        "bis": "New booking"
      },
      "picking": {
        "bis": "New shipping"
      },
      "quotations": {
        "bis": "New quote"
      },
      "shipments": {
        "bis": "New shipment"
      }
    }
  },
  "orderNotFound": {
    "backToDashboard": "Back to dashboard",
    "backToFlows": "Back to flows",
    "backToQuotations": "Back to quotes table",
    "backToShipments": "Back to shipments table",
    "orderPreparation": "Could not find this order preparation request",
    "orders": "This shipment or quote request couldn't be found",
    "quotation": "Could not find this quote",
    "shipments": "Could not find this shipment"
  },
  "orderPreparationRequest": {
    "associatedOrderPreparationRequests": "Associated order preparations",
    "breadcrumb": {
      "orderPreparation": "Order Preparation"
    },
    "delayBiggerThanSlaAgreed": "+ {slaAgreed} hours",
    "editDeliveryLocation": {
      "deliveryLocation": "Delivery location",
      "modalTitle": "Specify the delivery location"
    },
    "editPallets": {
      "addPallet": "Add a pallet",
      "isStackable": "Stackable",
      "modalHeader": "Add or edit pallets",
      "palletType": "Type"
    },
    "header": {
      "pickingReferences": "Picking References",
      "share": "Share eShipping",
      "unspecified": "Unspecified"
    },
    "loads": {
      "dimensions": "Dimensions",
      "empty": {
        "description": "Please provide details of palletization.",
        "inform": "Fill in",
        "title": "Palletizing is required for this preparation."
      },
      "quantity": "Quantity",
      "stackable": "Stackable",
      "type": "Type",
      "unitWeight": "Weight/U"
    },
    "markAsReadyToShip": {
      "button": "Declare as ready for pickup",
      "modal": {
        "crd": "Cargo Ready Date",
        "details": "Provide details",
        "instructions": "Before declaring cargo as ready for pickup, ensure that the following conditions are met.",
        "packingList": "Packing List",
        "palletizationDetails": "Details of palletization",
        "title": "Declare cargo as ready for pickup",
        "upload": "Upload document"
      }
    },
    "order": {
      "alongWith": "Along with",
      "launch": "Launch a shipment",
      "noShipment": "No associated shipment",
      "title": "Shipment"
    },
    "packages": {},
    "packingListExtract": {
      "add": "Add a Packing List",
      "addLoad": "Add a package",
      "banner": "Zach, our AI assistant, automatically reads your Packing List.",
      "extractedPickingReferenceDoesNotExistInCurrentOrderPreparationRequest1": "This Packing List probably corresponds to another file",
      "extractedPickingReferenceDoesNotExistInCurrentOrderPreparationRequest2": "The number on this Packing List ({pickingNumber}) does not match any picking references in this file.",
      "extractedPickingReferenceHasAlreadyBeenExtracted1": "Packing List already downloaded",
      "extractedPickingReferenceHasAlreadyBeenExtracted2": "Packing details are already available for picking reference {pickingNumber}.",
      "height": "Height (cm)",
      "length": "Length (cm)",
      "modalHeader": "Upload a Packing List",
      "packages": "Packages",
      "processing": "Zach is extracting information from your Packing List",
      "quantity": "Quantity",
      "submit": "Upload Packing List",
      "success": "Successful extraction!",
      "unitWeight": "Weight / U (kg)",
      "validate": "Zach has extracted the details of packages from your Packing List. Please check and correct information if necessary.",
      "volume": "Volume",
      "weight": "Weight",
      "width": "Width (cm)",
      "willExtract": "Once uploaded, Zach will extract information from your Packing List"
    },
    "pickingReferencesCard": {
      "instructions": "Instructions",
      "references": "References",
      "shouldLabelise": "Loads to labelise",
      "shouldPalletise": "Loads to palletise"
    },
    "pickupReferences": "Pickup References",
    "status": {
      "forClient": {
        "appointment_taken": "Scheduled pickup",
        "cancelled": "Cancelled",
        "finished": "Completed",
        "ready_to_ship": "Pickup to be scheduled",
        "shipped": "EXA to be collected",
        "to_prepare": "Waiting for preparation"
      },
      "forWarehouse": {
        "appointment_taken": "Scheduled pickup",
        "cancelled": "Cancelled",
        "finished": "Completed",
        "ready_to_ship": "Ready for pickup",
        "shipped": "Picked up",
        "to_prepare": "To be prepared"
      }
    },
    "table": {
      "filters": {
        "all": "All",
        "finished": "Finished",
        "forClient": {
          "appointment_taken": "Pickup planned",
          "cancelled": "Cancelled",
          "finished": "Finished",
          "ready_to_ship": "Pickup to plan",
          "shipped": "EXA to be collected",
          "to_prepare": "Waiting for preparation"
        },
        "forWarehouse": {
          "appointment_taken": "Pickup planned",
          "cancelled": "Cancelled",
          "ready_to_ship": "Pickup to plan",
          "shipped": "Pickup done",
          "to_prepare": "To prepare"
        },
        "ongoing": "Ongoing"
      },
      "headers": {
        "file": "File",
        "new": "Request a preparation",
        "numberOfReferences": "# of references",
        "owner": "Owner",
        "pickupDate": "Pickup",
        "reference": "Reference",
        "requestedAt": "Request date",
        "requester": "Requester",
        "shipment": "Shipment",
        "status": "Status"
      }
    },
    "tabs": {
      "dashboard": "Dashboard",
      "documents": "Documents",
      "packaging": "Packaging"
    },
    "timeline": {
      "addDelivery": "Add a delivery location",
      "changeDelivery": "Change delivery location",
      "packaged": "Goods packaged",
      "pickup": "Pickup",
      "requested": "Order preparation requested",
      "requestedDate": "Requested date",
      "timeline": "Timeline"
    }
  },
  "orders": {
    "clientName": {
      "label": "Name"
    },
    "document": {
      "title": "Purchase Orders"
    },
    "edit": "Edit order name",
    "editForwarderReference": {
      "addReference": "Add your reference",
      "clientReference": "Client reference",
      "editReferences": "Edit references",
      "forwarderReference": "Your internal reference"
    },
    "logisticAgent": {
      "label": "Freight forwarder",
      "operatedBy": "Operated by { assignedFreightForwarderName }"
    },
    "pickings": {
      "markAsReady": "Mark as ready",
      "pickingReferences": "Picking References",
      "shouldPalletise": "Palettisation is required",
      "transporter": "Client Transporter"
    },
    "urgent": {
      "label": "This order is urgent"
    },
    "userSubscription": {
      "follow": "Follow shipment",
      "followTooltip": "Be notified of new messages and milestones",
      "ownerTooltip": "You can't unfollow a shipment that you created",
      "unfollow": "Unfollow shipment",
      "unfollowTooltip": "Stop being notified of new messages and milestones"
    }
  },
  "packages": {
    "array": {
      "row": {
        "add": "Add pallet / package",
        "number": "#"
      }
    }
  },
  "pad": {
    "messages": {
      "description": {}
    },
    "tasks": {
      "laterTasksHeader": "Later",
      "pastTasksHeader": "Past days",
      "task": "task",
      "tasks": "tasks",
      "todayTasksHeader": "Today",
      "tomorrowTasksHeader": "Tomorrow"
    }
  },
  "pallets": {
    "noLoads": "No cargo",
    "plural": "Pallets",
    "singular": "Pallet"
  },
  "password": {
    "reset": {
      "confirmation": {},
      "emailSent": "A password renewal email has been sent to you",
      "fifth": "Your password will not be reset until you have created a new one.",
      "fourthText": "If you didn't ask for a password reset, you can ignore this email or contact our teams.",
      "request": {},
      "success": {
        "not_confirmed": "Account not confirmed"
      },
      "thirdText": "This link expires in 24 hours."
    }
  },
  "passwordReset": {
    "passwordConfirmation": "Password confirmation"
  },
  "pdf": {
    "document": {
      "title": "PDF View"
    },
    "views": {
      "proposition": {
        "header": {
          "reference": "Reference",
          "summary": {
            "clientName": "Client reference",
            "quotation": "Quote"
          },
          "validity": "Validity"
        },
        "route": "Detailed route",
        "specific": "Specifics",
        "terms": "Terms & Conditions"
      }
    }
  },
  "picking": {
    "buttonLabel": "Voir sur OneChain",
    "new": {
      "body": "{userName} ({nameOfClient}) vous a adressé une nouvelle demande de préparation de commande",
      "instructions": "Instructions de conditionnement",
      "references": "Références",
      "shouldPalletize": "Palletisation demandée",
      "title": "Nouvelle commande à préparer"
    },
    "readyForPickup": {
      "body": "{userName} ({nameOfClient}) a confirmé que la marchandise était prête pour enlèvement",
      "conditionning": "Conditionnement",
      "isPalletized": "Palletisation effectuée",
      "references": "Références",
      "title": "Marchandise prête pour enlèvement"
    }
  },
  "places": {
    "emptyResult": "This address does not exist yet in your repertory",
    "headers": {}
  },
  "planned": {
    "delivery": {
      "buttonText": "See on OVRSEA"
    }
  },
  "priceNames": {
    "arrivalFees": "Arrival Fees",
    "freight": "Freight",
    "onCarriage": "On-Carriage"
  },
  "pricing": {
    "button": {
      "popUpContent": {
        "notToBePriced": "MOVE OUT PRICING DASHBOARD. \n\n This setting is internal only, it creates neither email alert nor wall activity. ",
        "toBePriced": "SET ON PURCHASE READY. \n\n This setting is internal only, it creates neither email alert nor wall activity. "
      }
    },
    "document": {
      "title": "Pricing Dashboard"
    }
  },
  "pricing-next": {
    "document": {
      "title": "Pricing Dashboard"
    }
  },
  "pricingFormSection": {
    "ratesToDb": {}
  },
  "products": {
    "document": {
      "title": "Products"
    },
    "poStatus": {
      "delivered": "PO delivered",
      "inProgress": "PO in progress"
    }
  },
  "profil": {
    "edit": {
      "password": {
        "confirmNewPassword": "New password confirmation",
        "newPassword": "New password",
        "oldPassword": "Old password"
      }
    }
  },
  "profile": {
    "avatar": {
      "add": "Add a profile picture",
      "change": "Change your profile picture",
      "editModal": {
        "description": "This is how you will appear on OVRSEA",
        "dropzoneButtonAdd": "Add",
        "dropzoneButtonUpdate": "Update",
        "dropzoneDescription": "Accepted files: png, jpeg, jpg",
        "dropzoneTitle": "My picture",
        "placeholder": "Pick a picture",
        "save": "Save",
        "scaleInfotip": "Use the mouse wheel to zoom in and out",
        "title": "Update your profile picture"
      }
    },
    "changePassword": "Edit your password",
    "display": {
      "role": {
        "language": {
          "bis": "Language"
        },
        "password": {}
      }
    },
    "document": {
      "title": "My profile"
    },
    "edit": {
      "email": "E-mail address",
      "firstName": "First name",
      "form": {
        "errors": {
          "emailInvalid": "E-mail address not valid.",
          "empty": "Required field"
        }
      },
      "lastName": "Last name",
      "password": {
        "form": {
          "errors": {
            "differents": "Different passwords.",
            "new6char": "Password must contain at leat 6 characters",
            "old": "Required field"
          },
          "forgot": "Forgot your password ?"
        }
      },
      "phone": "Phone",
      "role": "Role"
    },
    "email": {},
    "firstName": {},
    "lastName": {},
    "notifications": {
      "air": "Air",
      "alert": "If you choose to no longer receive certain notifications, you will still be notified if you are mentioned by a user.",
      "barge": "Barge",
      "billing": "Billing",
      "body": "Define what you are notified for by OneChain",
      "bookings_and_operations": "Bookings & operations",
      "freightModes": "Freight modes",
      "ocean": "Ocean",
      "quotes": "Quotes",
      "rail": "Rail",
      "scopes": "Scopes",
      "truck": "Truck"
    },
    "phone": {},
    "subtitles": {
      "contactInformation": "Contact information",
      "notificationSettings": "Notification settings",
      "preferences": "Preferences"
    }
  },
  "proposition": {
    "available": {
      "buttonText": "See on OVRSEA",
      "title": "{employeeName} ({companyName}) has shared new quotes."
    },
    "brandedPropositionCard": {
      "badge": {
        "description": "Priority boarding | Faster access to containers"
      },
      "carrier": "Carrier",
      "co2Emissions": {
        "co2EmissionReductionPopover": {
          "badge": "{reductionPercent} of CO2e emissions avoided",
          "body": {
            "electric": {
              "post_carriage": "Save {freightStepReductionFactor} in CO2e emissions on delivery by using an electric truck, resulting in a",
              "pre_carriage": "Save {freightStepReductionFactor} in CO2e emissions on pickup by using an electric truck, resulting in a"
            },
            "freightStepDiscount": {
              "freight": "Save {freightStepReductionFactor} in CO2e emissions on freight by using {fuelName}, resulting in a",
              "post_carriage": "Save {freightStepReductionFactor} in CO2e emissions on delivery by using {fuelName}, resulting in a",
              "pre_carriage": "Save {freightStepReductionFactor} in CO2e emissions on pickup by using {fuelName}, resulting in a"
            },
            "totalDiscount": " {totalCo2ReductionFactor} overall reduction in CO2e emissions for this shipment."
          },
          "footer": {
            "B100": "https://ecofreight.ovrsea.com/academy/advanced/alternative-fuels/b100",
            "Biofuel": "https://ecofreight.ovrsea.com/academy/advanced/alternative-fuels/biofuel",
            "Electric": "https://ecofreight.ovrsea.com/academy/advanced/alternative-fuels",
            "LNG": "https://ecofreight.ovrsea.com/academy/advanced/alternative-fuels/lng",
            "generic": "https://ecofreight.ovrsea.com/academy/advanced/alternative-fuels",
            "linkText": {
              "electric": "Learn more about alternative fuels",
              "generic": "Learn more about {fuelName}"
            }
          },
          "title": {
            "electric": "Opt for an electric truck",
            "freight": "Opt for {fuelName}-powered vessel",
            "generic": "Opt for alternative fuels",
            "post_carriage": "Opt for {fuelName}-powered truck",
            "pre_carriage": "Opt for {fuelName}-powered truck"
          }
        }
      },
      "comment": "Comment",
      "freightRate": "Freight Rate",
      "totalPrice": "Total Price",
      "transitTime": "Transit time"
    },
    "cargaison": {
      "information": {
        "colisPlural": "Packages",
        "colisSingular": "Package",
        "containers": {
          "hazardous": ", of which {numberHazardous} hazardous"
        },
        "fullTruck": "Full truck",
        "hazardous": "Dangerous",
        "lithium": "Lithium",
        "magnetic": "Magnetic",
        "noStackable": "Non stackable",
        "pallet": "Pallet",
        "pallets": "Pallets",
        "refrigerated": "Refrigerated",
        "taxableWeight": "Chargeable weight",
        "totalVolume": "Total volume",
        "totalWeight": "Total weight",
        "weightPerUnit": "kg/piece"
      }
    },
    "footer": {
      "disclaimer1": "* Prices do not include taxes, customs duties or import VAT.",
      "disclaimer1WithCustomsAdvance": "* Prices do not include taxes, customs duties, import VAT or customs advance fee.",
      "disclaimer2": "** Provided carrier capacity and booking before "
    },
    "status": {
      "accepted": "Accepted",
      "declined": {
        "bis": "Declined"
      }
    }
  },
  "propositions": {
    "document": {
      "title": "Shared propositions"
    }
  },
  "purchaseOrderMessages": {
    "availablePOs": "Available POs",
    "bookedQuantity": "Booked quantity",
    "finalQuantity": "Final Qty",
    "noAvailablePOs": "No PO available",
    "noAvailablePOsForThisClient": "No PO available for this client",
    "noSelectedPOs": "No PO selected",
    "provider": "Supplier",
    "reference": "Reference",
    "searchPOs": "Search by reference",
    "seeFullList": "Show {slicedListLength} more elements",
    "seeFullListSingular": "Show 1 more element",
    "selectAClientFirst": "Please select a client first.",
    "selectedPOs": "Selected POs",
    "selectedPOsDetails": "Details for selected POs"
  },
  "purchaseOrders": {
    "addPurchaseOrders": "Add POs",
    "cardHeader": "Cargo details",
    "desiredDelivery": "Desired delivery",
    "desiredPickup": "Desired pick-up",
    "downloadAsCSV": "Download as CSV",
    "exportPurchaseOrdersReport": "Download",
    "final": "Final",
    "hscode": "HS Code",
    "initial": "Initial",
    "initialQuantity": "Initial quantity",
    "name": "Name",
    "noData": "No Purchase Order for this shipment",
    "noDataForThisShipment": "No PO for this shipment",
    "numberOfActiveShipments": "Number of active shipments",
    "numberOfUnitsInTransit": "Units in transit",
    "orderStatus": "Status",
    "productName": "Name",
    "purchaseOrderNotAttached": "No shipment attached",
    "reference": "Reference",
    "shrunkInitialQuantity": "Initial qty",
    "shrunkTotalQuantity": "Final qty",
    "specific": "Specific",
    "supplier": "Supplier",
    "tableContainerNumber": "Container Number",
    "totalQuantity": "Final quantity",
    "unitPrice": "Unit Price",
    "updatePurchaseOrders": "Update POs",
    "updateQuantities": "Update quantities"
  },
  "purchaseOrdersSelectionModal": {
    "assign": "Assign",
    "assignFull": "Assign full",
    "assignPartially": "Assign partially",
    "available": "available",
    "cancel": "Cancel",
    "collapse": "Collapse",
    "complete": "Complete",
    "empty": "No POs found",
    "expand": "Expand",
    "header": "Choose the purchase orders for this booking",
    "hsCode": "HS Code",
    "name": "Name",
    "orderedQuantity": "Ordered quantity",
    "partial": "Partial",
    "productsOrdered": "products ordered",
    "productsSelected": "products selected",
    "reference": "Reference",
    "selected": "selected",
    "selectedPurchaseOrders": "Selected POs",
    "selectedQuantity": "Selected quantity",
    "sku": "SKU",
    "uniqueReferences": "unique references",
    "validate": "Validate"
  },
  "quotation": {
    "comparator": {
      "priceCategory": {
        "arrival_fees": "Arrival fees",
        "customs": "Customs",
        "departure_fees": "Departure fees",
        "freight": "Freight",
        "insurance": "Insurance",
        "on_carriage": "On-carriage",
        "others": "Others",
        "pre_carriage": "Pre-carriage"
      },
      "title": "Compare received offers"
    },
    "declineMessages": {
      "client_no_longer_served": "Client no longer served",
      "created_by_mistake_or_transferred": "This file has been created my mistake or transferred to another file",
      "no_solution_available": "No shipping solution found",
      "non_competitive_prices": "Prices are not competitive",
      "order_preparation_cancelled": "This order preparation has been cancelled by the client",
      "other": "Other",
      "out_of_geographic_scope": "Out-of-Scope Geography",
      "out_of_prestation_scope": "Out-of-Scope Services (cargo, messenger)",
      "requested_by_mistake": "This request has been created by mistake",
      "shipment_cancelled": "This shipment has been canceled",
      "tarification_usage": "This quote is used for a proposal",
      "too_long_to_answer": "It took too long to get a quote",
      "transfered_to_another_shipment": "Transferred to another shipment",
      "transit_time_too_long": "Transit Time is too long"
    },
    "document": {
      "title": "Quote"
    },
    "new": {
      "controlledSearchField": {},
      "fifth": {
        "other": {
          "customs": {}
        }
      },
      "first": {
        "loadType": {},
        "shipmentName": {}
      },
      "fourth": {
        "insurance": {
          "precisions": {}
        },
        "precisions": {}
      },
      "portSelectField": {},
      "searchField": {},
      "second": {
        "datesComplementary": {},
        "originDestination": {
          "locations": {
            "addbis": "Add \"{location}\"",
            "addbisEmpty": "Add a location",
            "placeholder": "Select location"
          }
        }
      }
    },
    "replicateButton": "Replicate this quote",
    "requoteMessages": {
      "cargo_updated": "Change in cargo",
      "cargoChangeDetails": {
        "becomeAboveHeightLimit": "Height has moved above 160cm",
        "becomeHazardous": "The cargo became hazardous",
        "becomeNonStackable": "The cargo became non stackable",
        "deltas": {
          "pallets": "Δ Pallets",
          "taxableWeight": "Δ TW",
          "volume": "Δ Volume",
          "weight": "Δ Weight"
        },
        "densityRatioInterval": "Density ratio has moved from {oldDensityInterval} to {newDensityInterval} interval",
        "header": "Cargo change details",
        "taxableWeightInterval": "Taxable weight has moved from {oldTaxableInterval} to {newTaxableInterval} interval"
      },
      "change_of_prestation": "Change of prestation",
      "company_updated": "Change in company",
      "expired": "Expired",
      "freight_method_changed": "Change in freight method",
      "other": "Other",
      "prices_too_high": "Price too high",
      "rates_update": "Rates update",
      "service_added": "Service added",
      "unsatisfactory_service": "Unsatisfactory service (transit time too long, wrong company)"
    },
    "sdhow": {
      "cargo": {
        "containers": {
          "vgmCreate": {}
        }
      }
    },
    "show": {
      "Cargo": {
        "packages": {
          "add": {
            "row": {
              "Section": {
                "productsDescription": "Additional information"
              }
            }
          }
        }
      },
      "cargo": {
        "containers": {
          "add": {
            "containers": {
              "row": {
                "hazardous": "Hazardous",
                "number": "Number*",
                "type": "Type*"
              }
            },
            "shipment": {
              "row": {
                "containerPositDate": "Date",
                "containerPositHour": "Hour",
                "loadingRef": "Reference"
              }
            }
          },
          "details": {
            "vgmCreate": {
              "bookingNumber": {
                "header": "Booking number"
              },
              "confirmationCheckbox": {
                "message": "By ticking this box, we certify that the aforementionned VGM was caclculated in accordance with the criteria defined by SOLAS convention and authorize Foresea Technologies SAS (commercial name : OVRSEA) to transmit this document to adequate authorities and also to the maritime company. All the consequences and fees related to an incorrect VGM are exclusively at the expense of the expeditor and without possible recourse to Foresea Technologies."
              },
              "containerNumber": {
                "hint": "Container number"
              },
              "header": "Submit VGM",
              "vgmWeight": {
                "hint": "VGM weight"
              },
              "weightCalculation": {
                "header": "Method used to calculate weight",
                "method_1": "Method 1 - Weighing",
                "method_2": "Method 2 - Addition of masses"
              }
            },
            "vgmModal": {
              "bookingNumber": "Booking number",
              "conditions": "By ticking this box, we certify that the aforementionned VGM was caclculated in accordance with the criteria defined by SOLAS convention and authorize Foresea Technologies SAS (commercial name : OVRSEA) to transmit this document to adequate authorities and also to the maritime company. All the consequences and fees related to an incorret VGM are exclusively at the expense of the expeditor and without possible recourse to Foresea Technologies.",
              "containerNumber": "Container number",
              "goodsWeight": "Merchandise weight (in kg)",
              "grossWeight": "Gross weight (in kg)",
              "leadNumber": "Seal number",
              "method_1": "Method 1 - Weighing",
              "method_2": "Method 2 - Addition of masses",
              "packagingWeight": "Packaging + pallets weight (in kg)",
              "tareWeight": "Tare weight of container (in kg)",
              "vgmWeight": "VGM weight (in kg)",
              "weightCalculationMethod": "Method used to calculate weight"
            }
          },
          "edit": {
            "goodsWeight": {
              "hint": "Merchandise weight (in kg)"
            },
            "grossWeight": {
              "hint": "Gross weight (in kg)"
            },
            "leadNumber": {
              "hint": "Seal number"
            },
            "packagingWeight": {
              "hint": "Packaging + pallets weight (in kg)"
            },
            "tareWeight": {
              "hint": "Tare weight of container (in kg)"
            }
          },
          "vgmCreate": {
            "error": {
              "confirmationCheck": "Pour soumettre la VGM, veuillez lire les conditions de soumission de la VGM et cocher la case pour prouver votre accord.",
              "containerNumber": "Field to be completed by Ovrsea.",
              "leadNumber": "Mandatory field."
            },
            "submitVGM": "Submit VGM"
          }
        },
        "packages": {
          "add": {
            "row": {
              "colis": "Packages (non palletized)",
              "fullTruck": "Full truck",
              "hazardousDetails": {
                "label": "Class/UN Number",
                "placeholder": "Class/UN #",
                "question": {
                  "a": " Hazardous class or type :",
                  "b": " Hazardous class or type"
                }
              },
              "height": {
                "bis1": "H (cm)",
                "title": "Height"
              },
              "length": {
                "bis1": "L (cm)",
                "title": "Length"
              },
              "palletized_80_120": {
                "bis": "Pallet 120x80 cm"
              },
              "palletized_100_120": {
                "bis": "Pallet 120x100 cm"
              },
              "palletized_specific": "Pallet specific",
              "productsDescription": {
                "placeholder": "Please share all pertinent information regarding your merchandise (description, HS code, caracteristics, ...)"
              },
              "qnt": "Qty",
              "quantity": {
                "bis": "Quantity",
                "title": "Quantity"
              },
              "section": {
                "dimensions": "Dimensions",
                "properties": "Properties"
              },
              "solidBox": "Rigid crate",
              "stackable": {
                "bis": "Stackable"
              },
              "type": {
                "bis": "Type of pallet / package",
                "title": "Pallet / parcel type"
              },
              "vrac": "Bulk (details yet unknown)",
              "weight": {
                "bis": "Weight/Unit"
              },
              "width": {
                "bis1": "W (cm)",
                "title": "Width"
              }
            }
          }
        }
      },
      "dashboard": {
        "orderPreparation": "{nbOfShippings} ongoing order preparations",
        "pickingInPreparation": "{nbOfShippings} ongoing shippings",
        "summary": {
          "customsDetails": {
            "export": "Export",
            "import": "Import",
            "importExport": "Import and Export",
            "no": "No"
          }
        }
      },
      "documents": {
        "quotationAccepted": "The quote is accepted, to upload a document please go on the associated shipment."
      },
      "proposition": {
        "awaitingAutomaticQuotation": {
          "firstLine": "Your quote is being processed!",
          "secondLine": "Our pricing experts are working on your request and will be in touch very shortly."
        },
        "multiple": {
          "decline": "Decline",
          "quotationDeclined": "Quote declined",
          "summary": {
            "decline": {
              "quotation": {
                "bette_price_placeholder": "Price in €/$",
                "betterPriceObtained": "Indicate the best obtained price",
                "betterPriceObtainedError": "Please indicate a price",
                "details": "Comments",
                "detailsError": "Please tell us why you wish to archive this quote",
                "header": {
                  "bis": "Decline quote"
                },
                "headerError": "Please select a reason.",
                "reason": "Select reason",
                "reasonLabel": "Could you tell us why?",
                "title": "We are sorry to hear that our offer does not meet your expectations."
              },
              "shipment": {
                "header": {
                  "bis": "Cancel shipment"
                },
                "title": "⚠️ Cancelling a shipment may cause surcharges, please contact your Sales Account manager for further details."
              }
            }
          }
        }
      },
      "quotationAccepted": "Quote has been accepted, please go to the corresponding shipment page.",
      "tab": {
        "cargo": "Cargo",
        "documents": "Documents",
        "orders": "Purchase Orders",
        "quotations": "Quotes",
        "summary": "Summary"
      },
      "wall": {
        "hermes": {
          "duplicateQuote": "To request another quote  with the same parameters:",
          "duplicateQuoteLinkContent": "Replicate quote",
          "quotationAccepted": "To access detail and exchange with our teams:"
        },
        "shipmentLink": "Go to the shipment"
      }
    },
    "status": {
      "accepted": "Booked",
      "archived_declined": "Declined",
      "expired": "Expired",
      "lost": "Lost",
      "new": "new quote",
      "not_answered": "Pending",
      "propositions_sent": "Available"
    },
    "table": {
      "answer": "Answer",
      "answerDeadline": "Deadline: {date}",
      "cargaison": "Cargo",
      "cargoReady": "CRD on",
      "destination": "Destination",
      "origin": "Origin",
      "quotation": "Quote",
      "selection": {
        "accepted": "Accepted",
        "active": "Active quotes",
        "all": "All quotes",
        "cancelled": "Cancelled",
        "declined": "Declined",
        "draft": "Draft quotes",
        "expired": "Expired",
        "toArchive": "To archive"
      },
      "status": "Status"
    },
    "tmsStatus": {
      "client": {
        "accepted": "Accepted",
        "awaiting_proposition": "Pending",
        "cancelled": "Cancelled",
        "declined": "Declined",
        "draft": "DRAFT",
        "expired": "Expired",
        "proposition_sent": "Available"
      },
      "freightForwarder": {
        "accepted": "Won",
        "awaiting_proposition": "To quote",
        "cancelled": "Cancelled",
        "declined": "Lost",
        "expired": "Expired",
        "proposition_sent": "Quoted"
      }
    }
  },
  "quotationTab": {
    "notAvailable": "For this quote, you don't have access to this information"
  },
  "quotations": {
    "document": {
      "title": "Quotes"
    },
    "new": {
      "displayShipmentDetails": {
        "grouping": "Grouping",
        "merchValue": "Merchandise value"
      },
      "renderDetailedContainers": {
        "containers": {}
      },
      "renderDetailedPackages": {
        "packages": {
          "merchPrecisions": "Merchandise specifications"
        }
      },
      "renderTransit": {
        "numbers": "selected schedules"
      }
    },
    "show": {
      "cargo": {
        "containers": {
          "add": {
            "shipment": {
              "row": {}
            }
          }
        }
      }
    },
    "table": {
      "CRDupcoming": "With CRD within 7 days",
      "available": "To review",
      "emptyTable": {
        "billing": "No invoice for now",
        "quotation": "No quotes for now"
      },
      "pending": "Awaiting answers"
    }
  },
  "quoteDeclined": {
    "body": "{userName} ({nameOfClient}) has declined your offer for this shipment.",
    "bodyNoProposition": "You have not submitted any propositions to {nameOfClient} for this shipment.",
    "buttonLabel": "See on OneChain",
    "reason": "Reason",
    "title": "Quote request lost for {nameOfClient}"
  },
  "quoteRequestSent": {
    "body": "{userOwnerName} ({nameOfClient}) has queried you on a new quote request.",
    "buttonLabel": "Answer on OneChain",
    "deadlineToAnswer": "Deadline to answer",
    "title": "New quote request from {nameOfClient}"
  },
  "quoteRequests": {
    "table": {
      "active": "Active requests",
      "all": "All requests",
      "awaitingClientAnswer": "Awaiting client answer",
      "cancelled": "Cancelled",
      "lost": "Lost",
      "toQuote": "To quote",
      "won": "Won"
    }
  },
  "regexValidation": {
    "containerNumber": "A container number must have the following format: 4 letters followed by 7 digits (AAAA1234567)."
  },
  "renderErrorMessage": {
    "impossible": "Can't reach the server",
    "invalidEmail": "Invalid email:",
    "invalidEmailExplanation": "the email you logged in with has no associated account. Click on logout to try another email. If you think this is an error, please contact us at antoine@ovrsea.com",
    "invalidPassword": "Invalid email or password",
    "loginFailed": "Login failed !",
    "notConfirmed": "Your account has to be verified by our team"
  },
  "reporting": {
    "addFilterSelectField": {
      "confirmFilterButton": "Add"
    },
    "carbonImpact": {
      "carbonImpactChart": {
        "periodText": "Period: from {elevenMonthAgo} {yearElevenMonthAgo} to today."
      },
      "carbonIntensity": {
        "alertSalesContact": "Please reach out to {salesOwnerName} at {salesOwnerMail} or at this phone number {salesOwnerPhone}",
        "alertText": "Our teams is at your disposal to help you reduce your carbon intensity.",
        "periodText": "Below is your average carbon intensity since the beginning of year {currentYear}."
      },
      "emited": "CO2 emitted",
      "helpCenterAboutLink": "Find out more",
      "helpCenterOffsetLink": "Offset my carbon emissions",
      "reimbursed": "Carbon offset",
      "yAxisLabel": "CO2 emitted (kg)"
    },
    "categoriesCostOverTimeChart": {
      "insuranceBarName": "Insurance",
      "postRoutingBarName": "On-carriage",
      "preRoutingBarName": "Pre-carriage"
    },
    "chart": {
      "mockup": {
        "nodata": {
          "blurr": "Insufficient data."
        }
      }
    },
    "chartMockup": {
      "nodata": {
        "subheader": {
          "blurr": "You can find here a reporting of your finished shipments."
        }
      }
    },
    "charts": {
      "costsOverTime": {
        "costsCategoriesSelection": "by category",
        "costsTotalSelection": "by freight method"
      },
      "dataOverTime": {
        "containersSelection": "containers",
        "costChart": "Costs per shipment",
        "lastTwelveMonths": "over the last 12 months",
        "palletsSelection": "pallets",
        "period": " ",
        "shipmentsSelection": "shipments",
        "till": "till",
        "today": "today"
      },
      "performances": {
        "lastTwelveMonths": "over the last 12 months",
        "period": " ",
        "till": "till"
      }
    },
    "costsCategoriesChart": {
      "costsOverTimeTooltip": {
        "monthlyTotal": "Total costs by category",
        "postRouting": "On-carriage",
        "preRouting": "Pre-carriage"
      }
    },
    "costsChart": {
      "costsOverTimeTooltip": {
        "monthlyTotal": "Total cost per freight type —"
      }
    },
    "costsOverTimeChart": {
      "XAxis": " ",
      "YAxis": "Costs (€)"
    },
    "dataOverTimeChart": {
      "airBarName": "Air",
      "oceanBarName": "Ocean",
      "railBarName": "Rail",
      "truckBarName": "Truck"
    },
    "document": {
      "title": "Reporting"
    },
    "filterBar": {
      "addFilterSelectField": {
        "filter": "Add a filter",
        "filterAttribute": {}
      },
      "displayFilters": {
        "renderHarborFilter": {},
        "renderLocationFilter": {}
      },
      "resetFiltersButton": "Reset"
    },
    "performanceChart": {
      "legend": {}
    },
    "performancesChart": {
      "barName": "transit time"
    },
    "reportingTabs": {
      "buttons": {
        "chart": "Chart",
        "compareBy": "Compare by",
        "display": "Display by",
        "month": "Month",
        "quarter": "Quarter",
        "table": "Table",
        "year": "Year"
      },
      "charts": {
        "evolutionWithDimension": "Evolution of {unit} by {compareByValue}",
        "evolutionWithoutDimension": "Evolution of {unit}"
      },
      "compareBy": {
        "carrier": "Carrier",
        "category": "Category",
        "client": "Client",
        "departureDate": "Departure date",
        "forwarder": "Forwarder",
        "freightMode": "Freight mode",
        "supplier": "Supplier",
        "traffic": "Traffic"
      },
      "dashboardChart": {
        "costsCategoriesPieLegend": {},
        "costsCategoriesPieTitle": "Total costs by category",
        "costsCategoriesPieTooltip": {
          "postRouting": "On-carriage",
          "preRouting": "Pre-carriage"
        },
        "costsTitle": "Total billed amount",
        "shipmentsPieChart": {},
        "shipmentsPieLegend": {},
        "shipmentsPieTooltip": {
          "airPlural": "Air shipments",
          "oceanPlural": "Ocean shipments",
          "railPlural": "Rail shipments",
          "truckPlural": "Truck shipments"
        }
      },
      "filter": {
        "departureDate": {
          "past-six-months": "Last 6 months",
          "past-three-months": "Last 3 months",
          "past-two-years": "Last 2 years",
          "past-year": "Last year"
        },
        "freightMethod": {
          "air": "Air",
          "ocean": "Ocean",
          "rail": "Rail",
          "truck": "Truck"
        }
      },
      "general": {
        "addAFilter": "Add a filter",
        "costs": "Costs",
        "filterValue": "Filter",
        "leadTimesPerformance": "Lead times - Performances",
        "leadTimesReliability": "Lead times - Reliability",
        "loadFactor": "Load factor",
        "loadFactors": "Load factors",
        "value": "Value",
        "volumes": "Volumes"
      },
      "units": {
        "cbm": "cbm",
        "costPerCbm": "€ per cbm",
        "costPerProduct": "€ per product",
        "costPerTeu": "€ per TEU",
        "costPerTon": "€ per ton",
        "days": "days",
        "delayAtArrival": "Delay at arrival (days)",
        "delayAtDeparture": "Delay at departure (days)",
        "medianVolume": "Median volume",
        "medianWeight": "Median weight",
        "nbOfContainers": "Nb of containers",
        "nbOfProductsShipped": "Nb of products",
        "nbOfShipments": "Nb of shipments",
        "nbOfTeus": "Nb of TEUs",
        "percentOfCosts": "% of costs",
        "percentOfProducts": "% of products",
        "percentOfShipments": "% of shipments",
        "tons": "tons",
        "totalCosts": "Total costs (€)",
        "totalVolume": "Total volume (cbm)",
        "totalWeight": "Total weight (tons)",
        "transitTime": "Transit time (days)",
        "transitTimeDoorToPort": "TT door-to-port (days)",
        "transitTimePortToDoor": "TT port-to-door (days)",
        "transitTimePortToPort": "TT port-to-port (days)"
      }
    },
    "shipmentsChart": {
      "XAxis": "Period",
      "YAxis": "Quantity",
      "dataOverTimeChart": {
        "dataOverTimeTooltip": {
          "byFreightMethod": "Total shipments per freight type -"
        }
      }
    },
    "shipmentsTab": {
      "dataOverTimeChart": {
        "airBarName": "Air",
        "containersBarName": "Containers",
        "oceanBarName": "Ocean",
        "railBarName": "Rail",
        "truckBarName": "Truck"
      }
    },
    "show": {
      "tab": {}
    }
  },
  "reportingperformanceChart": {
    "YAxis": "Transit time (days)"
  },
  "retool": {
    "page": {}
  },
  "schedules": {
    "display": {
      "summary": {
        "multi": {},
        "one": {},
        "transshipment": "Transhipments"
      }
    },
    "document": {
      "title": "Schedules"
    },
    "form": {
      "button": {
        "send": "Send"
      }
    },
    "header": {},
    "informations": {
      "carrierName": "Company",
      "destinationArrivalDate": "Arrival date",
      "estimatedTerminalCutoff": "Cut off",
      "orderChoice": "Choice n°",
      "originDepartureDate": "Departure date"
    },
    "no": {
      "way": "No route available"
    },
    "search": {
      "way": "Search a route"
    },
    "searchInProgress": "Data collection in progress",
    "table": {
      "companies": "Companies",
      "cutOff": "Cut off",
      "transit": {},
      "vessel": "Vessel"
    }
  },
  "settings": {
    "billingInformations": {
      "billingEmailAddresses": {
        "button": "Add billing email",
        "display": "Billing emails",
        "field": "Billing emails"
      }
    },
    "companyTab": {
      "billingInformations": {
        "billingAddress": "Billing address",
        "billingAddressCountry": "Country for Billing",
        "header": "Invoice information",
        "paymentTerms": {
          "bis": "Payment terms"
        },
        "siret": "SIRET number",
        "tva": "VAT number"
      },
      "generalInformations": {}
    },
    "document": {
      "title": "Settings"
    },
    "editBilling": {
      "billingAddress": "Incomplete address",
      "billingAddressCountry": "Country",
      "days": "days",
      "paymentTerms": {
        "bis": "Payment terms"
      },
      "tva": "VAT number"
    },
    "editContact": {
      "email": "E-mail address",
      "name": "Complete Name",
      "phone": "Phone",
      "siret": "SIRET #"
    },
    "notifications": {
      "footer": {
        "action": {
          "description": "You get notified by email when we need you to provide or validate information or documents on your shipments.\n\nEmail notifications for actions to perform cannot be turned off to ensure our best quality of service on your shipments.",
          "title": "Actions"
        },
        "message": {
          "description": "You get notified by email when we, your colleagues, and partners who use the platform send messages on shipments you are following.\n\nEmail notifications for new messages cannot be turned off to ensure smooth communication and coordination on your shipments.",
          "title": "Messages"
        },
        "subscribe": {
          "description": "You can choose to get notified for the shipments you create and manually subscribe to, or all the shipments of your company.",
          "title": "Shipments you get notified for"
        }
      },
      "form": {
        "caption": "You get notified of updates and activities on shipments you are following.\n\nYou can choose which email notifications you would like to receive so that you only get notified about the information that matters to you.",
        "description": {
          "billing_available": "A new invoice is available",
          "delivery_planned": "The delivery date is confirmed",
          "documents_uploaded": "New documents are available",
          "eta_updated": "The ETA of a shipment changes",
          "eta_updated_summary": "The ETA of a shipment changes (Bi-weekly summary)",
          "new_ai_commercial_invoice_extract": "My products are detected in a shipment ",
          "quotation_created": "My quote request has been sent",
          "shipment_arrived": "The cargo is delivered",
          "shipment_booked": "A booking is made",
          "shipment_delayed": "A shipment is delayed",
          "shipment_shared": "A shipment is shared with me"
        },
        "subscription": {
          "all_shipments": "All shipments",
          "followed_shipments": "Shipments I create and subscribe to"
        },
        "subtitle": "I want to receive an e-mail when…",
        "title": "Information"
      },
      "header": {
        "subtitle": "Email notifications",
        "title": "Notification settings"
      }
    },
    "users": {
      "form": {
        "name": {},
        "phone": {},
        "role": {},
        "success": {
          "lineTwo": {}
        },
        "title": {}
      },
      "roles": {
        "helpButtonPopup": "More information about the roles"
      }
    },
    "usersManagementTab": {
      "helpModal": {
        "addOwner": "If you want to change / delete / add an account owner, contact your OVRSEA sales representative.",
        "admin": "The admin has the same rights as the owner but adding or removing admin is free and does not require going through OVRSEA support.",
        "manager": "The manager can act on all entities associated with the account, but does not have access to user rights management or company information.",
        "member": "The member can only act on the entities he has created himself.",
        "owner": "The owner of the account can act on all the entities (quote, shipment, location ...) associated with this account. He has access to the 'Settings' menu allowing him to modify the information of the company and to manage the role of the users.",
        "title": "Role description",
        "viewer": "The viewer can see all entities in the account but can not perform any actions on the platform."
      },
      "removeUser": {
        "button": "Revoke access",
        "modalBodyOne": "Would you like to remove the user {userFullName} from OneChain?",
        "modalBodyTwo": "{userFullName} won’t be able to access OneChain anymore.",
        "modalTitle": "Remove a user"
      },
      "status": {
        "active": "Active",
        "blocked": "Blocked",
        "invitationPending": "Invited"
      },
      "table": {
        "clients": "Clients",
        "email": "Email",
        "name": "Name",
        "phone": "Phone number",
        "role": "Role",
        "roles": "Roles",
        "status": "Status"
      },
      "title": "User management"
    }
  },
  "shareTrackingModal": {
    "comment": "Help your colleagues and partners know the status of the shipment by sharing this public tracking link.",
    "cta": "Share the tracking link",
    "email": {
      "body1": "The real-time tracking of our shipment {shipmentName} is available on OneChain.",
      "body2": "You can access it using this link: {trackingLink}",
      "comment": "Send the link of the shipment tracking page by email",
      "cta": "Send by email",
      "heading": "Share by email",
      "object": "The real-time tracking of our shipment {shipmentName} is available"
    },
    "heading": "Share your shipment public tracking link",
    "link": {
      "comment": "Copy the shipment tracking page link",
      "cta": "Copy link",
      "heading": "Copy the link"
    },
    "user": {
      "comment": "Send an invitation to a colleague to join OVRSEA",
      "cta": "Add a user",
      "heading": "Invite a colleague"
    }
  },
  "shipment": {
    "details": {
      "arrivalCustoms": "Arrival customs fees",
      "arrivalFees": "Arrival fees",
      "arrivalLogistics": "Arrival logistics",
      "blNumber": "BL number",
      "bookingNumber": "Booking number",
      "carbonOffset": "Carbon offset",
      "cargoValuePlaceholder": "Insured cargo value",
      "cfsEnclosure": "CFS enclosure",
      "customs": "Customs",
      "datesInformation": "Dates information",
      "deliveryTargetDate": "Delivery target date",
      "departureCustoms": "Departure customs fees",
      "departureDate": "Desired departure date",
      "departureDates": "Desired departure dates",
      "departureFees": "Departure fees",
      "departureLogistics": "Departure logistics",
      "flightNumber": "Flight number",
      "freight": "Freight",
      "freightMethodDoorToPort": "Transport mode",
      "freightMethodPortToDoor": "Transport mode",
      "general": "General",
      "header": "Edit information",
      "hle": "LAT",
      "information": "Information",
      "insurance": "Insurance",
      "lithium": "Battery conditioning",
      "loadingReference": "Loading reference",
      "ltaNumber": "LTA number",
      "memo": "Comment",
      "onCarriage": "On-Carriage",
      "originalBls": "Original BLs",
      "other": "Other",
      "otherServices": "Other services",
      "pickupTargetDate": "Cargo ready date",
      "preCarriage": "Pre-Carriage",
      "refrigeratedDetails": "Temperature interval",
      "securing": "Securing",
      "shipownerAir": "Airline",
      "shipownerDefault": "Company",
      "shipownerOceanFcl": "Carrier",
      "shipownerOceanLcl": "Consolidator",
      "shipownerRail": "Company",
      "shipownerTruck": "Trucking company",
      "trainNumber": "Train number",
      "unloadingReference": "Unloading reference",
      "vesselName": "Vessel name",
      "voyageNumber": "Voyage number",
      "without": "Without"
    },
    "document": {
      "title": "Shipment"
    },
    "new": {
      "first": {
        "flowDisplay": {
          "deliveryHarbor": {
            "name": "Destination harbor :"
          },
          "deliveryLocation": {},
          "pickupHarbor": {
            "name": "Origin harbor :"
          },
          "pickupLocation": {}
        },
        "flowSelection": {}
      },
      "fist": {
        "error": {}
      },
      "second": {
        "destinationDate": {},
        "error": {},
        "loadingReference": {},
        "originDate": {},
        "schedules": {}
      }
    },
    "renderStatus": {
      "awaiting_booking": {
        "bis": "Pending"
      },
      "awaiting_client_confirmation": "Pending",
      "booked": {
        "bis": "Booked"
      },
      "booking_request": "To confirm",
      "cancelled": "Cancelled",
      "finished": "Finished",
      "in_progress": "In progress",
      "late": "Late",
    },
    "sharing": {
      "canSeeInvoices": "Financials visible",
      "cannotSeeInvoices": "Financials hidden",
      "header": "Subscribers",
      "myself": "Myself"
    },
    "show": {
      "billings": {
        "issuer": "Issuer",
        "noDate": "No invoices for now",
        "recipient": "Recipient",
        "uploadInvoiceButton": "Upload an invoice"
      },
      "cargo": {
        "containers": {
          "add": {
            "shipment": {
              "error": {
                "cargaison": "Choose a least one container",
                "loadingRef": "Required field"
              }
            }
          }
        }
      },
      "dashboard": {
        "error": "Error while retrieving the shipment milestones",
        "tracking": {
          "noData": "No tracking data available for now"
        },
        "trackingTab": "Tracking tab",
        "transcript": {
          "hide": "Hide",
          "view": "View the transcript",
        },
      },
      "tab": {
        "billing": "Invoice",
        "cargo": "Cargo",
        "documents": "Documents",
        "orders": "Purchase Orders",
        "products": "Products",
        "quotations": "Quotes",
        "summary": "Dashboard"
      },
      "tracking": "Tracking"
    },
    "table": {
      "cargo": "Cargo",
      "cargoReadyDate": "Cargo Ready Date",
      "client": "Client",
      "desiredArrivalDate": "Target delivery",
      "destination": "Destination",
      "eta": "ETA",
      "etd": "ETD",
      "filterColumns": "Show and hide columns",
      "freightForwarder": "By",
      "freightForwarderPlaceholder": "Forwarder",
      "freightMode": "Transport mode",
      "freightModeFilterValues": {
        "air": "Air",
        "oceanFcl": "Ocean FCL",
        "oceanLcl": "Ocean LCL",
        "railFcl": "Rail FCL",
        "railLcl": "Rail LCL",
        "truck": "Road"
      },
      "ops": "Ops",
      "origin": "Origin",
      "parties": "Third parties",
      "searchPlaceholder": "Search by shipment, name, origin, destination",
      "select": {
        "activeShipments": "On-going shipments",
        "all": "All shipments",
        "cancelledShipments": "Cancelled shipments",
        "finishedShipments": "Finished shipments"
      },
      "selectPartiesInvolved": "Select parties involved",
      "shipment": "Shipment",
      "status": "Status",
      "statusFilters": {
        "forClient": {
          "awaiting_booking": "Awaiting Booking",
          "booked": "Booked",
          "cancelled": "Cancelled",
          "finished": "Finished",
          "in_progress": "In transit"
        },
        "forForwarder": {
          "awaiting_booking": "To book",
          "booked": "Booked",
          "cancelled": "Cancelled",
          "finished": "Finished",
          "in_progress": "In transit"
        }
      }
    },
    "tracking": {}
  },
  "shipmentArrived": {
    "buttonText": "Show on OVRSEA",
    "mainText": "We are pleased to inform you that cargo has been delivered on {deliveryDate} at {locationName}.",
    "regardText": "Have a pleasant day,",
    "teamText": "The OVRSEA team",
    "title": "Goods delivered on {deliveryDate}"
  },
  "shipmentAssigned": {
    "body": "{userOwnerName} ({nameOfClient}) has entrusted you with a new shipment. You can now proceed to the booking of this shipment.",
    "bodyfromPropositionAccepted": "{userOwnerName} ({nameOfClient}) has accepted your offer for this shipment. You can now proceed to book the shipment.",
    "buttonLabel": "See on OneChain",
    "title": "New shipment entrusted by {nameOfClient}"
  },
  "shipmentProducts": {
    "table": {
      "alerts": {
        "noCommercialInvoice": {
          "message": "Get started by uploading your commercial invoice below to run our algorithm to find your products.",
          "title": "Find your products contained in this shipment with AI!"
        },
        "processingFailed": {
          "message": "Unfortunately, we could not find the products available on this shipment this time. Our Product team is currently working to fix this issue, don’t hesitate to reach out to us if necessary.",
          "title": "No products found on this shipment!"
        },
        "processingStarted": {
          "message": "Your products will soon be displayed on this page, it should not take more than 10 minutes.",
          "title": "Finding your products contained in this shipment!"
        }
      },
      "description": "Description",
      "dropzone": {
        "buttonText": "Upload file",
        "description": "Drag and Drop here or choose file to run the algorithm",
        "title": "Commercial Invoice"
      },
      "hsCode": "HS Code",
      "inaccurateDetails": {
        "additionalComment": "Additional comment",
        "additionalCommentPlaceholder": "Example : SKU & Description are swapped",
        "description": "Description",
        "hsCode": "HS Code",
        "incurrateColumnsQuestionTitle": "Please tell us which columns have an error",
        "modalTitle": "Report an error",
        "other": "Other",
        "quantity": "Quantity",
        "require": "This field is required ",
        "sku": "Product reference (SKU)",
        "unitPrice": "Unit price"
      },
      "name": "Name",
      "quantity": "Quantity",
      "sku": "Product reference (SKU)",
      "unitPrice": "Unit price",
      "unknownQuantity": "Unknown quantity"
    }
  },
  "shipmentTab": {
    "notAvailable": "For this shipment, you don't have access to this information"
  },
  "shipmentToInvoice": {
    "body": "This shipment is finished. Please upload the corresponding invoice.",
    "buttonLabel": "Upload on OneChain",
    "title": "Shipment to invoice"
  },
  "shipments": {
    "document": {
      "title": "Shipments"
    },
    "table": {
      "emptyTable": {
        "shipment": "No shipments for now"
      },
      "menuItem": {
        "addForwarder": "Add forwarders",
        "cancel": "Cancel",
        "uncancel": "Re-activate"
      }
    }
  },
  "shipowners": {
    "contacts": {
      "contactInfoCopied": {},
      "contactInfoCopy": {}
    },
    "document": {
      "title": "Directory"
    }
  },
  "show": {
    "location": {
      "not": {
        "found": "Location not found"
      }
    }
  },
  "smartTable": {
    "paginationHint": "Showing <number>{start}</number> to <number>{end}</number> of <number>{total}</number> results",
    "search": {
      "hint": "Search"
    }
  },
  "solidBox": {
    "plural": "Rigid crates",
    "singular": "Rigid crate"
  },
  "submitVgm": {
    "regards": "Thank you in advance,"
  },
  "titles": {
    "about": "About",
    "accept_invitation": "OVRSEA",
    "account-settings": "Settings",
    "api-integration": "API integrations",
    "billing": "Billings",
    "booking": "Booking information",
    "booking-request": "Booking request",
    "clients": "Clients",
    "co2-dashboard": "Carbon footprint",
    "contracted-traffic": "Traffic",
    "customs": "Customs",
    "dashboard": "Dashboard",
    "default": "OVRSEA",
    "developer": "Dev",
    "documents": "Documents",
    "harborsSettings": "Ports - Airports",
    "login": "Login",
    "negotiated-offers": "Negotiated offers",
    "network": "Network",
    "new-shipments": "Shipments",
    "notifications-settings": "Notification Settings",
    "orderPreparation": "Order preparation request",
    "orderPreparations": "Order preparations",
    "orders": "Purchase Orders",
    "pdf": "PDF View",
    "picking": "Shipping",
    "planning": "Planning",
    "pricing": "Pricing Dashboard",
    "products": "Products",
    "profile": "My profile",
    "quotation": "Quote",
    "quotations": "Quotes",
    "quote-requests": "Quote Requests",
    "reporting": "Reporting",
    "reset_password": "Reset password",
    "retool": "Retool",
    "schedules": "Schedules",
    "shipment": "Shipment",
    "shipments": "Shipments",
    "shipowners": "Directory",
    "termsOfUse": "Terms of Use",
    "tracking": "Tracking",
    "trackingOrder": "New shipment to track",
    "voyager": "Voyager",
    "workflows": "Workflow"
  },
  "tms": {
    "addForwarders": {
      "addToFile": "Add to file",
      "forwardersQueried": "Forwarders already queried",
      "modalBody": "Select the additional forwarders you’d like to query",
      "modalHeader": "Add a forwarder",
      "modalSubtitle": "Would you like to query additional forwarders for this shipment?"
    },
    "cancelFile": {
      "cancelFile": "Cancel file",
      "cancelledFile": "Cancelled file",
      "comments": "Comments",
      "dontCancel": "Don't cancel",
      "modalBody": "Stakeholders involved in this file will be notified by email and will have access to the reason and comments.",
      "modalSubtitle": "Would you like to cancel this file?",
      "reason": "Reason"
    },
    "completeRfqDraft": {
      "cardBody": "This quote request is still in a draft state. Please fill missing information and send it to your forwarders.",
      "cardButton": "Complete and submit",
      "cardTitle": "Complete and submit your draft RFQ"
    },
    "declineSingleProposition": {
      "comments": "Comments",
      "declineProposition": "Decline proposition",
      "declinedBy": "by {userName} ({companyName})",
      "modalBody": "{forwarderName} will be notified by email and will have access to the reason and comments.",
      "modalSubtitle": "Would you like to decline this proposition from {forwarderName}?",
      "modalSubtitleBis": "Would you like to decline this proposition?",
      "modalTitle": "Decline proposition",
      "reason": "Reason"
    },
    "newShipment": {
      "applicableNegotiatedOffers": "The negotiated offers contractualised with your freight forwarders",
      "bookingInformation": {
        "alertDescription": "You will be able to update this information if you change booking for this shipment",
        "alertTitle": "Information editable at a later date",
        "bookingInformation": "Booking information",
        "cardBody": "{clientName} has entrusted you with this new shipment. Once the booking is made, please provide the booking information.",
        "cardButton": "Provide booking information",
        "cardTitle": "New shipment entrusted by {clientName}",
        "emailBody": "{forwarderName} has booked your shipment with {shipownerName}",
        "emailButton": "See on OneChain",
        "emailFooter": "Help your colleagues and partners know the status of the shipment by sharing this",
        "emailFooterLinkText": "public tracking link",
        "emailObject": "Shipment booked - {foreseaName} - {clientShipmentName}",
        "emailTitle": "Your shipment has been booked",
        "shipmentBooked": "{forwarderName} has booked the shipment with {shipownerName}",
        "updateBooking": "Update booking",
        "updateBookingInformation": "Update booking information"
      },
      "insurance": {
        "buttonsTitle": "Would you like to subscribe to Ad Valorem insurance through your freight forwarder?",
        "dontSubscribe": "Do not subscribe",
        "merchandiseValue": "Merchandise value",
        "subscribe": "Subscribe for this shipment"
      },
      "tracking": {
        "airShipowner": "Airline",
        "bl": "Bill of Lading",
        "booking": "Booking",
        "container": "Container Number",
        "estimatedDeliveryDate": "Estimated delivery date",
        "estimatedPickupDate": "Estimated pickup date",
        "freightSectionTitle": "Freight Information",
        "loadType": "Load Type",
        "mawb": "Master Airway Bill",
        "number": "Number",
        "oceanShipowner": "Carrier",
        "reference": "Reference",
        "service": "Service",
        "trackingSectionTitle": "Tracking Information",
        "transportMode": "Transport mode",
        "transporter": "Transporter",
        "transporterPlaceholder": "Name of the selected transporter",
      },
      "truckService": {
        "courier": "Courier",
        "ftl": "Dedicated Truck Chartering (FTL)",
        "ltl": "Groupage Chartering (LTL)",
      },
      "truckServiceShortLabel": {
        "courier": "Courier",
        "ftl": "FTL",
        "irrelevant": "N/A",
        "ltl": "LTL",
        "unknown": "Unknown"
      }
    },
    "propositionComparison": {
      "avoidedEmissions": "Avoided emissions",
      "carbonFootprint": "Carbon footprint",
      "carbonIntensity": "Carbon intensity",
      "carrierAir": "Airline",
      "carrierOceanFcl": "Carrier",
      "carrierOceanLcl": "Consolidator",
      "carrierTruck": "Transporter",
      "co2eEmissions": "CO2e emissions",
      "compareOffers": "Compare offers",
      "compareReceivedOffers": "Compare received offers",
      "freightRate": "Freight rate",
      "hideDetails": "Hide details",
      "leadTime": "Lead time",
      "mode": "Mode",
      "nextDeparture": "Next departure",
      "price": "Price",
      "route": "Route",
      "seeDetails": "See details",
      "sortBy": "Sort by",
      "timeToAnswer": "Time to answer",
      "totalPrice": "Total price",
      "transitTime": "Transit time"
    },
    "quoteRequests": {
      "addAMessage": "Add a message",
      "addPrice": "Add a price",
      "answerDeadlineWithDate": "Deadline to answer: {answerDeadline}",
      "availableOffers": "Available offers",
      "awaitingAnswers": "Awaiting answer",
      "createPropositionFrom": {
        "choose": "How would you like to share your quote?",
        "heading": "Submit a proposition",
        "manually": "Manually",
        "manuallyDescription": "You’ll be able to specify manually the quoted costs.",
        "pdf": " Via a PDF file",
        "pdfDescription": "Zach, our AI assistant, will automatically read your quote."
      },
      "differenceOrigin": "Delta may be caused by the difference between the exchange rate on your offer and the one taken into account",
      "dragPrices": "Please classify these prices by dragging them into the correct categories.",
      "duplicate": "Duplicate",
      "expiredOffers": "Expired offers",
      "fillInformation": "Fill the information",
      "messagePlaceholder": "Add a message to your proposition",
      "mode": "Mode",
      "newQuoteRequest": "New quote request from {clientName}",
      "newQuoteRequestBody": "{userFullName} ({clientName}) has queried you on a new quote request.",
      "newQuoteRequestButton": "Submit a proposition",
      "nextDeparture": "Next departure",
      "nextDepartureCaption": "Important information for clients choice",
      "offers": "{numberOfOffers, plural, one{offre} other{offres}}",
      "optional": "optional",
      "queriedForwarders": "{nbOfQueriedForwarders} queried forwarders",
      "quoteReference": "Quote reference",
      "quotedPrices": "Quoted prices",
      "receivedAnswersPlural": "{nbOfForwardersWhoAnswered} / {nbOfQueriedForwarders} received answers",
      "receivedAnswersShortPlural": "{nbOfForwardersWhoAnswered} / {nbOfQueriedForwarders} answers",
      "receivedAnswersShortSingular": "{nbOfForwardersWhoAnswered} / {nbOfQueriedForwarders} answer",
      "receivedAnswersSingular": "{nbOfForwardersWhoAnswered} / {nbOfQueriedForwarders} received answer",
      "route": "Route",
      "submitProposition": "Submit proposition",
      "toClassify": "To classify",
      "truckService": "Service",
      "update": "Update",
      "upload": {
        "button": "Upload",
        "description": "Drag and Drop here or choose file",
        "file": "PDF file",
        "proposition": "Quote",
        "title": "Attach a file"
      },
      "validateInformation": "Validate information",
      "validityDate": "Validity date",
      "zach": {
        "filled": {
          "fields": "Zach has pre-filled the following fields.",
          "verify": "Please check and complete them."
        },
        "fullyExtracted": "Zach has extracted the prices from your offer. Please correct and reclassify prices if necessary.",
        "hasExtracted": "Extraction finished!",
        "heading": "Zach, our IA assistant, automatically reads your quotes.",
        "new": "NEW",
        "partiallyExtracted": "Zach has extracted a part of prices from your offer.",
        "processing": "Zach is extracting the information from your offer.",
        "totalDifferenceInPercent": "{totalDifferenceInPercent} % difference",
        "totalExtracted": "Total extracted from your file",
        "totalOfPriceLines": "Sum of extracted prices",
        "totalsMatch": {
          "title": "The total of your file is equal to the sum of the extracted prices."
        },
        "totalsNotMatching": {
          "description": "Please correct and reclassify the prices if necessary.",
          "title": "The total of your file does not equal the sum of the extracted prices."
        },
        "willExtract": "Once uploaded, Zach will extract the information and prices from your offer."
      }
    },
    "shareShipment": {
      "modalBody": "They will be notified that the shipment has been shared to them and will have access to the messages and documents starting from the launch of the shipment.",
      "modalSubtitle": "Select the party to who you want to share this shipment",
      "shareShipment": "Share shipment"
    },
    "termsOfUse": {
      "button": "Go to OneChain",
      "checkboxLabel": "I have read and accept the Terms of Use of OneChain",
      "footer": "By checking the box 'I have read and accept the general terms of use', the User acknowledges that this constitutes an electronic signature and agrees to be bound by all the provisions of the Terms of Use.",
      "header": "Terms of Use of OneChain",
      "termsOfUseMarkdown": `## Terms of Use of OneChain

*Last updated: 04/02/2024*

### 1. Preamble

OneChain has developed and operates centralized management software for overseeing transportation operations, accessible online through a software-as-a-service distribution model (hereinafter referred to as the "Solution").

The Solution is published by Foresea Technologies, a simplified joint-stock company with a capital of 13,862 euros, registered with the Paris Trade and Companies Register under number 832 541 189, and whose registered office is located at 23, rue Nollet - 75017 Paris (hereinafter "OneChain").

These terms of use (hereinafter "ToU") aim to set the conditions for the use of OneChain. The ToU govern the use of the Solution and do not constitute general terms of sale or subscription conditions.

### 2. Definitions

Within the context of the ToU, the terms below have the following meanings:

"Client" refers to a legal entity that has entered into a Contract with OneChain for access to the Solution.
- "User Content" refers to information, documents, and, more generally, all data transmitted by the User to OneChain via the Solution.
- "Contract" refers to the software and SaaS service agreement concluded between OneChain and the Client, allowing the Client to benefit from the Services provided by OneChain via the Solution.
- "User Space" refers to the personal space of the User, accessible via the Solution with a username and password, within which they can manage their personal information and transmit User Content.
- "Services" refers to the services provided by OneChain to the Client as part of the execution of the Contract.
- "Solution" refers to the software solution developed by OneChain and accessible online at https://app.onechain-tms.com/.
- "User" refers to an individual or legal entity designated and approved by the Client who has access to the Solution.

### 3. Effective Date and Duration

The ToU are applicable for as long as the User Space exists and for a period of five (5) years from the date of its deletion.

### 4. Acceptance, Enforceability, and Modification of the ToU

#### 4.1 Acceptance and Enforceability of the ToU

Access to the OneChain Solution implies acceptance of the ToU. The User declares having obtained all necessary information regarding the Services offered by the Solution and adheres to the ToU without reservation. Acceptance of the ToU is formalized by clicking on the "Accept" button by the User upon first use of the Solution. This click constitutes proof that the User has read and accepted the ToU.

The ToU are enforceable to the User from their acceptance, even before the first use of the Solution.

#### 4.2 Modification of the ToU

The ToU may be modified at any time by OneChain, in order to take into account the evolution of the Solution.

The User is informed by email of any changes to the ToU. Any use of the Solution after modification of the ToU and the correlated information to the Users constitutes acceptance by the User of the updated version of the ToU.

The ToU available via the Solution prevail over any previous versions. Users can access prior versions of the ToU upon request via email to their OneChain contact.

### 5. Access to the Solution

#### 5.1 Creation of the User Space

The User receives an invitation email to create their User Space and set the password to access it.

Creating the User Space requires the User to fill out a registration form. On this occasion, the User undertakes to provide accurate and complete information.

#### 5.2 Username and Password

Usernames and passwords are intended to restrict access to the Solution for Users, to protect the integrity and availability of the Solution, as well as the integrity, availability, and confidentiality of User Content. No operation can be carried out on the Solution by a User without their username and password.

The User's username and password are strictly personal and confidential. The User is solely responsible for maintaining their confidentiality. Any use of the Solution from the username and password assigned to the User is presumed to originate exclusively from the User. The User is required to fully compensate for all adverse consequences resulting from the disclosure of their username and/or password.

The User agrees to notify OneChain as soon as possible of any fraudulent use of their User Space, username, and/or password.

#### 5.3 Access and Availability of the Solution

The User can connect to the Solution at any time, except during maintenance periods. OneChain cannot be held responsible for access discontinuities to the Solution and its services, whether intentional or not.

OneChain reserves the right, without notice or compensation, to:

- Suspend or temporarily or permanently close all or part of the Solution for updates or any other maintenance operations,
- Make any modifications and improvements to the Solution that OneChain deems necessary or useful. It is the User's responsibility to ensure that their computer and transmission means can adapt to the changes proposed by OneChain.

Access to the Solution is from the User's computer, which is responsible for the security of individual access points to the Solution. If they become aware of another person accessing it, they must inform OneChain immediately by any means.

To access the Solution, the User must have Internet access. The User acknowledges being aware of the technical risks inherent to the Internet, such as connection failures they may cause, and the importance of having a quality Internet service provider and a suitable connection for using the Solution.

### 6. User Obligations

The User agrees to:
- Use the Solution fairly, in compliance with the ToU and applicable laws and regulations,
- Communicate all necessary information and documents via the Solution for the Client's use of the Solution in accordance with its purpose. The User informs OneChain of any changes in their situation that may affect the provision of Services,
- Not disrupt the use of the Solution by other Users, not access third-party User Spaces, and not access parts of the Solution with restricted access,
- Not delete or modify data on the Solution not published by themselves or fraudulently introduce data or alter the Solution's functioning. In particular, they ensure not to introduce viruses, malicious code, or any other harmful technology to the Solution,
- Take appropriate measures to ensure the security of their data and/or software against potential virus contamination on the Internet, and
- Immediately inform OneChain of any Solution failures they become aware of via email at contact@onechain-tms.com.

### 7. Suspension and Exclusion

OneChain reserves the right, without notice or compensation, to suspend or temporarily or permanently close all or part of the Solution or the User's access to the Solution, particularly if the User:

- Compromises or attempts to compromise the quality of Services or the security of a third party,
- Behaves in a way that affects the integrity of OneChain's or its providers' and/or subcontractors' services or IT systems.

The User is informed that in the event of a breach of the ToU, OneChain reserves the right, without compensation, eight (8) days after sending an email to the User requesting compliance with the ToU, to suspend access to the Solution until the cause of the suspension has disappeared.

In case of repeated breaches of the ToU, OneChain reserves the right, without compensation, eight (8) days after sending an email to the User requesting compliance with the ToU remaining unheeded, to terminate access to their User Space or prohibit access to all or part of the Solution, without prejudice to any common law actions that may be open to it.

### 8. Deletion of the User Space

In the event of the deletion of the User Space resulting from the termination of contractual relations between OneChain and its Client for any reason whatsoever, or an express request from the Client for any reason whatsoever, OneChain will, at the request of the User, return all User Content. Similarly, it will return all history and backups in its possession.

### 9. Liability

OneChain's cannot be held liable due to or in the event of:

- The quality of the Solution, which is offered "as is,"
- Disruption of the use of the Solution or inability to access the Solution for any reason whatsoever,
- Content disseminated by the User or use of the Solution contrary to the law or applicable regulations or the ToU,
- Indirect damage, including loss of profit, data, or any other intangible loss, resulting from the use of the Solution or inability to use it,
Modification of all or part of the Solution not carried out by OneChain,
- Use of the Solution in connection with third-party programs or data not expressly endorsed by OneChain,
- Damage suffered by the User due to a third party and/or a case of force majeure,
- Inability to access the Solution due to hardware destruction, hacking, hosting server unavailability, or Internet access issues,
- Inability for the User to access their User Space due to suspension or deletion of their User Space under the conditions described in articles 7 and 8 of the ToU above,
- IT security breaches causing damage to the User's computer equipment and their data.

### 10. Personal Data

If the transmissions for the purposes of using the Solution involve Personal Data, the User guarantees to OneChain that they have fulfilled all obligations incumbent upon them under the European General Data Protection Regulation 2016/679 and French law n° 78-17 of January 6, 1978, as amended (the "Applicable Data Protection Laws").

The Parties commit to implementing appropriate technical and organizational measures to ensure compliance with the Applicable Data Protection Laws.

For the purposes hereof, the terms "Data Subjects," "Personal Data," "Processing," "Processor," "Data Protection Impact Assessment," and "Personal Data Breach" shall have the meaning given to them in the Applicable Data Protection Laws.

The Parties acknowledge that, regarding the Processing of Personal Data carried out under this Agreement, OneChain acts as a Processor. The User alone determines the User Content they disclose to OneChain in this context, and thus OneChain cannot be held responsible for the number or type of User Content it accesses and processes on behalf of the User, nor for the legality of such disclosure of User Content as such.

In this context, the processing of Personal Data carried out by OneChain is described below:
- Nature of the processing: collection, recording, organization, structuring, storage.
- Purpose of Processing: provision of Services.
- Categories of Data Subjects: User, Client, recipients of the Client's goods.
- Categories of Personal Data: surname, first name, email address, postal address, User usernames and passwords, Client username and passwords, and any Personal Data included by the User in the User Content.
- Duration of the processing operations: duration of the contractual relationship between OneChain and the Client.

OneChain commits to:
- limit access to Personal Data to its personnel members bound by a confidentiality obligation;
- implement relevant technical and organizational measures to comply with the Applicable Data Protection Laws and, particularly, to protect the User’s Personal Data against any accidental or unlawful destruction, loss, alteration, disclosure, or unauthorized access;
- promptly inform the User of any Personal Data breach as soon as it becomes aware of it and provide the User with information about the Personal Data breach;
- respond promptly and to the fullest extent possible to any User request concerning the Personal Data processed to enable them to respond to any request made by Data Subjects aiming to exercise their rights;
- return or delete, at the User’s choice, all their Personal Data at the end of the Services provided under the Agreement and/or upon the deletion of their User Space, unless the Applicable Data Protection Laws require OneChain to retain such Data;
- in case of transfer of the User’s Personal Data outside the European Economic Area, put in place all necessary safeguards to ensure the protection of such Data. The User hereby agrees that OneChain and/or its sub-processors, where applicable, may make such transfers.

If the User has any questions regarding Personal Data, they may contact OneChain's team at the following address: personaldata@onechain-tms.com.

### 11. Confidentiality

The User Content is and remains the property of the User. OneChain undertakes to keep this data confidential, not to make any copy of such data, other than for technical and backup purposes, and not to use it for any purpose other than statistical purposes, for improving the Solution, or for the purposes other than those stipulated for the execution of the Agreement, nor any commercial exploitation.

In particular, OneChain undertakes not to use the User Content for any project and/or service outside the strict scope of the Services, nor disclose it to a third party without obtaining the prior written consent of the User.

Notwithstanding the above, it is specified that OneChain is authorized to disclose the User Content to its personnel dedicated to the development and operation of the Solution and bound by a confidentiality agreement (including employees, interns, and freelancers, etc.) and to its sub-processors on a need-to-know basis strictly for the execution of the Agreement, provided they are bound by a confidentiality agreement.

Conversely, the Client commits to maintaining complete confidentiality over the documents, data, or nature of the Services provided by OneChain via the Solution.

### 12. Intellectual Property

The content of the Solution, the general structure as well as the software, texts, images (animated or not), photographs, know-how, and all other elements composing the Solution are the exclusive property of OneChain.

OneChain's distinctive signs, such as domain names, trademarks, names, and logos appearing on the Solution, are protected by the Intellectual Property Code. Any total or partial reproduction of these distinctive signs made from elements of the Solution without the express authorization of OneChain is therefore prohibited, within the meaning of the French Intellectual Property Code. The same applies to the databases appearing on the Solution.

The ToU do not imply any transfer of any kind of intellectual property rights on the elements belonging to OneChain or its beneficiaries such as sounds, photographs, images, literary texts, software, trademarks, graphic charters, logos to the benefit of the User.

Consequently, the User is prohibited from:
- modifying, copying, reproducing, downloading, distributing, transmitting, commercially exploiting and/or distributing in any way the Services, the pages of the Solution, or the computer codes of the elements composing the Services and the Solution,
- any reproduction and/or representation, total or partial, of any of these rights, without the express authorization of OneChain, and
- any action or conduct that could directly or indirectly infringe the intellectual property rights of OneChain.

### 13. Interpretation and Independence

In case of difficulties in interpretation resulting from a contradiction between any of the titles appearing at the top of the clauses and any of the clauses, the titles will be declared non-existent.

If one or more stipulations of the ToU are held to be invalid or declared as such pursuant to a law, a regulation or following a final decision of a competent court, the other stipulations will retain their full force and scope.

### 14. Applicable Law and Jurisdiction

The ToU are governed by French law. This applies to substantive rules and procedural rules, notwithstanding the places of performance of substantive or ancillary obligations.

In the event of a dispute, the User shall first submit a claim to OneChain at the address of its registered office mentioned at the beginning hereof, to seek an amicable solution.

Failing an amicable solution, any dispute concerning all contractual or extra-contractual responsibilities falls under the jurisdiction of the courts within the jurisdiction of the Paris Commercial Court, notwithstanding multiple defendants or third-party claims.
`
    },
    "uploadInvoice": {
      "addCost": "Add cost",
      "billedCosts": "Billed costs",
      "billingReference": "Billing ref.",
      "cardBody": "This shipment is finished. Please upload the corresponding invoice.",
      "cardTitle": "Shipment to invoice",
      "currency": "Currency",
      "dueDate": "Due date",
      "editDetails": "Edit invoice details",
      "errors": {
        "billingReference": "Billing reference is required",
        "category": "Category is required",
        "currency": "Currency is required",
        "dueDate": "Due date is required",
        "exchangeRate": "Exchange rate is required",
        "exchangeRatePositive": "Exchange rate must be greater than 0",
        "invoiceNumber": "Invoice number is required",
        "issueDate": "Issue date is required",
        "issuer": "Issuer is required",
        "price": "Price is required",
        "prices": "At least one price is required",
        "pricesCategories": "All prices must be classified",
        "recipient": "Recipient is required"
      },
      "exchangeRate": "Exchange rate EUR to USD",
      "file": "File",
      "fuelSurcharge": "Fuel surcharge rate (%)",
      "invoiceNumber": "Invoice nº",
      "issueDate": "Issue date",
      "issuer": "Issuer",
      "label": "Label",
      "provideInvoiceInfo": "Provide invoice information",
      "recipient": "Recipient",
      "taxAmount": "Tax amount",
      "taxRate": "Tax rate (%)",
      "totalExcludingTaxes": "Total excl. taxes",
      "totalIncludingTaxes": "Total incl. taxes",
      "transportPrice": "Transport price",
      "uploadInvoice": "Upload invoice",
      "valueExcludingTaxes": "Value excl. taxes",
    },
    "yourLogisticAgents": {
      "logisticAgentStatus": {
        "awaitingOnboarding": "Awaiting onboarding",
        "awaitingOnboardingTooltip": "Your contacts at {freightForwarderName} do not have access to OneChain yet.",
        "integrated": "Integrated",
        "integratedTooltip": "OneChain and {freightForwarderName}’s system are interconnected.",
        "onboarded": "Onboarded",
        "onboardedTooltip": "Your contacts at {freightForwarderName} have access to OneChain."
      },
      "logisticAgentTypes": {
        "final_client": "Final Client",
        "freight_forwarder": "Forwarder",
        "third_party_logistic": "3PL",
        "warehouse_manager": "Warehouse Manager"
      },
      "operations": "Ops Manager",
      "opsManager": "Operations Manager",
      "sales": "Sales Manager",
      "salesManager": "Sales Manager",
      "tabName": "Partners",
      "title": "Your logistics partners",
      "updateContacts": {
        "editContacts": "Edit contacts",
        "selectOpsContacts": "Select your operations managers at {logisticAgentName}.",
        "selectSalesContacts": "Select your sales managers at {logisticAgentName}."
      }
    }
  },
  "totalPackages": {
    "header": "Number of packages"
  },
  "totalPallets": {
    "header": "Number of pallets"
  },
  "totalTaxableWeight": {
    "header": "Chargeable weight"
  },
  "totalVolume": {
    "header": " Total volume "
  },
  "totalWeight": {
    "header": "Total weight"
  },
  "tracking": {
    "EventActions": {
      "cannotDelete": "You cannot delete the event used for the ETA or ETD",
      "confirmDelete": "Are you sure you want to delete this event?",
      "deleteWarning": "You are about to delete a Pickup or Delivery event"
    },
    "autoTracking": {
      "activate": "Activate auto tracking",
      "airline": "Airline",
      "bl": "BL",
      "bookingNumber": "Booking number",
      "carrier": "Carrier",
      "container": "Container Number",
      "edit": "Edit auto tracking",
      "edit-tracking": "Edit Tracking",
      "error": "Error in automated tracking",
      "fix": "Fix tracking",
      "lastUpdated": "Last updated: {lastUpdatedDate}",
      "leave-edition": "Leave Edition Mode",
      "mawb": "MAWB",
      "number": "Number",
      "pause": "Pause",
      "reference": "Reference",
      "status": "Status",
      "unpause": "Resume"
    },
    "detailsCard": {
      "arrived": "Arrived at:",
      "arrivedAt": {
        "minified": "{eventDate}: Arrived at {eventDesc}",
        "title": "{eventDesc}, the {eventDate}"
      },
      "delay": "Delay of",
      "delayDays": "{numberOfDaysDelayed, plural, one{# day} other{# days}}",
      "delayDaysHours": "{numberOfDaysDelayed, plural, one{# day} other{# days}} and {numberOfHoursDelayed, plural, one{# hour} other{# hours}}",
      "delayHours": "{numberOfHoursDelayed, plural, one{# hour} other{# hours}}",
      "forward": "Advance of",
      "nextEventLabel": "Next milestone:",
      "nextEventValue": {
        "minified": "{eventDate}: {eventDesc}",
        "title": "{eventDesc}, the {eventDate}"
      }
    },
    "document": {
      "title": "Tracking"
    },
    "events": {
      "cancellationReason": "Reason for cancellation",
      "containerNumber": "Container number",
      "hasAppointmentBeenRescheduled": "Has it been rescheduled yet?",
      "hasAppointmentHappened": "Did the appointment take place successfully?",
      "markAsDoneButton": "Mark as done",
      "markDeliveryAsDone": "Mark delivery as done",
      "markPickupAsDone": "Mark pickup as done",
      "newAppointmentDate": "New appointment date",
      "sealNumber": "Seal number",
      "truckGateIn": "Truck gate in",
      "truckGateOut": "Truck gate out"
    },
    "timeline": {
      "date": {}
    }
  },
  "traffics": {
    "activities": {
      "pricesRequested": "{person} has requested prices on this traffic"
    },
    "createTraffic": {
      "errors": {
        "trafficName": {
          "empty": "The traffic name cannot be empty",
          "required": "The traffic name is required"
        }
      },
      "fields": {}
    },
    "emptyState": {
      "header": "Benefit from fixed prices on your regular maritime and...",
      "line1": "Find in a centralized way all the prices sold by Ovrsea on a given period for your various types of containers",
      "line2": " Start your shipments in 2 clicks from your price grids, simply by specifying the number of containers and their availability date"
    },
    "launchShipmentFromTraffic": {
      "anyCarriers": "Any carriers",
      "cargoAvailability": "Cargo Availability",
      "containers": "Containers",
      "errors": {
        "cargoReadyDate": "The cargo ready date is required",
        "containers": {
          "containerType": "The container type is required",
          "number": {
            "min": "The number of containers cannot be less than 0",
            "minTotal": "The total number of containers cannot be less than 1",
            "number": "The number of containers must be a number",
            "required": "The number of containers is required",
            "roundNumber": "The number of containers must be a round number"
          }
        },
        "routing": {
          "harbor": "One harbor is required",
          "location": "One location is required"
        }
      },
      "modalTitle": "Launch a shipment",
      "modify": "Modify",
      "routing": {
        "destination": {
          "harbor": "Arrival harbor",
          "location": "Delivery location"
        },
        "origin": {
          "harbor": "Departure harbor",
          "location": "Pickup location"
        },
        "validity": "Valid from {originHarborsLocodes} to {destinationHarborsLocodes}"
      },
      "shipmentProperties": "Please fill shipment's following information:",
      "trafficProperties": "The shipment will have all following traffic's characteristics:"
    },
    "negotiatedOffers": {
      "addNewRates": "Request new negotiated offers",
      "allocationConfirmedCard": {
        "mainText": "Following negotiated offers have been confirmed",
        "pill": "Allocation confirmed"
      },
      "allocationRefutedCard": {
        "mainText": "Negotiated offers haven't been applied due to allocations limitations.",
        "pill": "Allocation refuted"
      },
      "availableNegotiatedPricesCard": {
        "button": "See applicable fixed prices",
        "mainText": "This shipment is subject to contractualised prices that need to be confirmed with your allocations",
        "modal": {
          "header": "Prices applicable on containers of this shipment",
          "pill": "Awaiting allocation confirmation",
          "subHeader": "Ops team will confirm grid applies or come back with final quotation"
        },
        "pill": "Awaiting allocation confirmation",
        "subText": "Ops team will confirm grid applies or come back with final quotation"
      },
      "columns": {
        "cargo": "Cargo",
        "deliveryLocation": "Delivery Location",
        "pickupLocation": "Pickup Location",
        "pod": "POD",
        "pol": "POL",
        "price": "Price",
        "service": "Service",
        "status": "Status",
        "validityRange": "Validity range"
      },
      "containerType": "Container Type",
      "emptyState": {
        "message": "No negotiated offers available for now. Please contact your Sales Manager to add your first negotiated offers."
      },
      "negotiatedOfferCategoryName": {
        "negotiatedArrivalOffers": "Arrival Offers",
        "negotiatedDeliveryOffers": "On-Carriage Offers",
        "negotiatedDepartureOffers": "Departure Offers",
        "negotiatedFreightOffers": "Freight Offers",
        "negotiatedPickupOffers": "Pre-Carriage Offers"
      },
      "noData": "We have not yet registered negotiated offers",
      "priceUnit": {
        "tc": "per ctr",
        "unit": "per shipment"
      },
      "requestNewRatesModal": {
        "buttonLabel": "Request new negotiated offers",
        "closeButton": "Close",
        "description": "Please, fill out this form to provide your Sales Manager with all necessary information to answer your request. ",
        "fields": {
          "containerType": "Containers",
          "destinationPort": "Destination",
          "originPort": "Origin",
          "requiredError": "This field is required."
        },
        "header": "Request new negotiated offers",
        "submitButton": "Validate"
      },
      "selectNegotiatedOffers": {
        "category": {
          "arrival_fees": "Arrival fees",
          "arrival_truck_freight": "On-Carriage",
          "departure_fees": "Departure fees",
          "departure_truck_freight": "Pre-Carriage",
          "freight": "Freight",
          "insurance": "Insurance"
        },
        "details": "See details",
        "disclaimer": {
          "agreedFor": "Agreed for:",
          "surcharges": "Exceptional surcharges excluded"
        },
        "errors": {},
        "freeTime": "days of free time",
        "freightMethod": {
          "destination": "at arrival",
          "origin": "at departure"
        },
        "noOffers": {
          "description": "There are no negotiated offers valid for this kind of shipment",
          "title": "Our teams will come back to you with a price for this shipment"
        },
        "offersFor": "Offers for {containerType} :",
        "perContainer": "container",
        "priceDetails": "Details of prices",
        "title": "Following negotiated offers will apply",
        "validBy": "Valid for a departure by {validityDate}"
      },
      "status": {
        "accepted": "Accepted",
        "declined": "Declined",
        "new": "New",
        "sent": "Sent"
      },
      "validNegotiatedOffers": "{numberOfValidOffers} valid  {numberOfValidOffers, plural, one{negotiated offer} other{negotiated offers}}",
      "validity": {
        "filter": {
          "future": "Valid soon",
          "past": "Outdated",
          "present": "Valid"
        },
        "tooltip": {
          "future": "This offer will be valid from {validityStart}",
          "past": "This offer is no longer valid",
          "present": "This offer is valid"
        }
      }
    },
    "ongoingShipments": "{numberOfOngoingShipments} ongoing  {numberOfOngoingShipments, plural, one{shipment} other{shipments}}",
    "pageHeader": "Find your fixed prices with Ovrsea on your regular maritime traffics:",
    "pricingGrids": {
      "card": {
        "freeTime": "days of free time",
        "freightOnly": "Freight only",
        "priceDetails": {
          "hide": "Hide price details",
          "show": "Show price details"
        },
        "routing": {
          "otherHarbors": "and {numberOfOtherHarbors} {numberOfOtherHarbors, plural, one{other harbor} other{other harbors}}",
          "otherLocations": "and {numberOfOtherLocations} {numberOfOtherLocations, plural, one{other location} other{other locations}}"
        },
        "validity": "From {validityStart} to {validityEnd}"
      },
      "expired": "Expired",
      "filters": {
        "container": "Container types",
        "validityEnd": "to ...",
        "validityStart": "Valid from ..."
      },
      "title": "Pricing Grids",
      "valid": "Valid"
    },
    "reporting": {
      "delayOnETD": {
        "label": "Delay on ETD",
        "tooltip": "Median time delay on ETD, in days, for the selected time period."
      },
      "delayOnTT": {
        "label": "Delay on transit time",
        "tooltip": "Median time delay on transit time, in days, for the selected time period."
      },
      "download": "Download traffic data",
      "etaInTarget": "ETA in target",
      "etdInTarget": "ETD in target",
      "method": "Mode",
      "noData": "Insufficient number of shipments for the selected period to view the traffic performance. Please select a wider period or come back once more shipments are finished.",
      "numberOfShipments": {
        "label": "Shipments",
        "tooltip": "Number of finished shipments for this traffic, for the selected time period."
      },
      "pod": "POD",
      "pol": "POL",
      "timeGranularity": {
        "last3Months": "Last 3 months",
        "last6Months": "Last 6 months",
        "last9Months": "Last 9 months",
        "last12Months": "Last 12 months"
      },
      "transitTime": {
        "label": "Transit time",
        "tooltip": "Median port-to-port time, in days, for the selected time period."
      }
    },
    "schedules": {
      "alert": {
        "noSchedules": {
          "description": "Ask your sales manager for pricing grids updates to have access to their schedules",
          "title": "No valid pricing grids for which vessel departures could apply"
        },
        "schedules": {
          "description": "This does not guarantee booking or loading on vessels.",
          "title": "Below vessel schedules apply with your fixed pricing grids."
        }
      },
      "departureFrom": "Departure from",
      "disclaimers": {
        "first": "*ETD & ETA are announced by carriers and are subject to change",
        "second": "**Pickup date is estimated"
      },
      "table": {
        "carrier": "Carrier",
        "destination": "Destination",
        "origin": "Origin",
        "pickup": "Pickup Date",
        "transitTime": "Transit Time",
        "vessel": "{numberOfVessels, plural, one{Navire} other{Navires}} : {vesselNames}"
      },
      "title": "Schedules"
    },
    "settings": {
      "description": "These settings will be automatically applied for all your shipments launched on this traffic.",
      "tabName": "Settings",
      "tooltip": "Edit traffic's settings"
    },
    "toggleDetailsButton": {
      "showLess": "Hide traffic's details",
      "showMore": "Display traffic's details"
    },
    "trafficDetails": {
      "cargoInformation": "Cargo Information",
      "containerTypes": "Container types",
      "dangerousGoods": "Dangerous Goods",
      "dangerousGoodsDetails": "Dangerous goods details",
      "freightMethod": "Freight Method",
      "loadType": "Load Type",
      "temperatureControlled": "Temperature Controlled",
      "temperatureRange": "Temperature Range"
    },
    "updateTrafficDetails": {
      "cargoProperties": {
        "always": "Always",
        "never": "Never",
        "sometimes": "Sometimes"
      },
      "generalInformation": "General information",
      "hazardousDetails": "UN & Class",
      "insuranceStatus": {
        "asked_by_client": "Subscribe",
        "not_asked_by_client": "Don't subscribe"
      },
      "modalTitle": "Modify traffic's settings",
      "shipmentInformation": "Shipment information",
      "trafficName": "Name",
      "updateTraffic": "Update traffic"
    }
  },
  "translation": {
    "document": {
      "title": "Translation"
    }
  },
  "units": {
    "cbm": "cbm",
    "cm": "cm",
    "in": "in",
    "kg": "kg",
    "lbs": "lbs"
  },
  "upload": {
    "document": {
      "button": "Add a document",
      "categoryDownload": "Download the folder"
    }
  },
  "userPopoverCard": {
    "role": {
      "ops": "Operations manager",
      "sales": "Sales manager"
    }
  },
  "usersTable": {
    "button": {
      "add": "Add"
    }
  },
  "value-proposition": {
    "access": "Guest accesses and public tracking links",
    "experts": "A dedicated expert for your shipments",
    "flows": "Detailed analysis of your trades",
    "title": "OVRSEA services included in this quote",
    "tracking": "Real-time tracking, available 24/7"
  },
  "vgmSubmitted": {
    "clientLabel": "Client",
    "containerNumber": "Container number",
    "containerType": "Container type",
    "grossWeight": "Gross weight",
    "hazardous": {
      "no": "No",
      "title": "Hazardous",
      "yes": "Yes"
    },
    "leadNumber": "Seal number",
    "tareWeight": "Container tare weight",
    "transportLabel": "Shipment",
    "userValidationLabel": "Validated by",
    "userValidationText": "{userName} - {userEmail}",
    "vgmAcceptedTitle": "VGM accepted"
  },
  "voyager": {
    "document": {
      "title": "Voyager"
    }
  },
  "wall": {
    "WallMessaging": {
      "askContainersInfo": "Ask containers info",
      "askDocuments": "Ask for documents",
      "askInfo": "Ask Information",
      "uploadAwbOrBl": "Upload AWB/BL Draft"
    },
    "activities": {
      "cargoUpdated": {
        "hide": "Hide",
        "show": "Show",
        "title": "Cargo updated"
      }
    },
    "propositions": {
      "declineProp": "Decline quote",
      "declineProps": "Decline quotes",
      "declineSingleProp": "Decline proposition",
      "reactivateProp": "Reactivate quote",
      "reactivateProps": "Reactive quotes"
    },
    "replyForm": {},
    "uploadADoc": "Upload a",
    "uploadLoad": "Upload in progress",
    "voiceMessage": {
      "startRecord": "Start recording",
      "stopRecord": "Stop recording"
    }
  },
  "wallCard": {
    "tasksCard": {
      "doneTasksHeader": "Done tasks 👏️",
      "toDoTasksHeader": "To-Do tasks ✏️",
      "upcomingTasks": "Upcoming tasks ⌛️"
    },
    "tasksModals": {
      "sendEmailTask": {
        "emailSent": "Email sent"
      }
    },
    "wallModal": {
      "documentClientTask": {
        "no": "No. Skip validation and check task",
        "yes": "Yes"
      },
      "uploadDroppedFilesModal": {
        "body": "{filesLength} {filesLength, plural, one {file} other {files}}",
        "bodyPlural": "files",
        "bodySingular": "file",
        "messageDetailsTitle": "Associated message",
        "submit": "Upload",
        "submitting": "Uploading...",
        "title": "Upload files",
        "titlePlural": "Upload files"
      }
    }
  },
  "workflow": {
    "document": {
      "title": "Workflows"
    }
  }
};
